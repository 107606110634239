
;"use strict";

var State = {

// Глобальные флаги тестовой модификации

	flNoDist: 0, // Отключение автоматического распределения пациентов в группах
	flTrace: 0, // Флаг включения трассировки
/////////////////////////////////////////

// Глобальные флаги модификации режимов работы платформы

	flNoTotalsReset: 1, // При распределению по дефолту не сбрасываем общие количества в патологиях

	dbVersion: 19, // версия хранилища в local storage

	super: 1072427180, // пароль суперпользователя

	freePats: {
		'vp': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0
		},
		'ba': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0,
			st0_0_9: 0,
			st0_0_10: 0
		},
		'hobl': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0
		}
	},


	extraPats: {
		'vp': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0
		},
		'ba': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0,
			st0_0_9: 0,
			st0_0_10: 0
		},
		'hobl': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0
		}
	},


	stPats: [ // Распределение пациентов по стадиям с учетом корректировки для 2-3 стадий
		[1,1,1,1], // vp 
		[1,1,1,1], // ba 
		[1,1,1,1] // hobl 
	],// Используется для размещения исходного числа пациентов в группах перед пересчетом
		// в тех случаях, когда сумма процентов методов в группе не равна 100%

	pathList:   ['ВП','БА','ХОБЛ'],
	pathKeys:   ['vp','ba','hobl'],
	defaultSt: {
		'vp':'st0_0_1',
		'ba':'st0_0_1',
		'hobl':'st0_0_1'
	},
	drugBuf: [], 
	omsBuf: [],
	omsdBuf: [],
	rloBuf: [],
	onlsBuf: [],
	pathTotals: [1000,1000,1000], // Пациенты, вновь диагностированные на входе в модель
									// распределения.
	pathSumm: [1000,1000,1000],	// Сумма пациентов, проходящих терапию за год.
									// Т.е. сумма по всем группам, после распределения								
	vpPatsBuf: {}, // То же для VP
	vpMultBuf: {}, // Буфер сохранения множителей расчета общего количества в группе для VP (аналог РЖ)
	baPatsBuf: {}, 
	baMultBuf: {},
	hoblPatsBuf: {}, 
	hoblMultBuf: {},
	baFreePatsBuf:{}, 
	hoblFreePatsBuf:{}, 
	pathBudgets: {'vp':1, 'ba':2, 'hobl':3},
	chanBudgets: {'oms':0, 'omsd':0, 'rlo':0, 'onls':0 },
	pathFreeList: [],
	selPath: [1,1,1],
	selPathKeys: ['vp','ba','hobl'], // Ключи, оставшиеся после фильтрации 

	startEvent: 'mousedown',
	currStad: 'st0_0_1',
	currStNum: 2,
	currPath: 'vp',
	totFreePats: 0,
	stPanel: null,
	pathPanel: null,
	currPage: 0,
	flSelection: 0,
	flModal: 0,
	flIpad: 0,
	flAnimate: 0,
	flKsgEdit: 0, // Открыта форма выбора КСГ для метода
	flDistChanged: 0,
	regionDataInit: 0, // Индикатор первой инициализации выбранного региона
	currPats: { // Набор исходных данных по текущему региону
		'vp': 1000,
		'ba': 1000,
		'hobl': 1000
	}, //
	customPats: { // Набор исходных данных, расчитанных исходя из нового
				 // значения общего числа пациентов
		'vp': -1,
		'ba': -1,
		'hobl': -1
	}, //
	currLeth: {
		'vp': 0,
		'ba': 0,
		'hobl': 0
	},
	drugOrd: [0, 1, 57, 58, 2, 67, 3, 4, 5, 6, 7, 72, 8, 9, 10, 31, 32, 33, 53, 34, 35, 50, 36, 52, 37, 59, 60, 11, 55, 12, 13, 73, 68, 14, 15, 16, 17, 38,
			18, 39, 19, 20, 21, 40, 41, 22, 23, 42, 54, 66, 51, 43, 44, 45, 56, 46, 61, 65, 62, 47, 70, 49, 48, 63, 24, 71, 25, 26, 28, 29, 30, 69, 27],// Порядок листинга препаратов в алфавитных списках

	tabletForm: [
		0, 2, 5, 6, 11, 12, 15, 17, 18, 20, 22, 24, 28, 30, 31,
		32, 33, 34, 35, 36, 37, 40, 42, 43, 44, 45, 46, 56, 57, 58, 59, 47, 48, 49, 50, 51, 52, 55, 
		56, 61, 60, 62, 63, 65, 66, 67, 68, 69, 70, 71, 72, 73
	], // Индексы препаратов, содержащих много единиц в упаковке, бюджет которых
		// округляется на полный курс лечения	
	perorForm: [
		0, 5, 6, 7, 11, 17, 20, 22, 24, 40, 42, 45, 66, 67, 68, 69, 71, 72
	], // Индексы препаратов в пероральной форме, для формирования данных по 
		// инъекционным препаратам  для ограничения их использования при распределении
		// каналов финансирования 

	otherForm: [
		31, 32, 33, 34, 35, 36, 43, 44, 46, 47, 48, 56, 57, 58, 59, 60, 61, 62, 63, 70
	], // Индексы препаратов в аэрозольной форме, для формирования листинга цен

	rlFreePatsBuf: {},
	rmpFreePatsBuf: {},
	currRegname: '',
	currRegNum: 0,
	currLawDoc: 0,
	flRegPrice: 0,	// Is maximal regional price addon selected
	currRegAdd: 8, 	// price addon persent for selected region
	totPatSumm: 0,	// total patients in therapy in all selected pathologies 

/////////// DISTRIBUTION MODULE ////////////////

	clinType: 'st', // Current selected type of clinic 
//	clinType: 'ds', // Current selected type of clinic 

	dsIncome: 0, // OMS compensation accumulators for DS and KS
	stIncome: 0,
	distCurrPage: 4,

	clinicData: {
		omsPlan: 2000000000,
		omsdPlan: 2000000000,
		rloPlan: 5000000,
		onlsPlan: 10000000,
		bs: 12000.0,
		kus: 1.5,
		uk: 1.1,
		kslp: 1.0,
		kd: 1.0
	},

	dsData: {
		omsPlan: 2000000000,
		omsdPlan: 2000000000,
		rloPlan: 5000000,
		onlsPlan: 10000000,
		bs: 10000.0,
		kus: 1.5,
		uk: 1.1,
		kslp: 1.0,
		kd: 1.0
	},

	stData: {
		omsPlan: 2000000000,
		omsdPlan: 2000000000,
		rloPlan: 5000000,
		onlsPlan: 10000000,
		bs: 12000.0,
		kus: 2.0,
		uk: 1.1,
		kslp: 1.0,
		kd: 1.0
	},

	dsUKData: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1], // Individual values for UK for all KSG
	stUKData: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1], // Individual values for UK for all KSG
	averCostKs: 0, 
	nOfCasesKs: 0,
	averCostDs: 0, 
	nOfCasesDs: 0,

	ksgBuf: {
		'ds23.001': 0,

		'st12.013': 0, 
		'st23.004': 0,
		'st23.005': 0
	},

	custSt: [], // Не первые КСГ, выбранные из списка допустимых вариаций
	custDs: [],
// Chanel disribution report parameters

	repPatsNum: {'vp':1000, 'ba':1000, 'hobl':1000},
	repMonthNum: 12,
	mainMonthNum: 12,
	nOfRloPats: {'vp':0, 'ba':0, 'hobl':0},
	nOfOnlsPats: {'vp':0, 'ba':0, 'hobl':0},
	nOfRemained: {'vp':0, 'ba':0, 'hobl':0} // Сколько еще можно распределить в каналы РЛО и ОНЛС

}

export default State;