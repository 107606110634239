"use strict";

import vpData from './models/vp-meth';
import baData from './models/ba-meth';
import hoblData from './models/hobl-meth';
import State from './state';

class StPanel {

	constructor() {

		this.currSt = 0;
		this.currStName = 'st0_0_1';
		this.currPath = 'vp';
		this.pathData = vpData;
	
		this.currFreePats = 3;
		this.currLineNum = 0;
		this.mode = 'count';
		this.stBuf = [];
		this.stList = [];
		this.freeList = [];
		this.totalPats = 0;
		this.flEditMode = 0;

		$('#st-title').on(State.startEvent, (e)=>this.openEditor(e));
	}

///////////////////////////////////

	setCurrentPath(path) {
		this.currPath = path;
	}	

///////////////////////////////////

	setCurrentSt(st) {

		if(typeof st == 'string') {
			this.fetchStLists(State.currPath);
			let len = this.stList.length;
			for(let cnt=0 ; cnt<len ; cnt++) {
				if(st == this.stList[cnt]) {
					this.currSt = cnt;
					this.currStName = st;
					State.currStad = st;
					break;
				}
			}
		} else {
			this.currSt = st;
		}
	}	

///////////////////////////////////

	fetchStLists(path) {

		let st, line, group, key, str1, str2, stData = {}, pathData = {};

		if(path=='vp') { this.fetchVpGroupList() ; return; }
		if(path=='ba') { this.fetchBaGroupList() ; return; }
		if(path=='hobl') { this.fetchHoblGroupList() ; return; }
		else console.log('!!! Error calling fetchStLists()');
		this.stBuf = [];
		this.stList = [];
		this.totalPats = 0;
		for(st = 1 ; st<5 ; st++) {
			for(line = 0 ; line<5 ; line++) {
				for(group = 0 ; group<=6 ; group++) {
					stData = {};

					key = 'st' + st + '_' + line + '_' + group;
					if(line!=0 && group==0) key = 'st' + st + '_' + line;
					if(line==0 && group==0) key = 'st' + st;

					if(pathData[key]) {
						stData.name = 'Стадия ' + st;
						stData.line = 'Линия ' + line;
						if(line==0) stData.line = '';
						stData.group = '';
						if(group>0)	stData.group = pathData[key].group;
						if(pathData[key].result!=-1) stData.totalPats = pathData[key].result;
						else stData.totalPats = pathData[key].pats;
						this.stBuf.push(stData);
						this.stList.push(key);
						this.totalPats += stData.pats;
					}
				}
			}
		}
	}


///////////////////////////////////

	fetchVpGroupList() {
		let st, line, group, key, str1, str2, stData = {}, pathData = {};

		pathData = vpData;
		this.stBuf = [];
		this.stList = [];
		for(st = 0 ; st<5 ; st++) {
			for(line = 0 ; line<5 ; line++) {
				for(group = 0 ; group<=14 ; group++) {
					stData = {};

					key = 'st' + st + '_' + line + '_' + group;
					if(line!=0 && group==0) key = 'st' + st + '_' + line;
					if(line==0 && group==0) key = 'st' + st;
					if(pathData[key]) {
						stData.name = 'Стадия ' + st;
						stData.line = 'Линия ' + line;
						if(st==0) stData.name = '';
						if(line==0) stData.line = '';
						stData.group = '';
						if(group>0)	stData.group = pathData[key].group;
						if(pathData[key].result!=-1) {
							stData.totalPats = pathData[key].result;
						}
						else {
							stData.totalPats = pathData[key].pats;
						}
						this.stBuf.push(stData);
						this.stList.push(key);
					}
				}
			}
		}
	}

///////////////////////////////////

	fetchBaGroupList() {
		let st, line, group, key, str1, str2, stData = {}, pathData = {};

		pathData = baData;
		this.stBuf = [];
		this.stList = [];
		for(st = 0 ; st<5 ; st++) {
			for(line = 0 ; line<5 ; line++) {
				for(group = 0 ; group<=14 ; group++) {
					stData = {};

					key = 'st' + st + '_' + line + '_' + group;
					if(line!=0 && group==0) key = 'st' + st + '_' + line;
					if(line==0 && group==0) key = 'st' + st;
					if(pathData[key]) {
						stData.name = 'Стадия ' + st;
						stData.line = 'Линия ' + line;
						if(st==0) stData.name = '';
						if(line==0) stData.line = '';
						stData.group = '';
						if(group>0)	stData.group = pathData[key].group;
						if(pathData[key].result!=-1) {
							stData.totalPats = pathData[key].result;
						}
						else {
							stData.totalPats = pathData[key].pats;
						}
						this.stBuf.push(stData);
						this.stList.push(key);
					}
				}
			}
		}
	}

///////////////////////////////////

	fetchHoblGroupList() {
		let st, line, group, key, str1, str2, stData = {}, pathData = {};

		pathData = hoblData;
		this.stBuf = [];
		this.stList = [];
		for(st = 0 ; st<5 ; st++) {
			for(line = 0 ; line<5 ; line++) {
				for(group = 0 ; group<=14 ; group++) {
					stData = {};

					key = 'st' + st + '_' + line + '_' + group;
					if(line!=0 && group==0) key = 'st' + st + '_' + line;
					if(line==0 && group==0) key = 'st' + st;
					if(pathData[key]) {
						stData.name = 'Стадия ' + st;
						stData.line = 'Линия ' + line;
						if(st==0) stData.name = '';
						if(line==0) stData.line = '';
						stData.group = '';
						if(group>0)	stData.group = pathData[key].group;
						if(pathData[key].result!=-1) {
							stData.totalPats = pathData[key].result;
						}
						else {
							stData.totalPats = pathData[key].pats;
						}
						this.stBuf.push(stData);
						this.stList.push(key);
					}
				}
			}
		}
	}

///////////////////////////////////

	render(path) {

		const stPanel = $('#st-panel');
		let st, len, cnt;

		if(path) this.currPath = path;
		// if(path=='rmz') { this.renderRmz(); return; }
//		if(path=='ba') { this.renderBa(); return; }

		this.fetchStLists(this.currPath);
//console.log(this.currPath);
		len = this.stBuf.length;

		const view = `${ this.stBuf.map( (st, num)=>`
                <div class="st-line" id="st-line${num}">
                    <div class="panel-pic">
                        <div class="line-pic mid-line" id="stline-pic${num}">
                            <div class="dot-pic" id="dot-pic${num}"></div>
                        </div>
                    </div>
                    <div class="st-line-text" id="st-line-text${num}">
                        <div class="stline-title normal-blue" id="stline-title${num}">${st.name}</div>
                        <div class="stline-line normal-grey" id="stline-group${num}">${st.group}</div>
                        <div class="stline-line normal-grey" id="stline-line${num}">${st.line}</div>
                        <div class="stline-info normal-grey" id="stline-info${num}">
                            <div class="man-pict" id="st-man-pict${num}"></div>
                            <div class="st-num normal-grey" id="st-num${num}">${st.totalPats}</div>
                        </div>
                    </div>
                    <div class="line-alert" id="line-alert${num}">
                    </div>

	                <div class="st-line-counter">
	                    <div class="st-minus-but" id="st-minus${num}"></div>
	                    <div class="st-pats-count" id="st-pcount${num}">
	                    	<div class="pats-edit" id="st-pats-edit${num}">
	                    		<input class="st-count-edit" id="st-count-edit${num}" type="text" value="${st.totalPats}">
	                    	</div>
	                    	<div class="pats-text" id="st-pats-text${num}">${st.totalPats}</div>
	                    </div>
	                    <div class="st-plus-but" id="st-plus${num}"></div>
	                </div>

                </div>
			`).join('')}	
		`;	

		stPanel.empty();
		stPanel.append(view);
		$('#stline-pic0').addClass('top-line');
		$('#stline-pic0').removeClass('mid-line');
		$('#stline-pic'+(len-1)).addClass('bot-line');
		$('#stline-pic'+(len-1)).removeClass('mid-line');
		st = this.currSt;
		$('#stline-title'+st).addClass('selected');
		$('#stline-line'+st).addClass('selected');
		$('#stline-info'+st).addClass('selected');
		$('#stline-group'+st).addClass('selected');
		$('#st-man-pict'+st).addClass('man-pict-sel');
		$('#st-num'+st).addClass('selected');
		$('#dot-pic'+st).addClass('dot-pic-sel');

		$('#stline-title'+st).removeClass('normal-blue');
		$('#stline-line'+st).removeClass('normal-grey');
		$('#stline-info'+st).removeClass('normal-grey');
		$('#stline-group'+st).removeClass('normal-grey');
		$('#st-man-pict'+st).removeClass('man-pict');
		$('#st-num'+st).removeClass('normal-grey');
		$('#dot-pic'+st).removeClass('dot-pic');
		State.methPanel.setCurrentSt(this.currStName);

		this.markFreePats(State.currPath);
		for(cnt=0 ; cnt<len ; cnt++) {
			if($('#stline-group'+cnt).width()>123) $('#line-alert'+cnt).css({'margin-right':0});
		}

		$('.st-line-text').on(State.startEvent, (e) => { this.selectSt(e)});
		$('.st-minus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.st-plus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.st-pats-count').on(State.startEvent, (e) => this.showEdit(e));
		$('.st-count-edit').on('keypress focusout', (e) => this.editNumber(e));
	}

///////////////////////////////////

	renderRmz() {

		const stPanel = $('#st-panel');
		let st, len, cnt;

		this.fetchRmzGroupList();

		len = this.stBuf.length;

		const view = `${ this.stBuf.map( (st, num)=>`
                <div class="st-line" id="st-line${num}">
                    <div class="panel-pic">
                        <div class="line-pic mid-line" id="stline-pic${num}">
                            <div class="dot-pic" id="dot-pic${num}"></div>
                        </div>
                    </div>
                    <div class="st-line-text" id="st-line-text${num}">
                        <div class="stline-line normal-grey" id="stline-group${num}">${st.group}</div>
                        <div class="stline-info normal-grey" id="stline-info${num}">
                            <div class="man-pict" id="st-man-pict${num}"></div>
                            <div class="st-num normal-grey" id="st-num${num}">${st.totalPats}</div>
                        </div>
                    </div>
                    <div class="line-alert" id="line-alert${num}">
                    </div>
	                <div class="st-line-counter">
	                    <div class="st-minus-but" id="st-minus${num}"></div>
	                    <div class="st-pats-count" id="st-pcount${num}">
	                    	<div class="pats-edit" id="st-pats-edit${num}">
	                    		<input class="st-count-edit" id="st-count-edit${num}" type="text" value="${st.totalPats}">
	                    	</div>
	                    	<div class="pats-text" id="st-pats-text${num}">${st.totalPats}</div>
	                    </div>
	                    <div class="st-plus-but" id="st-plus${num}"></div>
	                </div>
                </div>
			`).join('')}	
		`;	

        // <div class="stline-title normal-blue" id="stline-title${num}">${st.name}</div>
        // <div class="stline-line normal-grey" id="stline-line${num}">${st.line}</div>


		stPanel.empty();
		stPanel.append(view);
		$('#stline-pic0').addClass('top-line');
		$('#stline-pic0').removeClass('mid-line');
		$('#stline-pic'+(len-1)).addClass('bot-line');
		$('#stline-pic'+(len-1)).removeClass('mid-line');
		st = this.currSt;
		$('#stline-title'+st).addClass('selected');
		$('#stline-line'+st).addClass('selected');
		$('#stline-info'+st).addClass('selected');
		$('#stline-group'+st).addClass('selected');
		$('#st-man-pict'+st).addClass('man-pict-sel');
		$('#st-num'+st).addClass('selected');
		$('#dot-pic'+st).addClass('dot-pic-sel');

		$('#stline-title'+st).removeClass('normal-blue');
		$('#stline-line'+st).removeClass('normal-grey');
		$('#stline-info'+st).removeClass('normal-grey');
		$('#stline-group'+st).removeClass('normal-grey');
		$('#st-man-pict'+st).removeClass('man-pict');
		$('#st-num'+st).removeClass('normal-grey');
		$('#dot-pic'+st).removeClass('dot-pic');

		State.methPanel.setCurrentSt(this.currStName);

		this.markFreePats(this.currPath);
		for(cnt=0 ; cnt<len ; cnt++) {
			if($('#stline-group'+cnt).width()>123) $('#line-alert'+cnt).css({'margin-right':0});
		}

		$('.st-line-text').on(State.startEvent, (e) => { this.selectSt(e)});
		$('.st-minus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.st-plus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.st-pats-count').on(State.startEvent, (e) => this.showEdit(e));
		$('.st-count-edit').on('keypress focusout', (e) => this.editNumber(e));
	}


///////////////////////////////////

	renderBa() {

		const stPanel = $('#st-panel');
		let st, len, cnt;

		this.fetchBaGroupList();

		len = this.stBuf.length;

		const view = `${ this.stBuf.map( (st, num)=>`
                <div class="st-line" id="st-line${num}">
                    <div class="panel-pic">
                        <div class="line-pic mid-line" id="stline-pic${num}">
                            <div class="dot-pic" id="dot-pic${num}"></div>
                        </div>
                    </div>
                    <div class="st-line-text" id="st-line-text${num}">
     
                   		<div class="stline-title normal-blue" id="stline-title${num}">${st.name}</div>
                        <div class="stline-line normal-grey" id="stline-group${num}">${st.group}</div>
                        <div class="stline-line normal-grey" id="stline-line${num}">${st.line}</div>
                        <div class="stline-info normal-grey" id="stline-info${num}">
                            <div class="man-pict" id="st-man-pict${num}"></div>
                            <div class="st-num normal-grey" id="st-num${num}">${st.totalPats}</div>
                        </div>
                    </div>
                    <div class="line-alert" id="line-alert${num}">
                    </div>
	                <div class="st-line-counter">
	                    <div class="st-minus-but" id="st-minus${num}"></div>
	                    <div class="st-pats-count" id="st-pcount${num}">
	                    	<div class="pats-edit" id="st-pats-edit${num}">
	                    		<input class="st-count-edit" id="st-count-edit${num}" type="text" value="${st.totalPats}">
	                    	</div>
	                    	<div class="pats-text" id="st-pats-text${num}">${st.totalPats}</div>
	                    </div>
	                    <div class="st-plus-but" id="st-plus${num}"></div>
	                </div>
                </div>
			`).join('')}	
		`;	

		stPanel.empty();
		stPanel.append(view);
		$('#stline-pic0').addClass('top-line');
		$('#stline-pic0').removeClass('mid-line');
		$('#stline-pic'+(len-1)).addClass('bot-line');
		$('#stline-pic'+(len-1)).removeClass('mid-line');
		st = this.currSt;
		$('#stline-title'+st).addClass('selected');
		$('#stline-line'+st).addClass('selected');
		$('#stline-info'+st).addClass('selected');
		$('#stline-group'+st).addClass('selected');
		$('#st-man-pict'+st).addClass('man-pict-sel');
		$('#st-num'+st).addClass('selected');
		$('#dot-pic'+st).addClass('dot-pic-sel');

		$('#stline-title'+st).removeClass('normal-blue');
		$('#stline-line'+st).removeClass('normal-grey');
		$('#stline-info'+st).removeClass('normal-grey');
		$('#stline-group'+st).removeClass('normal-grey');
		$('#st-man-pict'+st).removeClass('man-pict');
		$('#st-num'+st).removeClass('normal-grey');
		$('#dot-pic'+st).removeClass('dot-pic');

		State.methPanel.setCurrentSt(this.currStName);

		this.markFreePats(this.currPath);
		for(cnt=0 ; cnt<len ; cnt++) {
			if($('#stline-group'+cnt).width()>123) $('#line-alert'+cnt).css({'margin-right':0});
		}

		$('.st-line-text').on(State.startEvent, (e) => { this.selectSt(e)});
		$('.st-minus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.st-plus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.st-pats-count').on(State.startEvent, (e) => this.showEdit(e));
		$('.st-count-edit').on('keypress focusout', (e) => this.editNumber(e));
	}

///////////////////////////////////

	openEditor(e) {

		let len, cnt;

		if(State.flAnimate==1) return;
		State.flAnimate = 1;
		this.flEditMode = 1;

		$('.st-line-counter').css({'display':'flex'});
		$('#methods-wrap').animate({'left':'620px','opacity':0},600,()=>{
			State.flAnimate = 0;
			$('.st-line-counter').css({'display':'flex'});
			$('#methods-wrap').hide();
		});
		len = this.freeList.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			$('#line-alert'+cnt).hide();

			$('#stline-title'+cnt).addClass('normal-blue');
			$('#stline-line'+cnt).addClass('normal-grey');
			$('#stline-info'+cnt).addClass('normal-grey');
			$('#stline-group'+cnt).addClass('normal-grey');
			$('#st-man-pict'+cnt).addClass('man-pict');
			$('#st-num'+cnt).addClass('normal-grey');
			$('#dot-pic'+cnt).addClass('dot-pic');

			$('#stline-title'+cnt).removeClass('selected');
			$('#stline-line'+cnt).removeClass('selected');
			$('#stline-info'+cnt).removeClass('selected');
			$('#stline-group'+cnt).removeClass('selected');
			$('#st-man-pict'+cnt).removeClass('man-pict-sel');
			$('#st-num'+cnt).removeClass('selected');
			$('#dot-pic'+cnt).removeClass('dot-pic-sel');
		}
	}

///////////////////////////////////

	closeEditor() {

		if(State.flAnimate==1) return;
		State.flAnimate = 1;
		this.flEditMode = 0;

		$('.st-line-counter').hide();
		$('#methods-wrap').show();
		$('#methods-wrap').animate({'left':'372px','opacity':1},600,()=>{
			$('.st-line-counter').css({'display':'none'});
			State.flAnimate = 0;
		});
		this.markFreePats(this.currPath);
	}

///////////////////////////////////

	countFreePats(path) {

		let freeCnt = 0, cnt, currSt, methSet, stKey, len, addPats, extraPats,
			numberOfSt, allPats, freePatSumm, persentSumm;
		let	pathData;

		if (path=='vp') {
			pathData = vpData;
			this.fetchVpGroupList();
		} else if(path=='ba') {
			pathData = baData;
			this.fetchBaGroupList();
		} else if(path=='hobl') {
			pathData = hoblData;
			this.fetchHoblGroupList();
		}  
		else console.log('!!! Error calling countFreePats()')
		numberOfSt = this.stList.length;
		this.freeList = [];
		freePatSumm = 0;
		for(currSt=0 ; currSt<numberOfSt ; currSt++) {
			stKey = this.stList[currSt];
			methSet = pathData[stKey].meth;
			len = methSet.length;
			freeCnt = 0; addPats = 0; persentSumm = 0;
			if(pathData[stKey].result!=-1) allPats = pathData[stKey].result;
			else allPats = pathData[stKey].pats;
			for(cnt=0 ; cnt<len ; cnt++) { 
//if(methSet[cnt].rate>=0) // Не считаем фиктивных пациентов
				if(methSet[cnt].rate<0) {
					addPats += methSet[cnt].pats;
					persentSumm += Math.abs(methSet[cnt].rate);
				}
				extraPats = Math.floor(allPats*persentSumm/100.0);

				freeCnt += methSet[cnt].pats;
			}
if(path=='ba') allPats = State.baFreePatsBuf[stKey];
if(path=='hobl') allPats = State.hoblFreePatsBuf[stKey];
			// else if(path=='rmp') allPats = State.rmpFreePatsBuf[stKey];
			// else if(pathData[stKey].result!=-1) allPats = pathData[stKey].result;
			// else allPats = pathData[stKey].pats;
//			freeCnt = allPats - freeCnt;	
//extraPats = State.extraPats[path][stKey];		
State.extraPats[path][stKey] = extraPats;		
freeCnt = allPats + extraPats - freeCnt;

			State.freePats[path][stKey] = freeCnt;
			this.freeList[currSt] = freeCnt;
			freePatSumm += freeCnt;
		}
		len = State.pathKeys.length;
		for(cnt=0 ; cnt<len ; cnt++)
			if(State.pathKeys[cnt]==path) State.pathFreeList[cnt] = freePatSumm;
	}


///////////////////////////////////

	markFreePats(path) {

		let len, cnt;

		this.countFreePats(path);
		len = this.freeList.length;
		for(cnt=0 ; cnt<len ; cnt++) {

			if(this.freeList[cnt]>0) $('#line-alert'+cnt).show();
			else $('#line-alert'+cnt).hide();
		}
		State.pathPanel.markFreePats();
//!!TODO Нужно заменить это вызов на более специфичный, т.е. на панели
// патологий делать апдейт индикатора только для текущей патологии 
		this.countFreePats(path);
	}


///////////////////////////////////

	selectSt(e) {

		let num = e.currentTarget.id, st;
		num = num.slice(12);
		if(this.currSt==num && this.editMode==0) return;
		if(this.flEditMode==1) this.closeEditor();
		this.fetchStLists(this.currPath);
		st = this.currSt;
		$('#stline-title'+st).removeClass('selected');
		$('#stline-line'+st).removeClass('selected');
		$('#stline-group'+st).removeClass('selected');
		$('#stline-info'+st).removeClass('selected');
		$('#st-man-pict'+st).removeClass('man-pict-sel');
		$('#st-num'+st).removeClass('selected');
		$('#dot-pic'+st).removeClass('dot-pic-sel');

		$('#stline-title'+st).addClass('normal-blue');
		$('#stline-line'+st).addClass('normal-grey');
		$('#stline-group'+st).addClass('normal-grey');
		$('#stline-info'+st).addClass('normal-grey');
		$('#st-man-pict'+st).addClass('man-pict');
		$('#st-num'+st).addClass('normal-grey');
		$('#dot-pic'+st).addClass('dot-pic');


		st = num;
		$('#stline-title'+st).addClass('selected');
		$('#stline-line'+st).addClass('selected');
		$('#stline-group'+st).addClass('selected');
		$('#stline-info'+st).addClass('selected');
		$('#st-man-pict'+st).addClass('man-pict-sel');
		$('#st-num'+st).addClass('selected');
		$('#dot-pic'+st).addClass('dot-pic-sel');

		$('#stline-title'+st).removeClass('normal-blue');
		$('#stline-line'+st).removeClass('normal-grey');
		$('#stline-group'+st).removeClass('normal-grey');
		$('#stline-info'+st).removeClass('normal-grey');
		$('#st-man-pict'+st).removeClass('man-pict');
		$('#st-num'+st).removeClass('normal-grey');
		$('#dot-pic'+st).removeClass('dot-pic');

		this.currSt = num;
		this.currStName = this.stList[num];
		State.currStad = this.stList[num];
		State.currStNum = num;
		State.methPanel.render(this.stList[num]);
	}


///////////////////////////////////
// Корректируем сумму пациентов в текущей нозологии
//

	updatePathPats(delta) {

		let currPathNum = -1, i, len, path, selPathNum = -1, 
			str, pathTotal, pathName;

		pathName = State.currPath;
		if(pathName=='melanoma') pathName = 'mel';
		len = State.pathKeys.length;
		// Ищем номер текущей активной патологии по ее имени
		for(i=0 ; i<len ; i++) 
			if(State.pathKeys[i]==State.currPath) {
				currPathNum = i;
				break;
			} 
		// Ищем позицию текущей патологии в списке отфильтрованных
		len = State.selPathKeys.length;
		for(i=0 ; i<len ; i++) 
			if(State.selPathKeys[i]==State.currPath) {
				selPathNum = i;
				break;
			} 
		// Изменяем суммы для отображание в списке патологий
		State.pathTotals[currPathNum] += delta; // Вновь диагностированные
		State.pathSumm[currPathNum] += delta; // Общее кол-во за год
		pathTotal = State.pathTotals[currPathNum];
//		str = '' + pathTotal + '<b> / </b>' + State.pathSumm[currPathNum];
		str = '' + pathTotal;
		// Прописываем результат в объект изменений
		State.customPats[pathName] = pathTotal;
		if(pathTotal<0) {
			$('#path-num'+selPathNum).text('---');
			$('#path-pats-text'+selPathNum).text(0);
			$('#path-count-edit'+selPathNum).val(0);
		} else {
			$('#path-num'+selPathNum).html(str);
			$('#path-pats-text'+selPathNum).text(pathTotal);
			$('#path-count-edit'+selPathNum).val(pathTotal);
		}
		// Заполняем массив кол-ва пациентов в отфильтрованном списке объекта path-panel
		State.pathPanel.updatePathTotals();
		State.calc.updateTotalPatientViews();
	}


///////////////////////////////////
// Перераспределяем низлежащие методы
//

	redistributeCurrGroup(path) {

		if(path=='vp') State.vpModel.distributeAllMeth();
		if(path=='ba') State.baModel.distributeAllMeth();
		if(path=='hobl') State.hoblModel.distributeAllMeth();
		this.markFreePats(path);
		State.methPanel.render(this.currStName); 
	}


///////////////////////////////////

	getModelData(path) {

		let stData;

		if(path=='vp') stData = vpData;
		else if(path=='ba') stData = baData;
		else if(path=='hobl') stData = hoblData;
		else console.log('!!! Error calling getModelData()');
		return stData;
	}


///////////////////////////////////

	completeEdit() {

		const num = this.currLineNum;
		const currSt = this.stList[num];
		let currPath = State.currPath;
		let stData, delta, model;

		if(this.editMode!='edit') return;

		model = this.getModelData(currPath);
		stData = model[currSt];
		let	pats = $('#st-count-edit'+num).val();
//!! Если вводить одно число, то дельта все равно  будет увеличивать счетчики
// вводим stData.result
		if(stData.result!=-1) delta = pats - stData.result;
		else delta = pats - stData.pats;
		if(stData.result!=-1) stData.result += delta;
		else stData.result = stData.pats + delta;
//		stData.pats = pats;
//		let freePats = this.currFreePats - delta;	

//		if(freePats<0) delta = this.currFreePats;
//		this.methSet[num].pats += delta;
//		this.currFreePats -= delta;
//		pats = this.methSet[num].pats;
		this.editMode = 'count';
//		$('#count-edit'+num).val(pats);

		$('#st-pats-edit'+num).hide();
		$('#st-pats-text'+num).show();
		$('#st-pats-text'+num).text(pats);
		$('#st-num'+num).text(pats);
//		$('#meth-free-pats').text(this.currFreePats);
		this.updatePathPats(delta);
		this.redistributeCurrGroup(currPath);
if(delta!==0) {
//	State.flDistChanged = 1; // Не используем пока
	State.distPanel.resetChanels(currSt);
}
		State.methPanel.displayResult();
		State.methPanel.saveMethData();
	}

///////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.completeEdit();
				return;
			}
			if(e.charCode<48 || e.charCode>57) {
				e.preventDefault();
				return;
			}
		}
		if(e.type=='focusout') {
				this.completeEdit();
		}	
	}


///////////////////////////////////

	showEdit(e) {

		let num = e.target.id, currPath, stSet, currSt, data, 
			str = num.slice(0,12);

		currPath = State.currPath;
		stSet = this.getModelData(currPath);

		if(str!='st-pats-text') return;
		num = num.slice(12);
		if(this.editMode=='edit') {
			if(this.currLineNum==num) return;
			this.completeEdit();
		}
		currSt = this.stList[num];	

		setTimeout(() => {
			this.editMode = 'edit';
			this.currLineNum = num;
			data = stSet[currSt].pats;
			if(stSet[currSt].result!=-1) data = stSet[currSt].result;
			$('#st-pats-text'+num).hide();
			$('#st-pats-edit'+num).show();
			$('#st-count-edit'+num).val(data);
		},50);


		setTimeout(() => {
			$('#st-count-edit'+num).focus();
			let len = $('#st-count-edit'+num).val().length;
			let inp = document.getElementById('st-count-edit'+num);
			inp.setSelectionRange(0,len);
		},100);

	}

///////////////////////////////////

	changeCounter(e) {

		let str = e.target.id, num, currSt, currPath, delta,
			stSet;

		currPath = State.currPath;
		stSet = this.getModelData(currPath);
		
		if(this.editMode=='edit') {
			this.completeEdit();
		}	
		num = str.slice(0,7);
		setTimeout(() => {
			if(num=='st-minu') {
				num = str.slice(8);
				currSt = this.stList[num];	
				if(stSet[currSt].result!=-1 && stSet[currSt].result < 1) return;
				if(stSet[currSt].pats < 1) return;
				if(stSet[currSt].result!=-1) stSet[currSt].result--;
				else stSet[currSt].result = stSet[currSt].pats - 1;
				this.currFreePats++;
				$('#st-pats-text'+num).text(''+stSet[currSt].result);
				$('#st-num'+num).text(''+stSet[currSt].result);
				this.totalPats--;
				delta = -1;
			}
			else if(num=='st-plus') {
				num = str.slice(7);
				currSt = this.stList[num];	
				if(stSet[currSt].result!=-1) stSet[currSt].result++;
				else stSet[currSt].result = stSet[currSt].pats + 1;
				this.currFreePats--;
				$('#st-pats-text'+num).text(''+stSet[currSt].result);
				$('#st-num'+num).text(''+stSet[currSt].result);
				this.totalPats++;
				delta = 1;
			}	
			this.updatePathPats(delta);
			this.redistributeCurrGroup(currPath);
//State.flDistChanged = 1; // Не используем пока
State.distPanel.resetChanels(currSt);
			State.methPanel.displayResult();
			State.methPanel.saveMethData();
	}, 100);

	}

}

export default StPanel;