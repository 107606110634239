"use strict";

import vpData from './models/vp-meth';
import baData from './models/ba-meth';
import hoblData from './models/hobl-meth';
import State from './state';
import methods from './methods';

class MethPanel {

	constructor() {

		this.currSt = 'st0_0_1';
		this.currPath = 'vp';
		this.pathData = vpData;
		this.methSet = this.pathData[this.currSt].meth;
		this.allPats = this.pathData[this.currSt].pats;
		this.currFreePats = 0;
		this.currLineNum = 0;
		this.mode = 'count';
		this.methPack = {}; // Упакованный объект методов
		$('#dist-button').on(State.startEvent, this.showDistPanel);
	}

///////////////////////////////////

	showDistPanel() {

		State.distPanel.show();
	}

///////////////////////////////////

	setCurrentSt(st) {

		this.currSt = st;
	}


///////////////////////////////////
// Возвращаем число фейковых пациентов в текущей группе

	countExtraPats() {

		let i;
		const len = this.methSet.length;
		let percentSumm = 0, patsNum = 0;

		for(i=0 ; i<len ; i++) {
			if(this.methSet[i].rate<0) percentSumm += Math.abs(this.methSet[i].rate);
		}
		if(this.pathData[this.currSt].result != -1) patsNum = this.pathData[this.currSt].result;
		else patsNum = this.pathData[this.currSt].pats;
		return Math.floor(patsNum*percentSumm/100.0);
	}

///////////////////////////////////

	render(st) {
		const path = State.currPath;
		let viewStr, view = '', num, meth, methNum, grCount,
			sectData = [];
		let sectView = '<div id="sect-select">', sectLine;	


//State.hoblModel.currSection = 5;
		let currSection = State.hoblModel.currSection;

		if(st) this.currSt = st;
		if(State.currPath=='vp') {
			this.pathData = vpData;
			sectData = State.vpModel.getSections();
		} else if(State.currPath=='ba') {
			this.pathData = baData;
			sectData = State.baModel.getSections();
		} else if(State.currPath=='hobl') {
			this.pathData = hoblData;
			sectData = State.hoblModel.getSections();
		}
		else console.log('!!!Error calling render()');

		this.methSet = this.pathData[this.currSt].meth;

		if(path=='ba') this.allPats = State.baFreePatsBuf[this.currSt];
		else if(path=='rmp') this.allPats = State.rmpFreePatsBuf[this.currSt];
		else if(path=='hobl') this.allPats = State.hoblFreePatsBuf[this.currSt];
		else {
			if(this.pathData[this.currSt].result != -1) this.allPats = this.pathData[this.currSt].result;
			else this.allPats = this.pathData[this.currSt].pats;	
		}
		const methPanel = $('#methods-panel');

		const len = this.methSet.length; grCount = 0;

		view = '';
		// view = `
		// 		<div id="sect-select">
		// 			<div class="sect-el sect-active" id="sect-el0">Базовые</div>
		// 			<div class="sect-el sect-inactive" id="sect-el1">ДДАХ/ДДБА</div>
		// 			<div class="sect-el sect-inactive" id="sect-el2">ИГКС/ДДБА</div> 
		// 			<div class="sect-el sect-inactive" id="sect-el3">ДДАХ/ДДБА/ИГКС</div> 
		// 			<div class="sect-el sect-inactive" id="sect-el4">ИГКС/ДДАХ/рофлумиласт</div>
		// 			<div class="sect-el sect-inactive" id="sect-el5">ДДАХ/ДДБА/ИГКС/рофлумиласт</div> 
		// 			<div class="sect-el sect-inactive" id="sect-el6">ИГКС/ДДБА/теофиллин</div>
		// 		</div>
		// `;
		let sectCnt = 0, findRes, sect;
		let sectBuf = [];
		for(num=0 ; num<len ; num++) {
			methNum = this.methSet[num].meth;
			meth = methods[methNum];
			if(this.methSet[num].sect!=undefined) {
				sect = this.methSet[num].sect;
				findRes = sectBuf.find((el) => el==sect);
				if(findRes!=undefined) ;
				else {
					sectLine = `<div class="sect-el sect-inactive" id="sect-el${sect}">
									${sectData[sect]}
								</div>`;
					sectView += sectLine;
					sectBuf.push(sect);
					sectCnt++;
				}
			}
			if(this.methSet[num].sect!=undefined && this.methSet[num].sect!=State.hoblModel.currSection) continue;
			viewStr = `
	            <div class="meth-line" id="meth-line${num}">
	                <div class="panel-pic">
	                    <div class="line-pic mid-line" id="line-pic${grCount}">
	                        <div class="dot-pic"></div>
	                    </div>
	                </div>
	                <div class="line-text">
	                    <div class="line-title">${meth.name}</div>
	                    <div class="line-info">${meth.info}</div>
	                </div>
	                <div class="line-counter">
	                    <div class="minus-but" id="meth-minus${num}"></div>
	                    <div class="pats-count" id="pcount${num}">
	                    	<div class="pats-edit" id="pats-edit${num}">
	                    		<input class="count-edit" id="count-edit${num}" type="text" value="${meth.pats}">
	                    	</div>
	                    	<div class="pats-text" id="pats-text${num}">${this.methSet[num].pats}</div>
	                    </div>
	                    <div class="plus-but" id="meth-plus${num}"></div>
	                </div>
	            </div>`; 
	        view += viewStr;
	        grCount++;
	    }
		sectView += '</div>';
		if(sectCnt==0) sectView = '';
		let panelView = sectView + view;
		methPanel.empty();
		methPanel.append(panelView);

		$('.sect-el').on(State.startEvent, (e)=>this.changeSection(e));
		$('#sect-el'+currSection).addClass('sect-active');
		$('#sect-el'+currSection).removeClass('sect-inactive');

		$('#line-pic0').addClass('top-line');
		$('#line-pic0').removeClass('mid-line');
		$('#line-pic'+(grCount-1)).addClass('bot-line');
		$('#line-pic'+(grCount-1)).removeClass('mid-line');
		$('.minus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.plus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.pats-count').on(State.startEvent, (e) => this.showEdit(e));
		$('.count-edit').on('keypress focusout', (e) => this.editNumber(e));

		if(len==1) {
			$('#line-pic'+(len-1)).removeClass('bot-line');
			$('#line-pic'+(len-1)).removeClass('top-line');
		}

		let freeCnt = 0, cnt;
		for(cnt=0 ; cnt<len ; cnt++) {
			if(this.methSet[cnt].result) freeCnt += this.methSet[cnt].result;
			else {
//!!D				if(this.methSet[cnt].rate>=0) // Это не фиктивные пациенты для добавочных препаратов
						freeCnt += this.methSet[cnt].pats;
			}
		}
		freeCnt = this.allPats - freeCnt;
let extraPats = this.countExtraPats(st);
freeCnt += extraPats;
State.extraPats[State.currPath][st] = extraPats;
		this.currFreePats = freeCnt;
		State.freePats[State.currPath][this.currSt] = freeCnt;
		$('#meth-free-pats').text(this.currFreePats);
		
		let currBudget;
		State.calc.resetDataBufs();
		currBudget = State.calc.calcOneGroup(State.currPath, this.currSt);
		currBudget = State.calc.padDigits(Math.floor(currBudget));
		$('.curr-budget').text(currBudget);
		State.distPanel.render(this.currSt);		
	}

///////////////////////////////////

	changeSection(e) {

		let num = e.target.id;

		num = num.slice(7);
		State.hoblModel.currSection = num;
		this.render(this.currSt);
	}

///////////////////////////////////

	completeEdit() {

		const num = this.currLineNum;
		let	pats = $('#count-edit'+num).val();
		let delta = pats - this.methSet[num].pats;
		let freePats = this.currFreePats - delta;	

		if(freePats<0) delta = this.currFreePats;
		this.methSet[num].pats += delta;
		this.currFreePats -= delta;
		pats = this.methSet[num].pats;
//console.log(pats);
		this.editMode = 'count';
		$('#count-edit'+num).val(pats);
		$('#pats-edit'+num).hide();
		$('#pats-text'+num).show();
		$('#pats-text'+num).text(pats);
		$('#meth-free-pats').text(this.currFreePats);
//		State.stPanel.markFreePats(State.currPath);
		if(delta!=0) {
			State.flDistChanged = 1;
		}
		this.displayResult();
		this.saveMethData();
	}

///////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.completeEdit();
				return;
			}
			if(e.charCode<48 || e.charCode>57) {
				e.preventDefault();
				return;
			}
		}
		if(e.type=='focusout') {
				this.completeEdit();
		}	
	}


///////////////////////////////////

	showEdit(e) {

		let num = e.target.id,
			str = num.slice(0,9);

		if(str!='pats-text') return;
		num = num.slice(9);
		if(this.editMode=='edit') {
			if(this.currLineNum==num) return;
			this.completeEdit();
		}

		setTimeout(() => {
			this.editMode = 'edit';
			this.currLineNum = num;
			$('#pats-text'+num).hide();
			$('#pats-edit'+num).show();
			$('#count-edit'+num).val(this.methSet[num].pats);
		},50);
		setTimeout(() => {
			$('#count-edit'+num).focus();
			let len = $('#count-edit'+num).val().length;
//console.log(len);		
			let inp = document.getElementById('count-edit'+num);
			inp.setSelectionRange(0,len);
		},100);

	}

///////////////////////////////////

	displayResult() {

		let result;

		State.stPanel.markFreePats(State.currPath);
		State.calc.resetDataBufs();
		result = State.calc.calcFullBudget();
		result = Math.round(result);
		$('.total-budget').text(State.calc.padDigits(result));

		$('#scr3-insert').html('');

		State.drugTable.render('');
		State.drugTable.render('vp');
		State.drugTable.render('ba');
		State.drugTable.render('hobl');

		let currBudget;
		State.calc.resetDataBufs();
		currBudget = State.calc.calcOneGroup(State.currPath, this.currSt);
		currBudget = State.calc.padDigits(Math.floor(currBudget));
		$('.curr-budget').text(currBudget);
if(State.flDistChanged==1) State.distPanel.resetChanels();		
State.flDistChanged = 0;
	}

///////////////////////////////////

	changeCounter(e) {

		let str = e.target.id, num,
			methSet = this.methSet;

		if(this.editMode=='edit') {
			this.completeEdit();
		}	
		num = str.slice(0,8);
		setTimeout(() => {
			if(num=='meth-min') {
				num = str.slice(10);
				if(methSet[num].pats < 1) return;
				methSet[num].pats--;
				this.currFreePats++;
				$('#pats-text'+num).text(''+methSet[num].pats);
				$('#meth-free-pats').text(this.currFreePats);
			}
			else if(num=='meth-plu') {
				if(this.currFreePats<1) return;
				num = str.slice(9);
				methSet[num].pats++;
				this.currFreePats--;
				$('#pats-text'+num).text(''+methSet[num].pats);
				$('#meth-free-pats').text(this.currFreePats);
			}	
			State.flDistChanged = 1;
			this.displayResult();
			this.saveMethData();
		}, 100);

	}

///////////////////////////////////
// Загружаем всю БД методов в локальный буфер, но не перегружаем в State
// Используется для частичной перезаписи БД методов 

	loadMethPack() {

		let dataStr, key = 'mnp_meth';

		dataStr = localStorage.getItem(key);	
		if(null==dataStr) this.methPack = {};
		else this.methPack = JSON.parse(dataStr);
	}	

///////////////////////////////////
// Упаковка структуры данных, описывающей раскладку по методам 
// для одной патологии. Используется перед сохранением распределения между сеансами  
// !!! В текущей реализации не может вызываться напрямую, т.к. для выборочной
// перезаписи нужно вызывать loadMethPack, а он вызывается только в saveMethData() 

	compactPathMethData(pathName) {

		let pathData, group, len, meth, methPack;

		if(pathName=='vp') pathData = vpData;
		else if(pathName=='ba') pathData = baData;
		else if(pathName=='hobl') pathData = hoblData;
		else console.log('!!! Error calling compactPathMethData()');
		methPack = this.methPack;
		if(!methPack[pathName]) methPack[pathName] = {};
		for(group in pathData) {
			
			if(!methPack[pathName][group]) methPack[pathName][group] = {};
			methPack[pathName][group].p = pathData[group].pats;
			methPack[pathName][group].r = pathData[group].result;
			methPack[pathName][group].m = [];
			len = pathData[group].meth.length;
			for(meth=0 ; meth<len ; meth++) {
				methPack[pathName][group].m.push(pathData[group].meth[meth].pats);
			}
			methPack[pathName][group].om = [];
			methPack[pathName][group].omd = [];
			methPack[pathName][group].rl = [];
			methPack[pathName][group].on = [];
			for(meth=0 ; meth<len ; meth++) {
				methPack[pathName][group].om.push(pathData[group].meth[meth].oms);
				methPack[pathName][group].omd.push(pathData[group].meth[meth].omsd);
				methPack[pathName][group].rl.push(pathData[group].meth[meth].rlo);
				methPack[pathName][group].on.push(pathData[group].meth[meth].onls);
			}
		}
	}

///////////////////////////////////
// Упаковываем и сохраняем в LS все данные распределения пациентов 

	saveMethData() {

		let cnt, path, len, dataStr, key = 'mnp_meth';

		this.loadMethPack();
		len = State.pathKeys.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			path = State.pathKeys[cnt];
			this.compactPathMethData(path);
		}
		// this.methPack.rmpFP = State.rmpFreePatsBuf;
		this.methPack.baFP = State.baFreePatsBuf;
		this.methPack.hoblFP = State.hoblFreePatsBuf;
		this.methPack.stPats = State.stPats;
		// this.methPack.rzMults = State.rzMultBuf;
		// this.methPack.rzPats = State.rzPatsBuf;
		this.methPack.vpMults = State.vpMultBuf;
		this.methPack.vpPats = State.vpPatsBuf;
		this.methPack.baMults = State.baMultBuf;
		this.methPack.baPats = State.baPatsBuf;
		this.methPack.hoblMults = State.hoblMultBuf;
		this.methPack.hoblPats = State.hoblPatsBuf;
		dataStr = JSON.stringify(this.methPack);
		localStorage.setItem(key,dataStr);
		State.pathPanel.savePathData();

	}

///////////////////////////////////
// Загрузка из this.methPack структуры данных, описывающей раскладку по методам 
// для одной патологии.  

	restorePathMethData(pathName) {

		let pathData, group, len, meth, methPack;

		if(pathName=='vp') pathData = vpData;
		else if(pathName=='ba') pathData = baData;
		else if(pathName=='hobl') pathData = hoblData;
		else console.log('!!! Error calling restorePathMethData()');
		methPack = this.methPack;
		for(group in pathData) {
			pathData[group].pats = methPack[pathName][group].p;
			pathData[group].result = methPack[pathName][group].r;
			len = pathData[group].meth.length;
			for(meth=0 ; meth<len ; meth++) {
				pathData[group].meth[meth].pats = methPack[pathName][group].m[meth];
			}
			for(meth=0 ; meth<len ; meth++) {
				if(methPack[pathName][group].om) {
					pathData[group].meth[meth].oms = methPack[pathName][group].om[meth];
					pathData[group].meth[meth].omsd = methPack[pathName][group].omd[meth];
					pathData[group].meth[meth].rlo = methPack[pathName][group].rl[meth];
					pathData[group].meth[meth].onls = methPack[pathName][group].on[meth];
				} else {
					pathData[group].meth[meth].oms = 0;
					pathData[group].meth[meth].omsd = 0;
					pathData[group].meth[meth].rlo = 0;
					pathData[group].meth[meth].onls = 0;
				}
			}
		}
	}

///////////////////////////////////
// Загружаем из LS в this.methPack все данные распределения пациентов 

	loadMethData() {

		let cnt, path, len, group, dataStr, key = 'mnp_meth';

		dataStr = localStorage.getItem(key);	
		this.methPack = JSON.parse(dataStr);
		// State.rlFreePatsBuf = this.methPack.rlFP;
		State.baFreePatsBuf = this.methPack.baFP;
		State.hoblFreePatsBuf = this.methPack.hoblFP;
		State.stPats = this.methPack.stPats;
		// State.rzMultBuf = this.methPack.rzMults;
		// State.rzPatsBuf = this.methPack.rzPats;
		State.vpMultBuf = this.methPack.vpMults;
		State.vpPatsBuf = this.methPack.vpPats;
		State.baMultBuf = this.methPack.baMults;
		State.baPatsBuf = this.methPack.baPats;
		State.hoblMultBuf = this.methPack.hoblMults;
		State.hoblPatsBuf = this.methPack.hoblPats;
		len = State.pathKeys.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			path = State.pathKeys[cnt];
			this.restorePathMethData(path);
		}
		State.pathPanel.loadPathData();
		// for(group in rlData) State.rlPatsBuf[group] = rlData[group].pats;
		// for(group in rmpData) State.rmpPatsBuf[group] = rmpData[group].pats;
	}

///////////////////////////////////
// Сбрасываем записанные в LS данные по распределению пациентов

	resetMethData() {

		const lsName = 'mnp_meth';

		localStorage.removeItem(lsName);
	} 


///////////////////////////////////
// Загружаем в целевой набор методов патч, корректирующий часть методов

	patchMethSet(target, patch) {

		let currGroup, currSet, targetSet, len, i;

		for(currGroup in patch) {
			currSet = patch[currGroup].meth;
			targetSet = target[currGroup].meth;
			len = currSet.length;
			for(i=0 ; i<len ; i++) {
				if(currSet[i] != -1) {
					targetSet[i].drugs = currSet[i].drugs;
					targetSet[i].dose = currSet[i].dose;
					targetSet[i].cycles = currSet[i].cycles;
				}
			}
		}
	}
}

export default MethPanel;