;"use strict";

import State from './state';
import drugData from './drugdata';

class DrugTable {

	constructor() {
		$('#xls-butt').on(State.startEvent, (e) => this.renderXls(e));
	}

//////////////////////////////

	render(path) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, totPriceStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind, typeStr, classStr,
			pathName = '', totalPrice, doseMg, doseStr, pathNum = -1;	
		const len = State.drugOrd.length;
		const holder = `<div class='v-holder'>&nbsp<br></div>`;

        for(cnt=0, lim=State.pathKeys.length ; cnt<lim ; cnt++) {
        	if(path==State.pathKeys[cnt]) {
        		pathName = State.pathList[cnt];
        		pathNum = cnt;
        		break;
        	}
        }
		if(pathNum>-1 && State.selPath[pathNum]==0) return;

let pathStr = pathName;
let packRatio = State.mainMonthNum/12.0;
let	num = State.mainMonthNum % 10;
let	str = ' месяцев';
if(num<5 && num>1) str = ' месяца';
if(num==1) str = ' месяц';
if(State.mainMonthNum>5 && State.mainMonthNum<21) str = ' месяцев';
let repMonthStr = ' ' + State.mainMonthNum + str;

        // if(pathName=='') {
        // 	pathStr = 'Общий бюджет';
        // 	subTotal = '';
        // } else {
        // 	subTotal = `<div class="scr3-subtotal">
        // 		Суммарные расходы для нозологии ${pathStr}: <span id="subtotal-${path}">${State.pathBudgets[path]}</span> руб.
        // 	</div>`;
        // }
if(pathName=='') pathStr = 'Общий бюджет';
//        let insert = subTotal +
        let insert = 
                `<div class="scr3-delim" id="delim-${path}">${pathName}</div>
                <div class="scr3-table-head" id="scr3-table-hd">
                    <div class="scr3-head-col" id="scr3-hcol1">Препарат (МНН)</div>
                    <div class="scr3-head-col" id="scr3-hcol2"> Количество<br>упаковок</div>
                    <div class="scr3-head-col" id="scr3-hcol3">Кол-во мг/доз<br>суммарно</div>
                    <div class="scr3-head-col" id="scr3-hcol4">Расчет затрат<br>руб.</div>
                </div>`;                

		State.calc.resetDataBufs();
        if(path.length>1) {        
			State.calc.calcOnePath(path);
		} else { // Total budget for all pathologies
			State.calc.calcFullBudget();
		}
		drugBuf = State.drugBuf;
//if(path=='ba') console.log(drugBuf);
		tableCode = $('#scr3-insert').html();
		tableCode += insert;
		totalPrice = 0;
//console.log(drugBuf);
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			if(color==0) colClass = 'grey'; else colClass = '';
//		nOfPacks = State.calc.padDigits(drugBuf[ind].packs);	
nOfPacks = Math.ceil(drugBuf[ind].packs*packRatio);	
			if(nOfPacks==0) continue;
let nOfPacksStr = State.calc.padDigits(nOfPacks);
			currDrug = drugData[ind];
 			result = drugBuf[ind].summ;
//console.log(result);
//		doseMg = drugBuf[ind].mg;
doseMg = nOfPacks*currDrug.dose*currDrug.set;
			doseStr = State.calc.padDigits(Math.floor(doseMg));
result = nOfPacks*currDrug.price;			
 			result = Math.floor(result);
			totalPrice += result;
 			result = State.calc.padDigits(result);
			priceStr = State.calc.padFloat(currDrug.price);
			if(currDrug.type=='a') { typeStr = 'Антиб.'; classStr = 'target-lab';}
			if(currDrug.type=='v') { typeStr = 'Прот.вир.'; classStr = 'cito-lab';}
			if(currDrug.type=='m') { typeStr = 'Муколит.'; classStr = 'horm-lab';}
			if(currDrug.type=='ak') { typeStr = 'Ант.коаг.'; classStr = 'immuno-lab';}
			if(currDrug.type=='o') { typeStr = 'Другое'; classStr = 'other-lab';}
			if(currDrug.type=='ar') { typeStr = 'Аэроз.'; classStr = 'target-lab';}
			if(currDrug.type=='in') { typeStr = 'Ингал.'; classStr = 'cito-lab';}
			if(currDrug.type=='n') { typeStr = ''; classStr = 'none-lab';}
			lineTemplate = 
                `<div class="scr3-line" id="scr3-line${cnt}">
                    <div class="scr3-col t-col1 ${colClass}" id="col1-line${cnt}">
                    	<div class="type-label ${classStr}">${typeStr}</div>
                    	<div class="scr3-name-cell">${currDrug.name}</div>
                    </div>
                    <div class="scr3-col t-col2 ${colClass}" id="col2-line${cnt}">${nOfPacksStr}</div>
                    <div class="scr3-col t-col3 ${colClass}" id="col3-line${cnt}">${doseStr}</div>
                    <div class="scr3-col t-col4 ${colClass}" id="col4-line${cnt}">${result}</div>
                </div>`;
            tableCode += lineTemplate;    
			color++; if (color>1) color = 0;	
		}
totPriceStr = State.calc.padDigits(totalPrice);
        if(pathName=='') {
        	subTotal = '';
        } else {
        	subTotal = `<div class="scr3-subtotal">
        		Суммарные расходы для нозологии ${pathStr}: <span id="subtotal-${path}">${totPriceStr}</span> руб.
        	</div>`;
        }
tableCode += subTotal;		
		tableCode += holder;
		tableCode += '<br>'
		$('#scr3-insert').html(tableCode);
		totalPrice = Math.ceil(totalPrice/10000);
		totalPrice = totalPrice/100;
		totPriceStr = State.calc.padFloat(totalPrice);
		if(pathName=='') $('#total-budget-mln').text(totPriceStr);
		$('#main-month-length').text(repMonthStr);
	}

//////////////////////////////

	renderXlsLines(path) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, totPriceStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind, typeStr, classStr,
			pathName = '', totalPrice, doseMg, doseStr, pathNum = -1, subHeader, totalPats;	
		const len = State.drugOrd.length;

        for(cnt=0, lim=State.pathKeys.length ; cnt<lim ; cnt++) {
        	if(path==State.pathKeys[cnt]) {
        		pathName = State.pathList[cnt];
        		pathNum = cnt;
        		break;
        	}
        }
		if(pathNum>-1 && State.selPath[pathNum]==0) return;

let pathStr = pathName;
let packRatio = State.mainMonthNum/12.0;
let	num = State.mainMonthNum % 10;
let	str = ' месяцев';
if(num<5 && num>1) str = ' месяца';
if(num==1) str = ' месяц';
if(State.mainMonthNum>5 && State.mainMonthNum<21) str = ' месяцев';
let repMonthStr = '' + State.mainMonthNum + str;

        // if(pathName=='') {
        // 	pathName = 'Общий бюджет';
        // 	subTotal = 'Суммарные расходы:';
        // } else {
        // 	subTotal = `Суммарные расходы для нозологии ${pathName}:`;
        // }

if(pathName=='') pathStr = 'Общий бюджет';

		let blankStr = `<Row ss:AutoFitHeight="0" ss:Height="10">
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		   </Row>`;

		header = `<Row ss:AutoFitHeight="0" ss:Height="27">
			    <Cell ss:MergeAcross="4" ss:StyleID="s22"><Data ss:Type="String">${pathStr}</Data></Cell>
			   </Row>`;

		subHeader = `<Row ss:AutoFitHeight="0" ss:Height="21.75">
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Тип препарата</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Препарат (МНН)</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Количество упаковок</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Количество мг суммарно</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Расчет затрат руб.</Data></Cell>
			   </Row>`;	   

        for(cnt=0, lim=State.pathKeys.length ; cnt<lim ; cnt++) {
        	if(path==State.pathKeys[cnt]) {
        		pathName = State.pathList[cnt];
        		pathNum = cnt;
        		break;
        	}
        }
		if(pathNum>-1 && State.selPath[pathNum]==0) return;

		State.calc.resetDataBufs();
        if(path.length>1) {        
			State.calc.calcOnePath(path);
//			totalPats = Math.ceil(State.pathSumm[pathNum]*packRatio);
			totalPats = Math.ceil(State.pathTotals[pathNum]*packRatio);
		} else { // Total budget for all pathologies
			State.calc.calcFullBudget();
			totalPats = Math.ceil(State.totPatSumm*packRatio);
		}
		drugBuf = State.drugBuf;
		totalPrice = 0;
		tableCode = blankStr + header + subHeader;
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			if(color==0) colClass = 'grey'; else colClass = '';
//		nOfPacks = drugBuf[ind].packs;	
nOfPacks = Math.ceil(drugBuf[ind].packs*packRatio);	
			if(nOfPacks==0) continue;
			currDrug = drugData[ind];
//		result = drugBuf[ind].summ;
//		doseMg = drugBuf[ind].mg;
doseMg = nOfPacks*currDrug.dose*currDrug.set;
			doseStr = Math.floor(doseMg);
result = nOfPacks*currDrug.price;			
 			result = Math.floor(result);
			totalPrice += result;
			priceStr = currDrug.price;
			if(currDrug.type=='a') typeStr = 'Антиб.'; 
			if(currDrug.type=='v') typeStr = 'Прот.вир.'; 
			if(currDrug.type=='m') typeStr = 'Муколит.'; 
			if(currDrug.type=='ak') typeStr = 'Ант.коаг.'; 
			if(currDrug.type=='o') typeStr = 'Другое'; 
			if(currDrug.type=='ar') typeStr = 'Аэроз.'; 
			if(currDrug.type=='in') typeStr = 'Ингал.';
            lineTemplate = `<Row ss:AutoFitHeight="0" ss:Height="30">
			    <Cell ss:StyleID="s24"><Data ss:Type="String">${typeStr}</Data></Cell>
			    <Cell ss:StyleID="s25"><Data ss:Type="String">${currDrug.name}</Data></Cell>
			    <Cell ss:StyleID="s24"><Data ss:Type="Number">${nOfPacks}</Data></Cell>
			    <Cell ss:StyleID="s26"><Data ss:Type="Number">${doseStr}</Data></Cell>
			    <Cell ss:StyleID="s26"><Data ss:Type="Number">${result}</Data></Cell>
			   </Row>`;    

            tableCode += lineTemplate;    
		}

		totalPrice = Math.ceil(totalPrice);
        if(pathName=='') {
        	subTotal = `Суммарные расходы на ${repMonthStr}:`;
        } else {
        	subTotal = `Суммарные расходы на ${repMonthStr} для нозологии ${pathName}:`;
        }

		totPriceStr = `<Row ss:AutoFitHeight="0" ss:Height="10">
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		   </Row>
		   <Row ss:AutoFitHeight="0" ss:Height="18">
		    <Cell ss:StyleID="s27"><Data ss:Type="String"></Data></Cell>
		    <Cell ss:StyleID="s27"/>
		    <Cell ss:StyleID="s28"><Data ss:Type="String">Количество пациентов:</Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="Number">${totalPats}</Data></Cell>
		    <Cell ss:StyleID="s29"><Data ss:Type="String"> </Data></Cell>
		   </Row>
		   <Row ss:AutoFitHeight="0" ss:Height="18">
		    <Cell ss:StyleID="s27"><Data ss:Type="String"></Data></Cell>
		    <Cell ss:StyleID="s27"/>
		    <Cell ss:StyleID="s28"><Data ss:Type="String">${subTotal}</Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="Number">${totalPrice}</Data></Cell>
		    <Cell ss:StyleID="s29"><Data ss:Type="String"> рублей</Data></Cell>
		   </Row>`;
		tableCode += totPriceStr;
		tableCode += blankStr;   
		tableCode += blankStr;   

		return tableCode;
	}

//////////////////////////////


	renderXls() {

		let tableStr;
		let tabHeader = `<?xml version="1.0"?>
			<?mso-application progid="Excel.Sheet"?>
			<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
			 xmlns:o="urn:schemas-microsoft-com:office:office"
			 xmlns:x="urn:schemas-microsoft-com:office:excel"
			 xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
			 xmlns:html="http://www.w3.org/TR/REC-html40">
			 <DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
			  <Author>Microsoft Corporation</Author>
			  <LastAuthor>Alex</LastAuthor>
			  <Created>1996-10-08T23:32:33Z</Created>
			  <LastSaved>2019-09-27T14:42:23Z</LastSaved>
			  <Version>11.5606</Version>
			 </DocumentProperties>
			 <ExcelWorkbook xmlns="urn:schemas-microsoft-com:office:excel">
			  <WindowHeight>7320</WindowHeight>
			  <WindowWidth>9720</WindowWidth>
			  <WindowTopX>120</WindowTopX>
			  <WindowTopY>120</WindowTopY>
			  <AcceptLabelsInFormulas/>
			  <ProtectStructure>False</ProtectStructure>
			  <ProtectWindows>False</ProtectWindows>
			  <DisplayInkNotes>False</DisplayInkNotes>
			 </ExcelWorkbook>
			<Styles>
			  <Style ss:ID="Default" ss:Name="Normal">
			   <Alignment ss:Vertical="Bottom"/>
			   <Borders/>
			   <Font/>
			   <Interior/>
			   <NumberFormat/>
			   <Protection/>
			  </Style>
			  <Style ss:ID="s22">
			   <Alignment ss:Horizontal="Center" ss:Vertical="Center"/>
			   <Font x:CharSet="204" x:Family="Swiss" ss:Size="14"/>
			  </Style>
			  <Style ss:ID="s23">
			   <Alignment ss:Horizontal="Center" ss:Vertical="Center"/>
			   <Borders>
			    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
			   </Borders>
			   <Font x:CharSet="204" x:Family="Swiss" ss:Bold="1"/>
			  </Style>
			  <Style ss:ID="s24">
			   <Borders>
			    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
			   </Borders>
			  </Style>
			  <Style ss:ID="s25">
			   <Alignment ss:Vertical="Bottom" ss:WrapText="1"/>
			   <Borders>
			    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
			   </Borders>
			  </Style>
			  <Style ss:ID="s26">
			   <Borders>
			    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
			    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
			   </Borders>
			   <NumberFormat ss:Format="#,##0"/>
			  </Style>
			  <Style ss:ID="s27">
			   <Font x:CharSet="204" x:Family="Swiss" ss:Bold="1"/>
			   <NumberFormat ss:Format="#,##0"/>
			  </Style>
			  <Style ss:ID="s28">
			   <Alignment ss:Horizontal="Right" ss:Vertical="Bottom"/>
			  </Style>
			  <Style ss:ID="s29">
			   <Alignment ss:Horizontal="Left" ss:Vertical="Bottom"/>
			  </Style>
			 </Styles>
			 <Worksheet ss:Name="Результат расчетов">
			  <Table ss:ExpandedColumnCount="5" x:FullColumns="1"
			   x:FullRows="1">
			   <Column ss:AutoFitWidth="0" ss:Width="97.5"/>
			   <Column ss:AutoFitWidth="0" ss:Width="271.5"/>
			   <Column ss:AutoFitWidth="0" ss:Width="116.25"/>
			   <Column ss:AutoFitWidth="0" ss:Width="134.25"/>
			   <Column ss:AutoFitWidth="0" ss:Width="102.75"/>
				`;

			let tabFooter = `  </Table>
				  <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
				   <PageSetup>
				    <PageMargins x:Bottom="0.984251969" x:Left="0.78740157499999996"
				     x:Right="0.78740157499999996" x:Top="0.984251969"/>
				   </PageSetup>
				   <Print>
				    <ValidPrinterInfo/>
				    <PaperSizeIndex>9</PaperSizeIndex>
				    <HorizontalResolution>1200</HorizontalResolution>
				    <VerticalResolution>1200</VerticalResolution>
				   </Print>
				   <Selected/>
				   <Panes>
				    <Pane>
				     <Number>3</Number>
				     <ActiveRow>6</ActiveRow>
				    </Pane>
				   </Panes>
				   <ProtectObjects>False</ProtectObjects>
				   <ProtectScenarios>False</ProtectScenarios>
				  </WorksheetOptions>
				 </Worksheet>
				</Workbook>
				`;   

		let linesStr = '';
		linesStr += this.renderXlsLines('');
		linesStr += this.renderXlsLines('vp');
		linesStr += this.renderXlsLines('ba');
		linesStr += this.renderXlsLines('hobl');

		tableStr = tabHeader + linesStr;
		tableStr += tabFooter;

        let blob = new Blob([tableStr], { type: 'application/vnd.ms-excel' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'results.xls';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
	}

} 

export default DrugTable;