;"use strict";

import drugData from './drugdata';
import State from './state';
// Drug Types:
// t - target
// c - cytostat
// h - hormon
// i - immuno
// o - other
// n - no type
// а - antibiotic
// v - antivirus
// m - mukolitic
// ak - anticougulant
// ar - aerozole
// in - inhal
let initTable = [

// VP
	{ name: 'N-ацетилцистеин 600 мг 6 пакетов-саше',	price: 61.74, 	list: 1, dose: 600,	set: 6, 	on: 1, type: 'm'}, // 0 
	{ name: 'Азитромицин 0,5 гр. лиофизат для приготовления р-ра №1', price: 234.35, list: 1, dose: 500, set: 1, on: 1, type: 'a'}, // 1
	{ name: 'Амброксол 300 мг №1 флакон',			price: 82.66, 	list: 1, dose: 30, set: 10, on: 1, type: 'm'}, 		// 2
	{ name: 'Aмикацин 0,5 гр. №1 порошёк для р-ра флаконы',	price: 26.45, list: 1, dose: 500, set: 1, on: 0, type: 'a'},// 3
	{ name: 'Амикацин 1 гр. №1 порошёк для р-ра флаконы', price: 49.85, list: 1, dose: 1000, set: 1, on: 0, type: 'a'}, // 4
	{ name: 'Амоксициллин  1 гр. №20 таб.',			price: 366.03, 	list: 1, dose: 1000, set: 20, 	on: 1, type: 'a'}, 	// 5
	{ name: 'Амоксициллин/клавуланат 875 мг №14 таб.', price: 351.90,	list: 1, dose: 875, set: 14, on: 1, type: 'a'}, // 6
	{ name: 'Амоксициллин/клавуланат 1000 мг №1',	price: 97.90,	list: 0, dose: 1000, set: 1, 	on: 1, type: 'a'}, 	// 7
	{ name: 'Амоксициллин/сульбактам 750 мг порошок для раствора',	price: 52.4,list: 0, dose: 750,	set: 1, on: 0, type: 'a'}, // 8
	{ name: 'Ампициллин/сульбактам 750 мг №1',		price: 90.0,	list: 1, dose: 750,	set: 1,		on: 0,	type: 'a'}, // 9
	{ name: 'Ампициллин 1000 мг/флакон №1',			price: 12.25,	list: 1, dose: 1000, set: 1,	on: 1, 	type: 'a'}, // 10
	{ name: 'Доксициклин 100 мг №10 таб.',			price: 258.00, 	list: 1, dose: 100,	set: 10, 	on: 1, 	type: 'a'}, // 11
	{ name: 'Занамивир 5 мг 20 доз',				price: 1000, 	list: 0, dose: 5, 	set: 20,	on: 0, 	type: 'v'}, // 12
	{ name: 'Имипенем 0,5 гр. №1 флакон',			price: 900.00,	list: 0, dose: 500, set: 1, 	on: 0, 	type: 'a'}, // 13
	{ name: 'Кларитромицин 0,5 гр №1 флакон',		price: 488.65,	list: 1, dose: 500, set: 1, 	on: 1, 	type: 'a'}, // 14
	{ name: 'Клиндамицин 300 мг №10 ампулы',		price: 476.61,	list: 1, dose: 300, set: 10, 	on: 0, 	type: 'a'}, // 15
	{ name: 'Левофлоксацин 500 мг №1',				price: 828.18,	list: 1, dose: 500, set: 1, 	on: 0, 	type: 'a'}, // 16
	{ name: 'Левофлоксацин 500 мг №10 таб.',		price: 320, 	list: 1, dose: 500, set: 10, 	on: 1, 	type: 'a'}, // 17
	{ name: 'Меропенем 0,5 гр. №10',				price: 3358.80, list: 1, dose: 500, set: 10, 	on: 0, 	type: 'a'}, // 18
	{ name: 'Метронидазол 500 мг бутылка №1',		price: 33.42,	list: 1, dose: 500,	set: 1, 	on: 0, 	type: 'a'}, // 19
	{ name: 'Моксифлоксацин 400 мг №5 таб.',		price: 398.80,	list: 1, dose: 400, set: 5, 	on: 1, 	type: 'a'}, // 20
	{ name: 'Моксифлоксацин 400 мг р-р для инф. №1',price: 1946.59, list: 1, dose: 400, set: 1, 	on: 0, 	type: 'a'}, // 21
	{ name: 'Осельтамивир 75 мг №10 капсулы',		price: 7381.06,	list: 1, dose: 75, 	set: 10, 	on: 1, 	type: 'v'}, // 22
	{ name: 'Пиперациллин/тазобактам 4,5 гр. №1',	price: 860.20,	list: 0, dose: 4500,set: 1, 	on: 0, 	type: 'a'}, // 23
	{ name: 'Цефдиторен 200 мг №20 таб. ',			price: 1277.40,	list: 0, dose: 200, set: 20, 	on: 0, 	type: 'a'}, // 24
	{ name: 'Цефотаксим 1 гр. №1',					price: 130.84, 	list: 1, dose: 1000,set: 1, 	on: 0, 	type: 'a'}, // 25
	{ name: 'Цефтаролин 0,6 гр. №1',				price: 23781.80,list: 1, dose: 600,	set: 1, 	on: 0, 	type: 'a'}, // 26
	{ name: 'Эртапенем 1 гр. №1',					price: 1670.75, list: 1, dose: 1000,set: 1, 	on: 0, 	type: 'a'}, // 27 
	{ name: 'Цефтриаксон 1 гр порошёк для инъекций №10', price: 189.95, list: 1, dose: 1000, set: 10, on: 0, type: 'a'}, // 28
	{ name: 'Ципрофлоксацин 0,2 гр. №1',			price: 120.00, 	list: 1, dose: 200,	set: 1, 	on: 0, 	type: 'a'}, // 29
	{ name: 'Эноксапарин 40 мг шприц №2',			price: 305.80, 	list: 1, dose: 40,	set: 2, 	on: 1, 	type: 'ak'}, // 30
	// 31 => 27
// BA
	{ name: 'Беклометазон/сальбутамол аэрозоль для ингаляций 250 мкг/доза+100 мкг/доза',			price: 666.09, 	list: 0, dose: 1,	set: 200, 	on: 0, 	type: 'ar'}, // 31
	{ name: 'Беклометазон/формотерол аэрозоль для ингаляций 100 мкг+6 мкг/доза 120 ингаляций',			price: 1900, 	list: 1, dose: 1,	set: 120, 	on: 1, 	type: 'ar'}, // 32
	{ name: 'Беклометазона дипропионат аэрозоль для ингаляций дозированный 100 мкг/доза 200 доз',			price: 233.00, 	list: 1, dose: 1,	set: 200, 	on: 1, 	type: 'ar'}, // 33
	{ name: 'Будесонид порошок для ингаляций дозированный 80 мкг+4.5 мкг/доза 60 доз',	price: 661.20, 	list: 1, dose: 1,	set: 60, 	on: 1, 	type: 'in'}, // 34
	{ name: 'Будесонид р-р для ингаляций 1 мг №10',			price: 289.40, 	list: 1, dose: 1,	set: 10, 	on: 1, 	type: 'in'}, // 35
	{ name: 'Будесонид/формотерол аэрозоль для ингаляций дозированный 160 мкг+4.5 мкг/доза 120 доз',			price: 1729.20, 	list: 1, dose: 1,	set: 120, 	on: 0, 	type: 'ar'}, // 36
	{ name: 'Вилантерол/флутиказон порошок для ингаляций дозированный 22 мкг+184 мкг/доза 30 доз',			price: 1896.90,		list: 1, dose: 1,	set: 30, 	on: 1, 	type: 'in'}, // 37
	{ name: 'Меполизумаб лиофилизат для приготовления раствора для подкожного введения 100 мг №1',			price: 68829.98, 	list: 1, dose: 100,	set: 1, 	on: 1, 	type: 'o'}, // 38
	{ name: 'Метилпреднизолон лиофизат 250 мг №1',			price: 268.00, 	list: 1, dose: 250,	set: 1, 	on: 0, 	type: 'o'}, // 39
	{ name: 'Монтелукаст табл. 10 мг №28',	price: 1057.86, 	list: 0, dose: 10,	set: 28, 	on: 0, 	type: 'o'}, // 40
	{ name: 'Омализумаб 150 мг №1',	price: 16806.56, 	list: 1, dose: 150,	set: 1, 	on: 1, 	type: 'o'}, // 41
	{ name: 'Преднизолон табл., 5 мг №10',			price: 21.50, 	list: 1, dose: 5,	set: 10, 	on: 0, 	type: 'o'}, // 42
	{ name: 'Салметерол+флутиказон аэрозоль для ингаляций дозированный 25 мкг+250 мкг/доза',			price: 532.08, 	list: 1, dose: 1,	set: 120, 	on: 1, 	type: 'ar'}, // 43
	{ name: 'Сальбутамол р-ра для ингаляций 5 мг №60',			price: 996.00, 	list: 1, dose: 5,	set: 60, 	on: 1, 	type: 'in'}, // 44
	{ name: 'Теофиллин табл. пролонгированного действия 300 мг №50',			price: 316.13, 	list: 0, dose: 300,	set: 50, 	on: 1, 	type: 'o'}, // 45
	{ name: 'Тиотропия бромид капсулы с порошком для ингаляций 18 мкг №60',			price: 3416.40, 	list: 1, dose: 0.018,	set: 60, 	on: 1, 	type: 'in'}, // 46
	{ name: 'Фенотерол р-р для ингаляций 20 мг №1',			price: 308.68, 	list: 0, dose: 2,	set: 10, 	on: 0, 	type: 'in'}, // 47
	{ name: 'Флутиказона пропионат аэрозоль для ингаляций дозированный 250 мкг/доза 120 доз',			price: 100, 	list: 0, dose: 1,	set: 120, 	on: 0, 	type: 'ar'}, // 48
	{ name: 'Флутиказона пропионат аэрозоль для ингаляций дозированный 125 мкг/доза 120 доз',			price: 15.22, 	list: 0, dose: 1,	set: 120, 	on: 0, 	type: 'ar'}, // 49
	{ name: 'Будесонид/формотерол аэрозоль для ингаляций дозированный 80 мкг+4.5 мкг/доза 120 доз',			price: 100.0, 	list: 1, dose: 1,	set: 120, 	on: 0, 	type: 'ar'}, // 50
	{ name: 'Салметерол+флутиказон аэрозоль для ингаляций дозированный 25 мкг+125 мкг/доза',			price: 581.17, 	list: 1, dose: 1,	set: 120, 	on: 1, 	type: 'ar'}, // 51
	{ name: 'Вилантерол/флутиказон порошок для ингаляций дозированный 22 мкг+92 мкг/доза 30 доз',			price: 1517.10,		list: 1, dose: 1,	set: 30, 	on: 1, 	type: 'in'}, // 52
	{ name: 'Бенрализумаб 30 мг №1 р-р для п/к', price: 123752.57,		list: 1, dose: 30,	set: 1, 	on: 1, 	type: 'o'}, // 53
	{ name: 'Реслизумаб концентрат для приготовления р-а 100 мг №1', price: 20467.08,		list: 1, dose: 100,	set: 1, 	on: 1, 	type: 'o'}, // 54
	{ name: 'Дупилумаб 200 мг р-р для п/к №2', price: 46368,		list: 1, dose: 200,	set: 2, 	on: 0, 	type: 'o'}, // 55
	{ name: 'Тиотропия бромид раствор для ингаляций 2,5 мкг/доза 60 ингаляций (30 доз)', price: 2181.14, list: 1, dose: 1,	set: 60, on: 1, type: 'in'}, // 56

// HOBL
	{ name: 'Аклидиния бромид порошок для ингаляций дозированный 322 мкг №60', price: 34.90, list: 0, dose: 1,	set: 60, on: 0, type: 'in'}, // 57
	{ name: 'Аклидиния бромид/формотерол порошок для ингаляций дозированный 340 мкг+11,8 мкг 60 доз', price:2464.77 , list: 0, dose: 1,	set: 60, on: 0, type: 'in'}, // 58
	{ name: 'Гликопиррония бромид капсулы с порошком для ингаляций 50 мкг №30', price: 1521.92, list: 1, dose: 0.05,	set: 30, on: 0, type: 'in'}, // 59
	{ name: 'Гликопиррония бромид/индакатерол капсулы с порошком для ингаляций 50 мкг + 110 мкг №30', price: 2327.06, list: 1, dose: 0.16,	set: 30, on: 0, type: 'in'}, // 60
	{ name: 'Тиотропия бромид/олодатерол раствор для ингаляций дозированный 2,5 мкг+2,5 мкг 60 доз', price: 98.99, list: 0, dose: 1,	set: 60, on: 0, type: 'in'}, // 61
	{ name: 'Умеклидиния бромид/вилантерол порошок для ингаляций дозированный 30 доз', price: 66.77, list: 0, dose: 1,	set: 30, on: 0, type: 'in'}, // 62
	{ name: 'Формотерол аэрозоль дозированный для ингаляций 12 мкг №120', price: 995.69, list: 1, dose: 0.012,	set: 120, on: 0, type: 'in'}, // 63

// FAKE
	{ name: 'Ипратропия бромид аэрозоль дозированный для ингаляций 20 мкг №120', price: 100, list: 1, dose: 1,	set: 120, on: 1, type: 'in'}, // 64
// 2 ингаляционные дозы (40 мкг) 4 раза в день.	
	{ name: 'Умеклидиния бромид порошок для ингаляций дозированный 30 доз', price: 100, list: 0, dose: 1,	set: 30, on: 0, type: 'in'}, // 65

// HOBL
	{ name: 'Рофлумиласт 0,5 мг №90', price: 112.12, list: 0, dose: 0.5,	set: 90, on: 0, type: 'o'}, // 66
	{ name: 'Амброксол таблетки диспергируемые 60 мг №10', price: 117.5, list: 1, dose: 60,	set: 10, on: 0, type: 'in'}, // 67
	{ name: 'Карбоцистеин капсулы 375 мг №30', price: 276.16, list: 0, dose: 375,	set: 30, on: 0, type: 'm'}, // 68
	{ name: 'Эрдостеин капсулы 300 мг №80', price: 399.3, list: 0, dose: 300,	set: 80, on: 0, type: 'm'}, // 69
	{ name: 'Фенотерол аэрозоль для ингаляций дозированный 100 мкг 200 доз №1', price: 468.1, list: 0, dose: 1,	set: 200, on: 0, type: 'ar'}, // 70
	{ name: 'Цефиксим 400 мг №7', price: 1030, list: 0, dose: 400,	set: 7, on: 0, type: 'a'}, // 71
	{ name: 'Амоксициллин/клавуланат 1000 мг №14', price: 284.2, list: 0, dose: 1000,	set: 14, on: 0, type: 'a'}, // 72
	{ name: 'Ипратропия бромид раствор для ингаляций 0,25 мг/мл 20 мл №1', price: 165, list: 1, dose: 1,	set: 10, on: 0, type: 'in'}, // 73
	];

let dataSet ;

class DrugStore {

	constructor() {

		const lsName = 'mnp_store';
		let currDrug, cnt, len, dataStr;

		dataSet = localStorage.getItem(lsName);
		if(null == dataSet) {
//!!D
//		if(true) {
			len = initTable.length;
			for(cnt=0 ; cnt<len ; cnt++) {
				currDrug = initTable[cnt];
				currDrug.changed = 0;
			}
			dataStr = JSON.stringify(initTable);
			localStorage.setItem(lsName, dataStr);
console.log('Price Storage init');

		}

		dataStr = localStorage.getItem(lsName);	
		dataSet = JSON.parse(dataStr);
	}

////////////////////////////////////////

	resetPrice() {

		const lsName = 'mnp_store';
		let currDrug, cnt, len, dataStr;

console.log('Reset Price');

		len = initTable.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			currDrug = initTable[cnt];
			currDrug.changed = 0;
		}
		dataStr = JSON.stringify(initTable);
		localStorage.setItem(lsName, dataStr);

		dataStr = localStorage.getItem(lsName);	
		dataSet = JSON.parse(dataStr);
		this.fillDrugData();
		State.priceTable.render();
		State.methPanel.displayResult();
	}

////////////////////////////////////////

	resetStorage() {

		const lsName = 'mnp_store';

		localStorage.removeItem(lsName);

	}

////////////////////////////////////////

	objCopy(src, dest) {
		let key;

		for(key in src) {
			if(src.hasOwnProperty(key)) dest[key] = src[key];
		}
	}


////////////////////////////////////////

	fillDrugData() {

		const len = dataSet.length;
		let cnt, currDrug, price, regAdd, i, tabLen, 
			tabletList = State.tabletForm;

		// Устанавливаем признак округления для таблеток
		tabLen = tabletList.length;
		for(i=0 ; i<tabLen ; i++) {
			dataSet[tabletList[i]].tab = 1;
		}	
		// Устанавливаем признак инъекционного препарата
		for(i=0 ; i<len ; i++) {
			dataSet[i].inj = 1;
			dataSet[i].oth = 0;
		}
		tabLen = State.perorForm.length;
		for(i=0 ; i<tabLen ; i++) {
			dataSet[State.perorForm[i]].inj = 0;
		}	
		tabLen = State.otherForm.length;
		for(i=0 ; i<tabLen ; i++) {
			dataSet[State.otherForm[i]].oth = 1;
		}	
		drugData.length = 0;
		if(State.flRegPrice) { 
			regAdd = State.currRegAdd;
			for(cnt=0 ; cnt<len ; cnt++) {
				currDrug = {};
				this.objCopy(dataSet[cnt], currDrug); 
				price = currDrug.price;
				if(currDrug.changed==0) {
					if(currDrug.list==1) price += price/100.0*regAdd; 
					price = Math.floor(price*1.1*100); // +10% VAT
					currDrug.price = price/100
				}
				drugData.push(currDrug);
			}
		} else { // Don't multiply by regional koefficient
			for(cnt=0 ; cnt<len ; cnt++) {
				currDrug = {};
				this.objCopy(dataSet[cnt], currDrug); 
				price = currDrug.price;
				if(currDrug.changed==0) {
					price = Math.floor(price*1.1*100); // +10% VAT
					currDrug.price = price/100
				}
				drugData.push(currDrug);
			}

		}
	}

/////////////////////////////////

	saveData(num) {

		let newData, diff, price, currDrug, dataStr;
		const regAdd = State.regAdd,
				lsName = 'mnp_store';

		newData = Math.floor(drugData[num].price*100);
		price = dataSet[num].price;
		if(State.flRegPrice) {
			price = dataSet[num].price;
			if(dataSet[num].changed==0) {
				if(dataSet[num].list==1) price += price/100.0*regAdd; 
				price = Math.floor(price*1.1*100); // +10% VAT
			} else price = Math.floor(price*100);
		} else {
			if(dataSet[num].changed==0) {
				price = Math.floor(price*1.1*100); // +10% VAT
			} else price = Math.floor(price*100);
		}

		diff = newData - price ;
//!!TODO Установить разумный порог детектирования изменений
		if(diff!=0) {
			dataSet[num].price = drugData[num].price;
			drugData[num].changed = 1;
			dataSet[num].changed = 1;
			dataStr = JSON.stringify(dataSet);
			localStorage.setItem(lsName,dataStr);
		}

	}


}

export default DrugStore;