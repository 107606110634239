; "use strict";

import State from '../state';
import drugData from '../drugdata';
import vpData from './vp-meth';
import methods from '../methods';

const sections = [];

class VpModel {

	constructor() {

		let group;

		for(group in vpData) State.vpPatsBuf[group] = -1;
	}

/////////////////////////////

	getSections() {

		return sections;
	}


/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal, nOfDays,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, currMeth,
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks,log;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = vpData[st].meth;
		const currPath = State.currPath;
//if(st!='st0_2_6') return 0;
//console.log('------------>');
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			currMeth = methSet[cnt].meth;
			drugSet = methods[currMeth].drugs;
			doseSet = methods[currMeth].dose;
			nOfDrugs = drugSet.length;
			nOfDays = methSet[cnt].days;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==2) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfDays[currDrugN]*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfDays[currDrugN]*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
					State.nOfRloPats[currPath] += nOfPatients;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
					State.nOfOnlsPats[currPath] += nOfPatients;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methods[currMeth].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methods[currMeth].st && methods[currMeth].st!='') {
					State.ksgBuf[methods[currMeth].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methods[currMeth].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methods[currMeth].ds && methods[currMeth].ds!='') {
					State.ksgBuf[methods[currMeth].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// 			!! Для дополнительных пациентов вероятность приходить в отрицательном значении,
//				чтобы расположить их первыми в алгоритме ранжирования
// patByf - ссылка на массив распределенных пациентов
// extra - дополнительные фиктивные пациенты, необходимые для расчета медикаментов,
//		добавляемых сверх реального распределения, например - амброксол. Отдельной 
//		группы пациентов с амброксолом нет, но 20% пациентов его получает, независимо
//		от того, в какую группу они попадают. 

// Версия с обратным проходом от маленьких вероятностей к большим
// из модуля РП

	disributePats(input, probBuf, patBuf, extra) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];
		let extraRest = extra;					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в возрастающем
// порядке
// Отдаем приоритет позициям с меньшей вероятностью!!
		rest = input; // Нераспределенный остаток
//		if(extra) rest += extra;

		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			if(data<0) { 
				data = 0 - data;
				pats = Math.round(data);
				if(pats>extraRest) pats = extraRest;
				patBuf[probList[i]] = pats;
				extraRest -= pats;
				if(extraRest<0) extraRest = 0;
			} else {
				pats = Math.round(data);
				if(pats>rest) pats = rest;
				patBuf[probList[i]] = pats;
				rest -= pats;
				if(rest<0) rest = 0;
			}	
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
		if(extraRest>0) patBuf[probList[0]] += extraRest;
	}
 

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group) {

		let pathData, extraPats;

		if(State.flNoDist) return; // Отладочный режим

		pathData = vpData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		extraPats = 0;
		for(i=0 ; i<len ; i++) {
			if(methSet[i].rate<0) extraPats += Math.abs(methSet[i].rate);
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
		extraPats = Math.floor(total*extraPats/100.0);
State.extraPats['vp'][group] = extraPats;		
//		State.calc.disributePats(total, probBuf, patBuf);
this.disributePats(total, probBuf, patBuf, extraPats);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}

/////////////////////////////
// Суммируем для текущией патологии всех пациентов для данной группы
// Необходима в случае, если сумма процентов для всех методов в группе больше 100%

	summGroupPats(group) {

		let totalPatsBuf = State.stPats[0];
		let total = totalPatsBuf[group];
		let methSet = vpData[group].meth;
		let summ = 0, len, i, pats;

		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			pats = Math.abs(total*methSet[i].rate/100.0);
			summ += pats;
		}
		return summ;
	}

/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {
console.log('distributeAllMeth()');
// // Для каждой группы пробегаемся по списку методов и считаем сумму пациентов
// 		let pathData = vpData;
// 		let currGroup, currSumm,i,customVal;
// 		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
// 							// терапию в течение года 
// 		for(currGroup in pathData) {
//  			currSumm = this.summGroupPats(currGroup);
//  			currSumm = Math.ceil(currSumm);
// // Заносим сумму пациентов в данные группы
//  			if(!State.flNoDist)	pathData[currGroup].pats = currSumm;
// // Для каждой группы перераспределяем пациентов по методам
// 			this.distributeMeth(currGroup);
// 		}
// 		for(currGroup in pathData) {
// 			if(pathData[currGroup].result==-1) pathSumm += pathData[currGroup].pats;
// 			else pathSumm += pathData[currGroup].result;
// 		}
// 		// Сохраняем полное фактическое кол-во пациентов в хранилище
// 		State.pathSumm[0] = pathSumm;

// <================

		let percentDelta; // Коэффициент, показывающий насколько сумма процентов в текущей 
								// группе отличается от 100%
		let i, currGroup, pats;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in vpData) {
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры

			if(State.vpPatsBuf[currGroup]!=-1) {
				vpData[currGroup].pats = State.vpPatsBuf[currGroup]; 
			}

// Для каждой группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам больше 100%
// Суммируем пациентов внутри каждой группы

			let currGrData = vpData[currGroup].meth;
			let vpGrSumm = 0, percentSumm = 0, len = currGrData.length, num;
			
			for(i=0 ; i<len ; i++) percentSumm += Math.abs(currGrData[i].rate);
			percentDelta = percentSumm/100.0;

			if(vpData[currGroup].result!=-1 && State.vpMultBuf[currGroup]) {
				pats = Math.round(vpData[currGroup].result/State.vpMultBuf[currGroup]);
			} else if(vpData[currGroup].result!=-1 && isNaN(State.vpMultBuf[currGroup])) {
				pats = Math.round(vpData[currGroup].result);
			}
			else {
				pats = vpData[currGroup].pats;
			} 
// Если pats к этому моменту откорректировать на количество лишних пациентов, получившихся 
// за счет лишних процентов, то дальнейшее распределения должно быть корректным !!
//			pats = Math.round(pats*percentDelta); 
			for(i=0 ; i<len ; i++) {
				num = Math.abs(currGrData[i].rate*pats/100.0);
				if(!State.flNoDist)	currGrData[i].pats = num; 
				if(currGrData[i].rate>0) vpGrSumm += num;
			}
//!!D			vpGrSumm = Math.ceil(vpGrSumm);
// Перезаписываем новое значение в группу
//!!D			// vpData[currGroup].pats = vpGrSumm;
//!!D			// pathSumm += vpGrSumm;
vpData[currGroup].pats = pats;
pathSumm += pats;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup);
// Сохраняем множитель распределения в буфере
			State.vpMultBuf[currGroup] = vpGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.vpPatsBuf[currGroup] = pats;			
		}

		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[0] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = vpData, currGroup,i, indStr;
		let stPats = [], total = State.pathTotals[0];
		let totalPatsBuf = State.stPats[0];
		const grProbs = [10, 10, 25, 30, 10, 5, 10];
		let grPats = []; 
		let bactSumm = 0, virPats, maxQuant = 0, maxInd;
		for(i=0 ; i<4 ; i++) {
			grPats[i] = total*grProbs[i]/100.0;
			if(grPats[i]>maxQuant) {
				maxQuant = grPats[i];
				maxInd = i+1;
			}
		}
		for(i=4 ; i<7 ; i++) {
			grPats[i] = total*grProbs[i]*0.9/100.0;
			bactSumm += total*grProbs[i];
			if(grPats[i]>maxQuant) {
				maxQuant = grPats[i];
				maxInd = i+1;
			}
		}
		virPats = bactSumm*0.1/100.0;
		if(virPats>maxQuant) {
			maxQuant = virPats;
			maxInd = 8;
		}

let patSumm = 0, delta;				
// Амбулаторное лечение: Нетяжелая ВП у пациентов без сопутствующих заболеваний,
//		не принимавших за последние 3 мес АМП ≥2 дней и не имеющих других факторов риска
		Data['st0_0_1'].pats = Math.round(grPats[0]);
		totalPatsBuf['st0_0_1'] = Math.round(grPats[0]);
patSumm += Data['st0_0_1'].pats;
// Амбулаторное лечение: Нетяжелая ВП у пациентов с сопутствующими заболеваниями  
//	и/или принимавшими за последние 3 мес АМП ≥2 дней и/или имеющих другие факторы риска
		Data['st0_0_2'].pats = Math.round(grPats[1]);
		totalPatsBuf['st0_0_2'] = Math.round(grPats[1]);
patSumm += Data['st0_0_2'].pats;

// Стационарное лечение: Нетяжелая ВП у пациентов без сопутствующих заболеваний,  
//	не принимавших за последние 3 мес АМП ≥2 дней и не имеющих других факторов риска
		Data['st0_0_3'].pats = Math.round(grPats[2]);
		totalPatsBuf['st0_0_3'] = Math.round(grPats[2]);
patSumm += Data['st0_0_3'].pats;

// Стационарное лечение: Нетяжелая ВП у пациентов с сопутствующими заболеваниями  
//	и/или принимавшими за последние 3 мес АМП ≥2 дней и/или имеющих другие факторы риска
		Data['st0_0_4'].pats = Math.round(grPats[3]);
		totalPatsBuf['st0_0_4'] = Math.round(grPats[3]);
patSumm += Data['st0_0_4'].pats;

// Стационарное лечение: Тяжёлая ВП без факторов риска инфицирования P. aeruginosa
//	и предполагаемой/документированной аспирации
		Data['st0_0_5'].pats = Math.round(grPats[4]);
		totalPatsBuf['st0_0_5'] = Math.round(grPats[4]);
patSumm += Data['st0_0_5'].pats;

// Стационарное лечение: Тяжёлая ВП с факторами риска инфицирования P. aeruginosa
		Data['st0_0_6'].pats = Math.round(grPats[5]);
		totalPatsBuf['st0_0_6'] = Math.round(grPats[5]);
patSumm += Data['st0_0_6'].pats;

// Стационарное лечение: Тяжёлая ВП с документированной/предполагаемой аспирацией
		Data['st0_0_7'].pats = Math.round(grPats[6]);
		totalPatsBuf['st0_0_7'] = Math.round(grPats[6]);
patSumm += Data['st0_0_7'].pats;

// Стационарное лечение: Гриппозная пневмония
		Data['st0_0_8'].pats = Math.round(virPats);
		totalPatsBuf['st0_0_8'] = Math.round(virPats);

// Убираем ошибку округлений за счет значения в группе с наибольшим кол-вом пациентов
		patSumm += Data['st0_0_8'].pats;
		delta = patSumm - total;
		if(delta!=0) {
			indStr = 'st0_0_' + maxInd;
			Data[indStr].pats -= delta;
			totalPatsBuf[indStr] -= delta;
		}
		for(currGroup in Data) {
			State.vpPatsBuf[currGroup] = -1;
			Data[currGroup].result = -1;
		}
		this.distributeAllMeth();
	}

}

export default VpModel;