var regStat = {
	1: {'reg':'Белгородская область','mel':103,'krr':612,'rmz':703,'rl':574,	'rya':150,	'rshm':147,	'rp':261,'rz':369,'rmp':202,'rpsh':84,'rpz':139,'rtm':326,'gcr':32},
	2: {'reg':'Брянская область','mel':95,'krr':526,'rmz':577,'rl':577,			'rya':132,	'rshm':130,	'rp':188,'rz':388,'rmp':157,'rpsh':80,'rpz':160,'rtm':256,'gcr':66},
	3: {'reg':'Владимирская область','mel':118,'krr':585,'rmz':606,'rl':474,	'rya':145,	'rshm':169,	'rp':203,'rz':334,'rmp':116,'rpsh':91,'rpz':147,'rtm':321,'gcr':37},
	4: {'reg':'Воронежская область','mel':214,'krr':910,'rmz':1065,'rl':981,	'rya':200,	'rshm':227,	'rp':317,'rz':539,'rmp':287,'rpsh':73,'rpz':246,'rtm':557,'gcr':50},
	5: {'reg':'Ивановская область','mel':63,'krr':440,'rmz':557,'rl':361,		'rya':130,	'rshm':203,	'rp':172,'rz':317,'rmp':145,'rpsh':62,'rpz':106,'rtm':292,'gcr':32},
	6: {'reg':'Тверская область','mel':112,'krr':602,'rmz':621,'rl':589,		'rya':118,	'rshm':171,	'rp':200,'rz':399,'rmp':129,'rpsh':87,'rpz':180,'rtm':266,'gcr':51},
	7: {'reg':'Калужская область','mel':77,'krr':446,'rmz':491,'rl':364,		'rya':117,	'rshm':106,	'rp':132,'rz':294,'rmp':108,'rpsh':52,'rpz':115,'rtm':209,'gcr':55},
	8: {'reg':'Костромская область','mel':45,'krr':306,'rmz':314,'rl':260,		'rya':73,	'rshm':68,	'rp':114,'rz':188,'rmp':68,'rpsh':40,'rpz':62,'rtm':129,'gcr':14},
	9: {'reg':'Курская область','mel':71,'krr':476,'rmz':560,'rl':517,			'rya':135,	'rshm':136,	'rp':230,'rz':322,'rmp':147,'rpsh':74,'rpz':133,'rtm':251,'gcr':27},
	10: {'reg':'Липецкая область','mel':88,'krr':459,'rmz':487,'rl':461,		'rya':91,	'rshm':139,	'rp':151,'rz':250,'rmp':146,'rpsh':42,'rpz':117,'rtm':266,'gcr':29},
	11: {'reg':'г. Москва','mel':883,'krr':4908,'rmz':5902,'rl':2660,			'rya':923,	'rshm':832,	'rp':1356,'rz':2219,'rmp':1143,'rpsh':348,'rpz':1204,'rtm':1922,'gcr':349},
	12: {'reg':'Московская область','mel':577,'krr':3017,'rmz':3499,'rl':1964,	'rya':624,	'rshm':792,	'rp':1009,'rz':1573,'rmp':645,'rpsh':330,'rpz':722,'rtm':1349,'gcr':224},
	13: {'reg':'Орловская область','mel':99,'krr':390,'rmz':389,'rl':387,		'rya':55,	'rshm':98,	'rp':138,'rz':230,'rmp':105,'rpsh':49,'rpz':77,'rtm':185,'gcr':19},
	14: {'reg':'Рязанская область','mel':100,'krr':579,'rmz':541,'rl':461,		'rya':125,	'rshm':121,	'rp':168,'rz':329,'rmp':157,'rpsh':63,'rpz':125,'rtm':344,'gcr':29},
	15: {'reg':'Смоленская область','mel':68,'krr':406,'rmz':476,'rl':358,		'rya':104,	'rshm':131,	'rp':164,'rz':283,'rmp':82,'rpsh':53,'rpz':111,'rtm':253,'gcr':31},
	16: {'reg':'Тамбовская область','mel':72,'krr':434,'rmz':461,'rl':485,		'rya':76,	'rshm':108,	'rp':141,'rz':296,'rmp':128,'rpsh':53,'rpz':106,'rtm':240,'gcr':30},
	17: {'reg':'Тульская область','mel':122,'krr':727,'rmz':729,'rl':612,		'rya':157,	'rshm':202,	'rp':196,'rz':434,'rmp':188,'rpsh':83,'rpz':198,'rtm':426,'gcr':86},
	18: {'reg':'Ярославская область','mel':112,'krr':737,'rmz':680,'rl':472,	'rya':149,	'rshm':152,	'rp':209,'rz':374,'rmp':143,'rpsh':95,'rpz':188,'rtm':268,'gcr':71},
	19: {'reg':'Ненецкий а.о.','mel':1,'krr':13,'rmz':14,'rl':12,				'rya':1,	'rshm':7,	'rp':7,'rz':7,'rmp':1,'rpsh':5,'rpz':5,'rtm':2,'gcr':1},
	20: {'reg':'Архангельская обл.(б а.о)','mel':92,'krr':622,'rmz':431,'rl':498,'rya':146,	'rshm':141,	'rp':236,'rz':399,'rmp':120,'rpsh':120,'rpz':107,'rtm':176,'gcr':43},
	21: {'reg':'Вологодская область','mel':74,'krr':471,'rmz':486,'rl':430,		'rya':101,	'rshm':166,	'rp':150,'rz':280,'rmp':118,'rpsh':72,'rpz':97,'rtm':185,'gcr':86},
	22: {'reg':'Калининградская область','mel':71,'krr':376,'rmz':491,'rl':277,	'rya':100,	'rshm':126,	'rp':136,'rz':182,'rmp':108,'rpsh':43,'rpz':107,'rtm':192,'gcr':28},
	23: {'reg':'г. Санкт-Петербург','mel':516,'krr':2860,'rmz':2866,'rl':1750,	'rya':575,	'rshm':488,	'rp':784,'rz':1282,'rmp':611,'rpsh':247,'rpz':680,'rtm':906,'gcr':278},
	24: {'reg':'Ленинградская область','mel':107,'krr':632,'rmz':661,'rl':530,	'rya':140,	'rshm':165,	'rp':222,'rz':374,'rmp':174,'rpsh':99,'rpz':157,'rtm':274,'gcr':42},
	25: {'reg':'Мурманская область','mel':57,'krr':337,'rmz':379,'rl':250,		'rya':63,	'rshm':88,	'rp':144,'rz':172,'rmp':76,'rpsh':40,'rpz':77,'rtm':107,'gcr':23},
	26: {'reg':'Новгородская область','mel':41,'krr':294,'rmz':302,'rl':296,	'rya':79,	'rshm':75,	'rp':113,'rz':189,'rmp':112,'rpsh':51,'rpz':87,'rtm':135,'gcr':12},
	27: {'reg':'Псковская область','mel':41,'krr':305,'rmz':315,'rl':264,		'rya':73,	'rshm':87,	'rp':96,'rz':186,'rmp':70,'rpsh':38,'rpz':68,'rtm':152,'gcr':24},
	28: {'reg':'Республика Карелия','mel':56,'krr':287,'rmz':335,'rl':230,		'rya':58,	'rshm':85,	'rp':95,'rz':200,'rmp':51,'rpsh':44,'rpz':46,'rtm':123,'gcr':22},
	29: {'reg':'Республика Коми','mel':72,'krr':362,'rmz':385,'rl':371,			'rya':73,	'rshm':140,	'rp':150,'rz':210,'rmp':69,'rpsh':83,'rpz':88,'rtm':109,'gcr':39},
	30: {'reg':'Краснодарский край','mel':511,'krr':2661,'rmz':2531,'rl':2245,	'rya':503,	'rshm':692,	'rp':699,'rz':1122,'rmp':647,'rpsh':169,'rpz':642,'rtm':941,'gcr':298},
	31: {'reg':'Астраханская область','mel':65,'krr':368,'rmz':407,'rl':290,	'rya':87,	'rshm':142,	'rp':109,'rz':222,'rmp':57,'rpsh':60,'rpz':106,'rtm':150,'gcr':67},
	32: {'reg':'Волгоградская область','mel':185,'krr':1148,'rmz':1094,'rl':1047,'rya':225,	'rshm':316,	'rp':379,'rz':531,'rmp':254,'rpsh':115,'rpz':287,'rtm':408,'gcr':134},
	33: {'reg':'Ростовская область','mel':293,'krr':1719,'rmz':1929,'rl':1436,	'rya':376,	'rshm':628,	'rp':576,'rz':640,'rmp':378,'rpsh':132,'rpz':421,'rtm':721,'gcr':135},
	34: {'reg':'Республика Адыгея','mel':26,'krr':140,'rmz':157,'rl':135,		'rya':27,	'rshm':46,	'rp':45,'rz':75,'rmp':32,'rpsh':14,'rpz':44,'rtm':97,'gcr':26},
	35: {'reg':'Республика Калмыкия','mel':10,'krr':85,'rmz':76,'rl':136,		'rya':19,	'rshm':31,	'rp':26,'rz':57,'rmp':11,'rpsh':18,'rpz':23,'rtm':27,'gcr':14},
	36: {'reg':'Республика Крым','mel':162,'krr':832,'rmz':963,'rl':611,		'rya':188,	'rshm':254,	'rp':271,'rz':384,'rmp':221,'rpsh':66,'rpz':208,'rtm':332,'gcr':104},
	37: {'reg':'Город Cевастополь','mel':34,'krr':247,'rmz':233,'rl':117,		'rya':44,	'rshm':63,	'rp':61,'rz':73,'rmp':40,'rpsh':9,'rpz':64,'rtm':92,'gcr':19},
	38: {'reg':'Ставропольский край','mel':172,'krr':1180,'rmz':1072,'rl':921,	'rya':265,	'rshm':307,	'rp':257,'rz':442,'rmp':245,'rpsh':79,'rpz':201,'rtm':410,'gcr':90},
	39: {'reg':'Республика Ингушетия','mel':6,'krr':80,'rmz':125,'rl':74,		'rya':25,	'rshm':17,	'rp':34,'rz':37,'rmp':26,'rpsh':10,'rpz':6,'rtm':23,'gcr':7},
	40: {'reg':'Республика Дагестан','mel':56,'krr':452,'rmz':595,'rl':523,		'rya':125,	'rshm':162,	'rp':143,'rz':349,'rmp':134,'rpsh':90,'rpz':109,'rtm':126,'gcr':80},
	41: {'reg':'Респ. Кабардино-Балкария','mel':41,'krr':215,'rmz':296,'rl':270,'rya':53,	'rshm':69,	'rp':64,'rz':97,'rmp':70,'rpsh':20,'rpz':65,'rtm':101,'gcr':60},
	42: {'reg':'Респ. Северная Осетия','mel':36,'krr':206,'rmz':328,'rl':141,	'rya':67,	'rshm':46,	'rp':54,'rz':120,'rmp':83,'rpsh':9,'rpz':48,'rtm':116,'gcr':45},
	43: {'reg':'Респ. Карачаево-Черкесия','mel':17,'krr':119,'rmz':142,'rl':124,'rya':38,	'rshm':35,	'rp':45,'rz':69,'rmp':40,'rpsh':8,'rpz':34,'rtm':59,'gcr':14},
	44: {'reg':'Республика Чечня','mel':15,'krr':236,'rmz':269,'rl':317,		'rya':61,	'rshm':82,	'rp':62,'rz':128,'rmp':46,'rpsh':51,'rpz':63,'rtm':102,'gcr':42},
	45: {'reg':'Нижегородская область','mel':237,'krr':1814,'rmz':1883,'rl':1370,'rya':324,	'rshm':417,	'rp':589,'rz':1060,'rmp':451,'rpsh':191,'rpz':346,'rtm':776,'gcr':87},
	46: {'reg':'Кировская область','mel':130,'krr':750,'rmz':536,'rl':681,		'rya':110,	'rshm':121,	'rp':253,'rz':372,'rmp':161,'rpsh':57,'rpz':182,'rtm':231,'gcr':88},
	47: {'reg':'Самарская область','mel':259,'krr':1531,'rmz':1633,'rl':1169,	'rya':306,	'rshm':329,	'rp':494,'rz':686,'rmp':377,'rpsh':142,'rpz':368,'rtm':654,'gcr':110},
	48: {'reg':'Оренбургская область','mel':139,'krr':1003,'rmz':1016,'rl':934,	'rya':184,	'rshm':251,	'rp':373,'rz':480,'rmp':255,'rpsh':124,'rpz':221,'rtm':377,'gcr':115},
	49: {'reg':'Пензенская область','mel':114,'krr':630,'rmz':572,'rl':633,		'rya':157,	'rshm':138,	'rp':260,'rz':377,'rmp':164,'rpsh':68,'rpz':181,'rtm':336,'gcr':56},
	50: {'reg':'Пермский край','mel':195,'krr':1050,'rmz':1012,'rl':837,		'rya':266,	'rshm':283,	'rp':477,'rz':514,'rmp':196,'rpsh':126,'rpz':214,'rtm':408,'gcr':73},
	51: {'reg':'Саратовская область','mel':207,'krr':1212,'rmz':1248,'rl':992,	'rya':227,	'rshm':255,	'rp':386,'rz':568,'rmp':330,'rpsh':143,'rpz':258,'rtm':516,'gcr':90},
	52: {'reg':'Ульяновская область','mel':84,'krr':603,'rmz':549,'rl':514,		'rya':107,	'rshm':187,	'rp':151,'rz':329,'rmp':120,'rpsh':91,'rpz':94,'rtm':230,'gcr':31},
	53: {'reg':'Республика Башкортостан','mel':141,'krr':1492,'rmz':1523,'rl':1358,'rya':321,'rshm':356,'rp':562,'rz':830,'rmp':302,'rpsh':278,'rpz':349,'rtm':445,'gcr':129},
	54: {'reg':'Республика Марий Эл','mel':59,'krr':237,'rmz':230,'rl':153,		'rya':69,	'rshm':81,	'rp':77,'rz':153,'rmp':45,'rpsh':50,'rpz':37,'rtm':93,'gcr':21},
	55: {'reg':'Республика Мордовия','mel':65,'krr':344,'rmz':336,'rl':352,		'rya':62,	'rshm':82,	'rp':186,'rz':210,'rmp':97,'rpsh':53,'rpz':89,'rtm':166,'gcr':47},
	56: {'reg':'Республика Татарстан','mel':206,'krr':1745,'rmz':1625,'rl':1381,'rya':298,	'rshm':465,	'rp':571,'rz':851,'rmp':321,'rpsh':278,'rpz':440,'rtm':498,'gcr':139},
	57: {'reg':'Республика Удмуртия','mel':100,'krr':670,'rmz':537,'rl':552,	'rya':121,	'rshm':171,	'rp':244,'rz':313,'rmp':116,'rpsh':97,'rpz':104,'rtm':146,'gcr':53},
	58: {'reg':'Республика Чувашия','mel':64,'krr':487,'rmz':408,'rl':408,		'rya':85,	'rshm':82,	'rp':161,'rz':230,'rmp':78,'rpsh':54,'rpz':88,'rtm':146,'gcr':54},
	59: {'reg':'Ханты-Мансийский а.о.','mel':58,'krr':548,'rmz':557,'rl':460,	'rya':84,	'rshm':167,	'rp':296,'rz':258,'rmp':113,'rpsh':49,'rpz':132,'rtm':157,'gcr':151},
	60: {'reg':'Ямало-Ненецкий а.о.','mel':23,'krr':135,'rmz':174,'rl':96,		'rya':23,	'rshm':48,	'rp':69,'rz':55,'rmp':27,'rpsh':26,'rpz':27,'rtm':48,'gcr':11},
	61: {'reg':'Курганская область','mel':47,'krr':365,'rmz':424,'rl':471,		'rya':121,	'rshm':139,	'rp':212,'rz':239,'rmp':111,'rpsh':47,'rpz':104,'rtm':130,'gcr':54},
	62: {'reg':'Свердловская область','mel':322,'krr':1899,'rmz':1780,'rl':1594,'rya':325,	'rshm':480,	'rp':703,'rz':906,'rmp':415,'rpsh':206,'rpz':464,'rtm':616,'gcr':191},
	63: {'reg':'Тюменская обл.(б а.о)','mel':95,'krr':604,'rmz':664,'rl':636,	'rya':135,	'rshm':170,	'rp':229,'rz':299,'rmp':133,'rpsh':74,'rpz':161,'rtm':207,'gcr':94},
	64: {'reg':'Челябинская область','mel':222,'krr':1625,'rmz':1556,'rl':1220,'rya':365,	'rshm':471,	'rp':538,'rz':807,'rmp':352,'rpsh':171,'rpz':290,'rtm':653,'gcr':115},
	65: {'reg':'Алтайский край','mel':186,'krr':1097,'rmz':1045,'rl':1241,		'rya':241,	'rshm':282,	'rp':529,'rz':531,'rmp':317,'rpsh':116,'rpz':289,'rtm':404,'gcr':106},
	66: {'reg':'Красноярский край','mel':155,'krr':1187,'rmz':1351,'rl':1091,	'rya':246,	'rshm':424,	'rp':499,'rz':687,'rmp':338,'rpsh':123,'rpz':280,'rtm':474,'gcr':129},
	67: {'reg':'Иркутская область','mel':163,'krr':1061,'rmz':1108,'rl':1109,	'rya':259,	'rshm':389,	'rp':490,'rz':688,'rmp':273,'rpsh':147,'rpz':305,'rtm':346,'gcr':117},
	68: {'reg':'Кемеровская область','mel':179,'krr':1112,'rmz':1198,'rl':1144,	'rya':206,	'rshm':333,	'rp':478,'rz':556,'rmp':319,'rpsh':108,'rpz':268,'rtm':461,'gcr':118},
	69: {'reg':'Новосибирская область','mel':145,'krr':1321,'rmz':1356,'rl':1276,'rya':280,	'rshm':291,	'rp':488,'rz':602,'rmp':349,'rpsh':117,'rpz':393,'rtm':464,'gcr':146},
	70: {'reg':'Омская область','mel':132,'krr':906,'rmz':953,'rl':952,			'rya':188,	'rshm':221,	'rp':430,'rz':448,'rmp':275,'rpsh':84,'rpz':234,'rtm':327,'gcr':50},
	71: {'reg':'Томская область','mel':59,'krr':520,'rmz':502,'rl':411,			'rya':81,	'rshm':145,	'rp':162,'rz':245,'rmp':120,'rpsh':52,'rpz':128,'rtm':153,'gcr':98},

	74: {'reg':'Республика Алтай','mel':9,'krr':70,'rmz':59,'rl':75,			'rya':14,	'rshm':23,	'rp':23,'rz':53,'rmp':12,'rpsh':9,'rpz':21,'rtm':16,'gcr':18},
	75: {'reg':'Республика Тыва','mel':4,'krr':50,'rmz':96,'rl':96,				'rya':36,	'rshm':86,	'rp':29,'rz':78,'rmp':21,'rpsh':19,'rpz':25,'rtm':12,'gcr':43},
	76: {'reg':'Республика Хакасия','mel':29,'krr':194,'rmz':218,'rl':238,		'rya':46,	'rshm':80,	'rp':85,'rz':102,'rmp':72,'rpsh':32,'rpz':54,'rtm':74,'gcr':16},
	77: {'reg':'Приморский край','mel':142,'krr':705,'rmz':765,'rl':815,		'rya':170,	'rshm':236,	'rp':259,'rz':384,'rmp':194,'rpsh':119,'rpz':229,'rtm':263,'gcr':73},
	78: {'reg':'Хабаровский край','mel':95,'krr':541,'rmz':551,'rl':655,		'rya':106,	'rshm':149,	'rp':235,'rz':285,'rmp':156,'rpsh':82,'rpz':152,'rtm':191,'gcr':58},
	79: {'reg':'Амурская область','mel':53,'krr':298,'rmz':336,'rl':337,		'rya':52,	'rshm':113,	'rp':143,'rz':171,'rmp':80,'rpsh':43,'rpz':89,'rtm':122,'gcr':34},
	80: {'reg':'Камчатский край','mel':25,'krr':136,'rmz':121,'rl':131,			'rya':20,	'rshm':47,	'rp':63,'rz':76,'rmp':44,'rpsh':27,'rpz':45,'rtm':41,'gcr':15},
	81: {'reg':'Магаданская область','mel':8,'krr':56,'rmz':76,'rl':63,			'rya':10,	'rshm':46,	'rp':29,'rz':34,'rmp':11,'rpsh':11,'rpz':15,'rtm':21,'gcr':2},
	82: {'reg':'Сахалинская область','mel':31,'krr':197,'rmz':222,'rl':263,		'rya':48,	'rshm':78,	'rp':117,'rz':143,'rmp':85,'rpsh':50,'rpz':83,'rtm':69,'gcr':50},
	72: {'reg':'Забайкальский край','mel':32,'krr':310,'rmz':390,'rl':436,		'rya':102,	'rshm':273,	'rp':166,'rz':217,'rmp':83,'rpsh':61,'rpz':93,'rtm':108,'gcr':30},
	83: {'reg':'Чукотский авт.округ','mel':1,'krr':16,'rmz':19,'rl':32,			'rya':4,	'rshm':5,	'rp':15,'rz':14,'rmp':6,'rpsh':8,'rpz':5,'rtm':2,'gcr':5},
	73: {'reg':'Республика Бурятия','mel':34,'krr':322,'rmz':333,'rl':407,		'rya':89,	'rshm':260,	'rp':134,'rz':247,'rmp':51,'rpsh':95,'rpz':102,'rtm':89,'gcr':80},
	84: {'reg':'Республика Саха (Якутия)','mel':13,'krr':211,'rmz':242,'rl':323,'rya':53,	'rshm':125,	'rp':124,'rz':173,'rmp':54,'rpsh':64,'rpz':80,'rtm':59,'gcr':145},
	85: {'reg':'Еврейская авт. обл.','mel':5,'krr':60,'rmz':54,'rl':75,			'rya':19,	'rshm':11,	'rp':25,'rz':36,'rmp':25,'rpsh':10,'rpz':13,'rtm':19,'gcr':5}
}

export default regStat;