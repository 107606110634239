; "use strict";

import State from './state';
import drugData from './drugdata';
import vpData from './models/vp-meth';
import baData from './models/ba-meth';
import hoblData from './models/hobl-meth';

class Calc {

	constructor() {

	}

/////////////////////////////

	calcOneGroup(path, st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound,
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

		if(chan) {
			if(path=='vp') return State.vpModel.calcOneGroup(st, chan);	
			if(path=='ba') return State.baModel.calcOneGroup(st, chan);	
			if(path=='hobl') return State.hoblModel.calcOneGroup(st, chan);	
		} else {
			if(path=='vp') return State.vpModel.calcOneGroup(st);	
			if(path=='ba') return State.baModel.calcOneGroup(st);	
			if(path=='hobl') return State.hoblModel.calcOneGroup(st);	
		}

console.log('!!! Error calling calcOneGroup()');
	}


/////////////////////////////

	getCustomKSGSet(custCode, stType) {

		const ksgSet = {
			'ac4_d4': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.022',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.030',
						cyc: 4
					}
				]
			},
			'ac4_p12': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.019',
						cyc: 12
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.027',
						cyc: 12
					}
				]
			},
			'ac4_p4': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.021',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.028',
						cyc: 4
					}
				]
			},
			'ac4_dtr4': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.024',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.034',
						cyc: 4
					}
				]
			}, 
			'ac4_ptr12': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.021',
						cyc: 12
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.029',
						cyc: 12
					}
				]
			}, 
			'ac4_ptr4': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.023',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.032',
						cyc: 4
					}
				]
			}, 
			'ac4_dtrp4': {
				ds: [
					{
						code: 'ds19.019',
						cyc: 4
					},
					{
						code: 'ds19.027',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.027',
						cyc: 4
					},
					{
						code: 'st19.036',
						cyc: 4
					}
				]
			}
		};

		let data = ksgSet[custCode][stType];
		return data;
	}



/////////////////////////////

	resetKsgBufDs() {

		State.ksgBuf['ds23.001'] = 0;
	}

/////////////////////////////

	resetKsgBufKs() {

		State.ksgBuf['st12.013'] = 0;
		State.ksgBuf['st23.004'] = 0;
		State.ksgBuf['st23.005'] = 0;
	}

/////////////////////////////

	resetDataBufs() {

		const len = drugData.length;

		State.nOfCasesKs = 0;
		State.nOfCasesDs = 0;
		State.drugBuf = [];
		State.omsBuf = [];
		State.omsdBuf = [];
		State.rloBuf = [];
		State.onlsBuf = [];
		State.chanBudgets['oms'] = 0;
		State.chanBudgets['omsd'] = 0;
		State.chanBudgets['rlo'] = 0;
		State.chanBudgets['onls'] = 0;

		for(let cnt=0 ; cnt<len ; cnt++) {
			State.drugBuf[cnt] = {};
			State.drugBuf[cnt].summ = 0;
			State.drugBuf[cnt].packs = 0;
			State.drugBuf[cnt].mg = 0;

			State.omsBuf[cnt] = {};
			State.omsBuf[cnt].summ = 0;
			State.omsBuf[cnt].packs = 0;
			State.omsBuf[cnt].mg = 0;

			State.omsdBuf[cnt] = {};
			State.omsdBuf[cnt].summ = 0;
			State.omsdBuf[cnt].packs = 0;
			State.omsdBuf[cnt].mg = 0;

			State.rloBuf[cnt] = {};
			State.rloBuf[cnt].summ = 0;
			State.rloBuf[cnt].packs = 0;
			State.rloBuf[cnt].mg = 0;

			State.onlsBuf[cnt] = {};
			State.onlsBuf[cnt].summ = 0;
			State.onlsBuf[cnt].packs = 0;
			State.onlsBuf[cnt].mg = 0;
		}
	}


/////////////////////////////


	calcOnePath(path, chan) {

		let pathBudget = 0,
			pathData, currGroup, cnt, lim, pathNum=-1;

        for(cnt=0, lim=State.pathKeys.length ; cnt<lim ; cnt++) {
        	if(path==State.pathKeys[cnt]) {
        		pathNum = cnt;
        		break;
        	}
        }
        if(State.selPath[pathNum] != 1) return 0

		if(path=='vp') pathData = vpData;
		else if(path=='ba') pathData = baData;
		else if(path=='hobl') pathData = hoblData;
		else console.log("!!!Error calling calcOnePath()");

		if(chan=='rlo') State.nOfRloPats[path] = 0;
		if(chan=='onls') State.nOfOnlsPats[path] = 0;
		for(currGroup in pathData) {
 			if(chan) pathBudget += this.calcOneGroup(path, currGroup, chan);
			else pathBudget += this.calcOneGroup(path, currGroup);
		}	
//console.log('Path:'+path+'>'+pathBudget);
		if(chan) ;
		else {
			State.pathBudgets[path] = this.padDigits(Math.floor(pathBudget));			
//console.log(path+'->'+pathBudget);
		}
// if(chan=='oms')
// 	console.log(path+'->'+State.stIncome);
		return pathBudget;
	}

/////////////////////////////


	calcFullBudget(chan) {

		let fullBadget = 0,
			currPath;
//console.log(State.drugBuf);
// 
		if(chan=='oms') {
			State.stIncome = 0; // Reset income accumulator
			State.nOfCasesKs = 0;
			this.resetKsgBufKs();
		} 
		if(chan=='omsd') {
			State.dsIncome = 0; // Reset income accumulator
			State.nOfCasesDs = 0;
			this.resetKsgBufDs();
		} 
//		for(let i=0, len=State.pathKeys.length ; i<len ; i++) {
		for(let i=0, len=State.selPathKeys.length ; i<len ; i++) {
			currPath = State.selPathKeys[i];
			if(chan) fullBadget += this.calcOnePath(currPath, chan);
			else fullBadget += this.calcOnePath(currPath);
		}	
//console.log('FullBudget:'+fullBadget);
		return fullBadget;
	}

/////////////////////////////

	findMax(buf) {

		let len = buf.length, i, max=buf[0], maxInd=0;

		for(i=0 ; i<len ; i++) {
			if(buf[i]>max) {
				max = buf[i];
				maxInd = i;
			}
		}
		return maxInd;
	}

	findMin(buf) {

		let len = buf.length, i, min=buf[0], minInd=0;

		for(i=0 ; i<len ; i++) {
			if(buf[i]<min) {
				min = buf[i];
				minInd = i;
			}
		}
		return minInd;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// patByf - ссылка на массив распределенных пациентов

// Версия с обратным проходом от маленьких вероятностей к большим
// из модуля РП

	disributePats(input, probBuf, patBuf) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// порядке
// Отдаем приоритет позициям с большей вероятностью!!
		rest = input ; // Нераспределенный остаток

		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			pats = Math.round(data);

			if(pats>rest) pats = rest;
			patBuf[probList[i]] = pats;
			rest -= pats;
			if(rest<0) rest = 0;
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
	}
 


// 	disributePats(input, probBuf, patBuf) {

// 		let len = probBuf.length, i, maxInd, rest, pats, data;
// 		let probList = []; // Массив индексов элементов из probBuf, отсортированных
// 							// по убыванию
// 		let buf = [];					
// 		for(i=0 ; i<len ; i++) {
// 			buf.push(probBuf[i]);
// 		}
// 		for(i=0 ; i<len ; i++) {
// 			maxInd = this.findMax(buf);
// 			probList.push(maxInd);
// 			buf[maxInd] = -1;
// 		}

// // В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// // порядке
// // Отдаем приоритет позициям с большей вероятностью!!
// 		rest = input ; // Нераспределенный остаток
// //console.log('==============');
// //!! EXPERIMENTAL calculation of bias constant
// 		let bias = 0, delta, bias2 = 0;
// 		for(i=0 ; i<len ; i++) {
// 			data = input*probBuf[probList[i]]/100;
// 			pats = Math.round(data);
// 			delta = (pats - data);
// 			if(delta > 0) delta = 0.5 - delta;
// 			else delta = -0.5 + delta;
// 			bias += delta;
// 		}

// 		bias = bias/len;


// 		for(i=0 ; i<len ; i++) {
// //	V1		pats = Math.ceil(input*probBuf[probList[i]]/100);
// //	V2		pats = Math.round(input*probBuf[probList[i]]/100);
// //!! EXPERIMENTAL distribution (simple mode with bias constant of 0.1):
// //			data = input*probBuf[probList[i]]/100 - 0.1;
// 			data = input*probBuf[probList[i]]/100 - bias;
// 			pats = Math.round(data);

// 			if(pats>rest) pats = rest;
// 			patBuf[probList[i]] = pats;
// 			rest -= pats;
// 			if(rest<0) rest = 0;
// 		}
// 		if(rest>0) patBuf[probList[0]] += rest;
// 	}


/////////////////////////////
// Рендерим все индикаторы общего числа пациентов

	updateTotalPatientViews() {

		let i, len, patSumm = 0, totPatSumm = 0;

		len = State.pathTotals.length;
		for(i=0 ; i<len ; i++) {
			patSumm += State.pathTotals[i]*State.selPath[i];
//			totPatSumm += State.pathSumm[i]*State.selPath[i];
			totPatSumm = patSumm;
		}
		State.totPatSumm = totPatSumm;
		if(patSumm<0) {
			$('#scr0-pats-inp').val(0);	
			$('#scr0-pats-txt').text(0);
		} else {
			$('#scr0-pats-inp').val(patSumm);	
			$('#scr0-pats-txt').text(patSumm);
		}
		if(totPatSumm<0) {
			$('.total-patients').text('---');
		} else {
			$('.total-patients').text(this.padDigits(totPatSumm));
		}
	}


/////////////////////////////

	distributePath(path) {

		if(path=='vp') State.vpModel.distributeSt(); 
		if(path=='ba') State.baModel.distributeSt(); 
		if(path=='hobl') State.hoblModel.distributeSt(); 
	}


/////////////////////////////

	getDefaultSt() {

		return State.defaultSt[State.currPath];
	}

/////////////////////////////

	resetData() {

		let i, pats;

		pats = State.customPats; // Общее кол-во пациентов для патологии
		if(!State.flNoTotalsReset) for(i in pats) pats[i] = -1; 
		// for(i in State.rmzPatsBuf) State.rmzPatsBuf[i] = -1;	
		// Количества пациентов в группах: 
		for(i in State.vpPatsBuf) State.vpPatsBuf[i] = -1;	
	}


/////////////////////////////
// Проверяем, нет ли сохраненных данных по распределению пациентов в LS

	checkDataSaved() {

		const key = 'mnp_meth';

		let dataSet = localStorage.getItem(key);
		return (null != dataSet)
	}

/////////////////////////////

	restoreAllData() {

		State.clinData.loadClinData();
		State.methPanel.loadMethData();
	}

/////////////////////////////

	distributeAllPaths() {

		State.vpModel.distributeSt();
		State.baModel.distributeSt();
		State.hoblModel.distributeSt();
	}

/////////////////////////////

	initDataSet() {

		let patSumm = 0, i, len, flCustomData = 0;

		if(this.checkDataSaved()) {
			this.restoreAllData(); // Если пользователь уже заходил
			// в программу - все распределения восстановить из Local Storage
		}
		$('.curr-region').text(State.currRegname);
		len = State.pathKeys.length;
		if(State.customPats['vp']>-1) State.pathTotals[0] = State.customPats['vp'];
		else State.pathTotals[0] = State.currPats['vp'];
		if(State.customPats['ba']>-1) State.pathTotals[1] = State.customPats['ba'];
		else State.pathTotals[1] = State.currPats['ba'];
		if(State.customPats['hobl']>-1) State.pathTotals[2] = State.customPats['hobl'];
		else State.pathTotals[2] = State.currPats['hobl'];

		this.updateTotalPatientViews()

		State.pathPanel.render(); // Fetch all*2
		if(!this.checkDataSaved()) {
			this.distributeAllPaths();
			State.distPanel.resetAllChanels();
		} 
		State.stPanel.setCurrentSt('st0_0_1'); // Fetch current
		State.stPanel.render(State.currPath); // Fetch all
//!!TODO Зачем фетчится все??
// Это приходится делать для маркировки свободных пациентов на панели патологий
// Более корректно иметь метод апдейта только той строки патологий, для которой
// могли перераспределиться пациенты
		State.methPanel.render(this.getDefaultSt(State.currPath));
		State.methPanel.displayResult(); // Fetch all
	}


/////////////////////////////

    padDigits(str){

        var res='',s='',i,cnt,n;

        str = '' + str;
        n = str.length;

        for(i=0,cnt=0;i<n;i++){
            s = s + str.slice(-1);
            str = str.slice(0,str.length-1);
            cnt++;
            if(cnt>=3){
                cnt = 0;
                s = s + ' ';
            }
        }
        n = s.length;
        for(i=0;i<n;i++){
            res = res + s.slice(-1);
            s = s.slice(0,s.length-1);
        }
	    return(res);
    }

/////////////////////////////

    padFloat(str){

        var res='',s='',i,cnt,n, ext, point;

        ext = '';
        str = '' + str;
        point = str.indexOf('.');
        if(point==-1) { str = str + '.00'};
        point = str.indexOf('.');
        if(point>-1) {
            ext = str.slice(point+1, str.length);
            str = str.slice(0, point);
            if(ext.length > 2) ext = ext.slice(0, 1);
            if(ext.length == 1) ext = ext + '0';
            if(ext.length == 0) ext = ext + '00';
        }
        n = str.length;
        for(i=0,cnt=0;i<n;i++){
            s = s + str.slice(-1);
            str = str.slice(0,str.length-1);
            cnt++;
            if(cnt>=3){
                cnt = 0;
                s = s + ' ';
            }
        }
        n = s.length;
        for(i=0;i<n;i++){
            res = res + s.slice(-1);
            s = s.slice(0,s.length-1);
        }
        if(ext!='') {
            res += ',';
            res += ext;
        }
    return(res);

    }

}

export default Calc;