;"use strict";

const vpData = {

		'st0_0_1': {
			group: 'Амбулаторное лечение:<br>Нетяжелая ВП у пациентов без сопутствующих заболеваний, '+
			'не принимавших за последние 3 мес АМП ≥2 дней и не имеющих других факторов риска',
			ambul: 1,
			meth: [
				{
					// name: 'Амоксициллин внутрь',
					// info: '(амоксициллин)',
					meth: 0,
					rate: 65,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин',
					// info: '(моксифлоксацин)',
					meth: 1,
					rate: 10,
					days: [7],
					cycles: [1],
					pats: 0
				},
				// {
				// 	name: 'Гемифлоксацин',
				// 	info: '(гемифлоксацин)',
				// 	drugs: [],
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
					// name: 'Левофлоксацин',
					// info: '(левофлоксацин)',
					meth: 2,
					rate: 20,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Доксициклин',
					// info: '(доксициклин)',
					meth: 3,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [7],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_2': {
			group: 'Амбулаторное лечение:<br>Нетяжелая ВП у пациентов с сопутствующими заболеваниями '+ 
			'и/или принимавшими за последние 3 мес АМП ≥2 дней и/или имеющих другие факторы риска',
			ambul: 1,
			meth: [
				{
					// name: 'Амоксициллин/клавуланат',
					// info: '(амоксициллин/клавуланат)',
					meth: 6,
					rate: 60,
					days: [7],
					cycles: [1],
					pats: 0
				},
				// {
				// 	name: 'Ампициллин/сульбактам',
				// 	info: '(ампициллин/сульбактам)',
				// 	drugs: [9],//
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				// {
				// 	name: 'Амоксициллин/сульбактам',
				// 	info: '(амоксициллин/сульбактам)',
				// 	drugs: [8],//
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
					// name: 'Левофлоксацин',
					// info: '(левофлоксацин)',
					meth: 2,
					rate: 25,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин',
					// info: '(моксифлоксацин)',
					meth: 1,
					rate: 10,
					days: [7],
					cycles: [1],
					pats: 0
				},
				// {
				// 	name: 'Гемифлоксацин',
				// 	info: '(гемифлоксацин)',
				// 	drugs: [],
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
					// name: 'Цефдиторен',
					// info: '(цефдиторен)',
					meth: 7,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [7],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_3': {
			group: 'Стационарное лечение:<br>Нетяжелая ВП у пациентов без сопутствующих заболеваний, '+ 
			'не принимавших за последние 3 мес АМП ≥2 дней и не имеющих других факторов риска',
			ambul: 0,
			meth: [
				{
					// name: 'Амоксициллин/клавуланат в/в или в/м',
					// info: '(амоксициллин/клавуланат)',
					meth: 8,
					rate: 30,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Ампициллин в/в или в/м',
					// info: '(ампициллин)',
					meth: 9,
					rate: 30,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Ампициллин/сульбактам в/в или в/м',
					// info: '(ампициллин/сульбактам)',
					meth: 10,
					rate: 2.5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амоксициллин/сульбактам в/в или в/м',
					// info: '(амоксициллин/сульбактам)',
					meth: 11,
					rate: 2.5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин в/в',
					// info: '(моксифлоксацин)',
					meth: 12,
					rate: 10,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин в/в',
					// info: '(левофлоксацин)',
					meth: 13,
					rate: 25,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_4': {
			group: 'Стационарное лечение:<br>Нетяжелая ВП у пациентов с сопутствующими заболеваниями '+ 
			'и/или принимавшими за последние 3 мес АМП ≥2 дней и/или имеющих другие факторы риска',
			ambul: 0,
			meth: [
				{
					// name: 'Амоксициллин/клавуланат в/в или в/м',
					// info: '(амоксициллин/клавуланат)',
					meth: 8,
					rate: 20,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефотаксим в/в или в/м',
					// info: '(цефотаксим)',
					meth: 14,
					rate: 20,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефтриаксон в/в или в/м',
					// info: '(цефтриаксон)',
					meth: 15,
					rate: 25,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин в/в',
					// info: '(моксифлоксацин)',
					meth: 12,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин в/в',
					// info: '(левофлоксацин)',
					meth: 13,
					rate: 20,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефтаролин в/в',
					// info: '(цефтаролин)',
					meth: 16,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эртапенем в/в или в/м',
					// info: '(эртапенем)',
					meth: 17,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_5': {
			group: 'Стационарное лечение:<br>Тяжёлая ВП без факторов риска инфицирования P. aeruginosa ' +
			'и предполагаемой/документированной аспирации',
			ambul: 0,
			meth: [
				{
					// name: 'Цефтриаксон в/в или в/м + азитромицин в/в',
					// info: '(цефтриаксон, азитромицин)',
					meth: 18,
					rate: 15,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Цефотаксим в/в или в/м + азитромицин в/в',
					// info: '(цефотаксим, азитромицин)',
					meth: 19,
					rate: 15,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Цефтаролин в/в + азитромицин в/в',
					// info: '(цефтаролин, азитромицин)',
					meth: 20,
					rate: 10,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Амоксициллин/клавуланат в/в или в/м + азитромицин в/в',
					// info: '(амоксициллин/клавуланат, азитромицин)',
					meth: 21,
					rate: 10,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Амоксициллин/сульбактам в/в или в/м',
					// info: '(амоксициллин/сульбактам)',
					meth: 11,
					rate: 10,
					days: [9],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эртапенем в/в + азитромицин в/в',
					// info: '(эртапенем, азитромицин)',
					meth: 22,
					rate: 10,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				// {
				// 	name: 'Эртапенем в/в + кларитромицин в/в',
				// 	info: '(эртапенем, кларитромицин)',
				// 	drugs: [31,14],//
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
					// name: 'Моксифлоксацин в/в',
					// info: '(моксифлоксацин)',
					meth: 12,
					rate: 10,
					days: [9],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин в/в + цефтриаксон',
					// info: '(левофлоксацин, цефтриаксон)',
					meth: 23,
					rate: 10,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Цефотаксим в/в или в/м + азитромицин в/в',
					// info: '(цефотаксим, азитромицин)',
					meth: 19,
					rate: 10,
					days: [9,9],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эноксапарин',
					// info: '(эноксапарин)',
					meth: 24,
					rate: -100,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_6': {
			group: 'Стационарное лечение:<br>Тяжёлая ВП с факторами риска инфицирования P. aeruginosa',
			ambul: 0,
			meth: [
				{
					// name: 'Пиперациллин/тазобактам + ципрофлоксацин',
					// info: '(пиперациллин/тазобактам, ципрофлоксацин)',
					meth: 25,
					rate: 10,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Пиперациллин/тазобактам + левофлоксацин в/в',
					// info: '(пиперациллин/тазобактам, левофлоксацин)',
					meth: 26,
					rate: 10,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Меропенем + ципрофлоксацин',
					// info: '(меропенем, ципрофлоксацин)',
					meth: 27,
					rate: 15,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Меропенем + левофлоксацин в/в',
					// info: '(меропенем, левофлоксацин)',
					meth: 28,
					rate: 15,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Имипенем в/в + ципрофлоксацин',
					// info: '(имипенем, ципрофлоксацин)',
					meth: 29,
					rate: 5,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Имипенем в/в + левофлоксацин в/в',
					// info: '(имипенем, левофлоксацин)',
					meth: 30,
					rate: 15,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Пиперациллин/тазобактам + азитромицин в/в',
					// info: '(пиперациллин/тазобактам, азитромицин)',
					meth: 31,
					rate: 5,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Пиперациллин/тазобактам + кларитромицин в/в',
					// info: '(пиперациллин/тазобактам, кларитромицин)',
					meth: 32,
					rate: 5,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Меропенем + азитромицин в/в',
					// info: '(меропенем, азитромицин)',
					meth: 33,
					rate: 5,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				// {
				// 	name: 'Меропенем + кларитромицин в/в',
				// 	info: '(меропенем, кларитромицин)',
				// 	drugs: [18,14],//
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
//					name: 'Имипенем/циластатин в/в + азитромицин в/в',
					// name: 'Имипенем в/в + азитромицин в/в',
					// info: '(имипенем, азитромицин)',
					meth: 34,
					rate: 5,
					days: [12,12],
					cycles: [1,1],
					pats: 0
				},
				// {
				// 	name: 'Имипенем/циластатин в/в + кларитромицин в/в',
				// 	info: '(имипенем/циластатин, кларитромицин)',
				// 	drugs: [],
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
					// name: 'Моксифлоксацин + амикацин',
					// info: '(моксифлоксацин, амикацин)',
					meth: 35,
					rate: 5,
					days: [12,12,12],
					cycles: [1,1,1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин + амикацин',
					// info: '(левофлоксацин, амикацин)',
					meth: 36,
					rate: 5,
					days: [12,12,12],
					cycles: [1,1,1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эноксапарин',
					// info: '(эноксапарин)',
					meth: 24,
					rate: -100,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_7': {
			group: 'Стационарное лечение:<br>Тяжёлая ВП с документированной/предполагаемой аспирацией',
			ambul: 0,
			meth: [
				{
					// name: 'Амоксициллин/клавуланат в/в',
					// info: '(амоксициллин/клавуланат)',
					meth: 45,
					rate: 30,
					days: [14],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Ампициллин/сульбактам в/в',
					// info: '(ампициллин/сульбактам)',
					meth: 46,
					rate: 15,
					days: [14],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Пиперациллин/тазобактам в/в',
					// info: '(пиперациллин/тазобактам)',
					meth: 37,
					rate: 5,
					days: [14],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эртапенем в/в',
					// info: '(эртапенем)',
					meth: 38,
					rate: 10,
					days: [14],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Меропенем в/в',
					// info: '(меропенем)',
					meth: 39,
					rate: 15,
					days: [14],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Имипенем в/в',
					// info: '(имипенем)',
					meth: 40,
					rate: 15,
					days: [14],
					cycles: [1],
					pats: 0
				},
				// {
				// 	name: 'Имипенем/циластатин в/в',
				// 	info: '(имипенем/циластатин)',
				// 	drugs: [],
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				// {
				// 	name: 'Цефтриаксон в/в',
				// 	info: '(цефтриаксон)',
				// 	drugs: [28],//
				// 	rate: ,
				// 	dose: [],
				// 	ds: 'ds23.001',
				// 	st: 'st23.004',
				// 	cycles: [],
				// 	pats: 0
				// },
				{
					// name: 'Цефотаксим в/в + клиндамицин в/в',
					// info: '(цефотаксим, клиндамицин)',
					meth: 41,
					rate: 5,
					days: [14,14],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Цефотаксим в/в + метронидазол в/в',
					// info: '(цефотаксим, метронидазол)',
					meth: 42,
					rate: 5,
					days: [14,14],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: -20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эноксапарин',
					// info: '(эноксапарин)',
					meth: 24,
					rate: -100,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_8': {
			group: 'Стационарное лечение:<br>Гриппозная пневмония',
			ambul: 0,
			meth: [
				{
					// name: 'Осельтамивир + цефтаролин',
					// info: '(осельтамивир, цефтаролин)',
					meth: 43,
					rate: 95,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Занамивир + цефтаролин',
					// info: '(занамивир, цефтаролин)',
					meth: 44,
					rate: 5,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'N-ацетилцистеин',
					// info: '(N-ацетилцистеин)',
					meth: 4,
					rate: 20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 5,
					rate: 20,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		}
	}

export default vpData;	