;"use strict";

const asData = {

		'st0_0_1': {
			group: 'Группа А<br>постоянный прием',
			//ambul: 0,
			meth: [
				// {
				// 	// name: 'Вилантерол',
				// 	// info: '(вилантерол)',
				// 	meth: ,
				// 	rate: 0,
				// 	days: [],
				// 	cycles: [1],
				// 	pats: 0
				// },
				{
					// name: 'Формотерол',
					// info: '(формотерол)',
					meth: 108,
					rate: 10,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Тиотропия бромид',
					// info: '(тиотропия бромид)',
					meth: 71,
					rate: 40,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Аклидиния бромид',
					// info: '(аклидиния бромид)',
					meth: 109,
					rate: 5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид',
					// info: '(гликопиррония бромид)',
					meth: 110,
					rate: 12,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация',
					// info: '(гликопиррония бромид/индакатерол)',
					meth: 111,
					rate: 3,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация',
					// info: '(тиотропия бромид/олодатерол)',
					meth: 112,
					rate: 20,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация',
					// info: '(умеклидиния бромид/вилантерол)',
					meth: 113,
					rate: 5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация',
					// info: '(аклидиния бромид/формотерол)',
					meth: 114,
					rate: 5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Ацетилцистеин',
					// info: '(ацетилцистеин)',
					meth: 150,
					rate: -5,
					days: [536],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 151,
					rate: -5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Карбоцистеин',
					// info: '(карбоцистеин)',
					meth: 152,
					rate: -5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эрдостеин',
					// info: '(эрдостеин)',
					meth: 153,
					rate: -5,
					days: [365],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_2': {
			group: 'Группа B<br>постоянный прием',
			//ambul: 0,
			meth: [
				// {
				// 	// name: 'Вилантерол',
				// 	// info: '(вилантерол)',
				// 	meth: ,
				// 	rate: 0,
				// 	days: [],
				// 	cycles: [1],
				// 	pats: 0
				// },
				{
					// name: 'Формотерол',
					// info: '(формотерол)',
					meth: 108,
					rate: 10,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Тиотропия бромид',
					// info: '(тиотропия бромид)',
					meth: 71,
					rate: 40,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Аклидиния бромид',
					// info: '(аклидиния бромид)',
					meth: 109,
					rate: 5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид',
					// info: '(гликопиррония бромид)',
					meth: 110,
					rate: 12,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация',
					// info: '(гликопиррония бромид/индакатерол)',
					meth: 111,
					rate: 3,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация',
					// info: '(тиотропия бромид/олодатерол)',
					meth: 112,
					rate: 20,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация',
					// info: '(умеклидиния бромид/вилантерол)',
					meth: 113,
					rate: 5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация',
					// info: '(аклидиния бромид/формотерол)',
					meth: 114,
					rate: 5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Ацетилцистеин',
					// info: '(ацетилцистеин)',
					meth: 150,
					rate: -5,
					days: [536],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 151,
					rate: -5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Карбоцистеин',
					// info: '(карбоцистеин)',
					meth: 152,
					rate: -5,
					days: [365],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Эрдостеин',
					// info: '(эрдостеин)',
					meth: 153,
					rate: -5,
					days: [365],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_3': {
			group: 'Группа C<br>постоянный прием',
			//ambul: 0,
			meth: [
				// {
				// 	// name: 'Вилантерол',
				// 	// info: '(вилантерол)',
				// 	meth: ,
				// 	rate: 0,
				// 	days: [],
				// 	cycles: [1],
				// 	pats: 0
				// },
				{
					// name: 'Формотерол',
					// info: '(формотерол)',
					meth: 108,
					rate: 3,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид',
					// info: '(тиотропия бромид)',
					meth: 71,
					rate: 10,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид',
					// info: '(аклидиния бромид)',
					meth: 109,
					rate: 2,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид',
					// info: '(гликопиррония бромид)',
					meth: 110,
					rate: 2,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид',
					// info: '(умеклидиния бромид)',
					meth: 115,
					rate: 0,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация',
					// info: '(гликопиррония бромид/индакатерол)',
					meth: 111,
					rate: 10,
					days: [365],
					sect: 1,
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация',
					// info: '(тиотропия бромид/олодатерол)',
					meth: 112,
					rate: 13,
					days: [365],
					cycles: [1],
					sect: 1,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация',
					// info: '(умеклидиния бромид/вилантерол)',
					meth: 113,
					rate: 5,
					days: [365],
					cycles: [1],
					sect: 1,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация',
					// info: '(аклидиния бромид/формотерол)',
					meth: 114,
					rate: 3,
					days: [365],
					cycles: [1],
					sect: 1,
					pats: 0
				},
//--------------------------
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация',
					// info: '(беклометазон/формотерол)',
					meth: 49,
					rate: 0.76,
					days: [365],
					cycles: [1],
					sect: 2,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол',
					// info: '(будесонид/формотерол)',
					meth: 55,
					rate: 1.76,
					days: [365],
					cycles: [1],
					sect: 2,
					pats: 0
				},
//--------------------------
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + беклометазон',
					// info: '(гликопиррония бромид/индакатерол, беклометазон)',
					meth: 116,
					rate: 4.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + будесонид',
					// info: '(гликопиррония бромид/индакатерол, будесонид)',
					meth: 117,
					rate: 4.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + флутиказон',
					// info: '(гликопиррония бромид/индакатерол, флутиказон)',
					meth: 118,
					rate: 2.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + беклометазон',
					// info: '(тиотропия бромид/олодатерол, беклометазон)',
					meth: 119,
					rate: 1.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + будесонид',
					// info: '(тиотропия бромид/олодатерол, будесонид)',
					meth: 120,
					rate: 7.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + флутиказон',
					// info: '(тиотропия бромид/олодатерол, флутиказон)',
					meth: 121,
					rate: 1.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + беклометазон',
					// info: '(умеклидиния бромид/вилантерол, беклометазон)',
					meth: 122,
					rate: 2.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + будесонид',
					// info: '(умеклидиния бромид/вилантерол, будесонид)',
					meth: 123,
					rate: 1.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + флутиказон',
					// info: '(умеклидиния бромид/вилантерол, флутиказон)',
					meth: 124,
					rate: 2.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + беклометазон',
					// info: '(аклидиния бромид/формотерол, беклометазон)',
					meth: 125,
					rate: 0.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + будесонид',
					// info: '(аклидиния бромид/формотерол, будесонид)',
					meth: 126,
					rate: 0.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + флутиказон',
					// info: '(аклидиния бромид/формотерол, флутиказон)',
					meth: 127,
					rate: 0.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				// { !! Отсутствует в таблице
				// 	// name: 'Беклометазон/сальбутамол фиксированная комбинация + тиотропия бромид',
				// 	// info: '(беклометазон/сальбутамол, тиотропия бромид)',
				// 	meth: ,
				// 	rate: 0,
				// 	days: [365,365],
				// 	cycles: [1,1],
				// 	sect: 3,
				// 	pats: 0
				// },
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид',
					// info: '(вилантерол/флутиказон, тиотропия бромид)',
					meth: 59, //130,
					rate: 1.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
					// info: '(беклометазон/формотерол фиксированная комбинация, тиотропия бромид)',
					meth: 128,
					rate: 1.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					// info: '(будесонид/формотерол, тиотропия бромид)',
					meth: 129,
					rate: 4.76,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
//--------------------------
				{
					// name: 'Беклометазон/формотерол + рофлумиласт',
					// info: '(беклометазон/формотерол, рофлумиласт)',
					meth: 131,
					rate: 0.238,
					days: [365,365],
					cycles: [1,1],
					sect: 4,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол + рофлумиласт',
					// info: '(будесонид/формотерол, рофлумиласт)',
					meth: 132,
					rate: 0.238,
					days: [365,365],
					cycles: [1,1],
					sect: 4,
					pats: 0
				},
//--------------------------
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(гликопиррония бромид/индакатерол, беклометазон, рофлумиласт)',
					meth: 133,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(гликопиррония бромид/индакатерол, будесонид, рофлумиласт)',
					meth: 134,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(гликопиррония бромид/индакатерол, флутиказон, рофлумиласт)',
					meth: 135,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(тиотропия бромид/олодатерол, беклометазон, рофлумиласт)',
					meth: 136,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(тиотропия бромид/олодатерол, будесонид, рофлумиласт)',
					meth: 137,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(тиотропия бромид/олодатерол, флутиказон, рофлумиласт)',
					meth: 138,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(умеклидиния бромид/вилантерол, беклометазон, рофлумиласт)',
					meth: 139,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(умеклидиния бромид/вилантерол, будесонид, рофлумиласт)',
					meth: 140,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(умеклидиния бромид/вилантерол, флутиказон, рофлумиласт)',
					meth: 141,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(аклидиния бромид/формотерол, беклометазон, рофлумиласт)',
					meth: 142,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(аклидиния бромид/формотерол, будесонид, рофлумиласт)',
					meth: 143,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(аклидиния бромид/формотерол, флутиказон, рофлумиласт)',
					meth: 144,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				// { !! Отсутствует в таблице
				// 	// name: 'Беклометазон/сальбутамол фиксированная комбинация + тиотропия бромид + рофлумиласт',
				// 	// info: '(беклометазон/сальбутамол, тиотропия бромид, рофлумиласт)',
				// 	meth: ,
				// 	rate: 0.238,
				// 	days: [365,365,365],
				// 	cycles: [1,1,1],
				// 	sect: 5,
				// 	pats: 0
				// },
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид + рофлумиласт',
					// info: '(беклометазон/формотерол фиксированная комбинация, тиотропия бромид, рофлумиласт)',
					meth: 145,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид + рофлумиласт',
					// info: '(будесонид/формотерол, тиотропия бромид, рофлумиласт)',
					meth: 146,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид + рофлумиласт',
					// info: '(вилантерол/флутиказон, тиотропия бромид, рофлумиласт)',
					meth: 147,
					rate: 0.238,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
//--------------------------
				{
					// name: 'Беклометазон/формотерол + теофиллин',
					// info: '(беклометазон/формотерол, теофиллин)',
					meth: 148,
					rate: 1,
					days: [365,365],
					cycles: [1,1],
					sect: 6,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол + теофиллин',
					// info: '(будесонид/формотерол, теофиллин)',
					meth: 149,
					rate: 3,
					days: [365,365],
					cycles: [1,1],
					sect: 6,
					pats: 0
				},
//--------------------------
				{
					// name: 'Ацетилцистеин',
					// info: '(ацетилцистеин)',
					meth: 150,
					rate: -5,
					days: [536],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 151,
					rate: -5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Карбоцистеин',
					// info: '(карбоцистеин)',
					meth: 152,
					rate: -5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Эрдостеин',
					// info: '(эрдостеин)',
					meth: 153,
					rate: -5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				}
			]
		},
		'st0_0_4': {
			group: 'Группа D<br>постоянный прием',
			//ambul: 0,
			meth: [
				// {
				// 	// name: 'Вилантерол',
				// 	// info: '(вилантерол)',
				// 	meth: ,
				// 	rate: 0,
				// 	days: [],
				// 	cycles: [1],
				// 	pats: 0
				// },
				{
					// name: 'Формотерол',
					// info: '(формотерол)',
					meth: 108,
					rate: 0,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид',
					// info: '(тиотропия бромид)',
					meth: 71,
					rate: 5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид',
					// info: '(аклидиния бромид)',
					meth: 109,
					rate: 2,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид',
					// info: '(гликопиррония бромид)',
					meth: 110,
					rate: 3,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид',
					// info: '(умеклидиния бромид)',
					meth: 115,
					rate: 0,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация',
					// info: '(гликопиррония бромид/индакатерол)',
					meth: 111,
					rate: 7,
					days: [365],
					sect: 1,
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация',
					// info: '(тиотропия бромид/олодатерол)',
					meth: 112,
					rate: 20,
					days: [365],
					cycles: [1],
					sect: 1,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация',
					// info: '(умеклидиния бромид/вилантерол)',
					meth: 113,
					rate: 8,
					days: [365],
					cycles: [1],
					sect: 1,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация',
					// info: '(аклидиния бромид/формотерол)',
					meth: 114,
					rate: 2,
					days: [365],
					cycles: [1],
					sect: 1,
					pats: 0
				},
//--------------------------
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация',
					// info: '(беклометазон/формотерол)',
					meth: 49,
					rate: 1.76,
					days: [365],
					cycles: [1],
					sect: 2,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол',
					// info: '(будесонид/формотерол)',
					meth: 55,
					rate: 2.76,
					days: [365],
					cycles: [1],
					sect: 2,
					pats: 0
				},
//--------------------------
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + беклометазон',
					// info: '(гликопиррония бромид/индакатерол, беклометазон)',
					meth: 116,
					rate: 4.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + будесонид',
					// info: '(гликопиррония бромид/индакатерол, будесонид)',
					meth: 117,
					rate: 4.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + флутиказон',
					// info: '(гликопиррония бромид/индакатерол, флутиказон)',
					meth: 118,
					rate: 1.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + беклометазон',
					// info: '(тиотропия бромид/олодатерол, беклометазон)',
					meth: 119,
					rate: 3.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + будесонид',
					// info: '(тиотропия бромид/олодатерол, будесонид)',
					meth: 120,
					rate: 3.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + флутиказон',
					// info: '(тиотропия бромид/олодатерол, флутиказон)',
					meth: 121,
					rate: 1.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + беклометазон',
					// info: '(умеклидиния бромид/вилантерол, беклометазон)',
					meth: 122,
					rate: 2.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + будесонид',
					// info: '(умеклидиния бромид/вилантерол, будесонид)',
					meth: 123,
					rate: 2.706,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + флутиказон',
					// info: '(умеклидиния бромид/вилантерол, флутиказон)',
					meth: 124,
					rate: 1.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + беклометазон',
					// info: '(аклидиния бромид/формотерол, беклометазон)',
					meth: 125,
					rate: 0.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + будесонид',
					// info: '(аклидиния бромид/формотерол, будесонид)',
					meth: 126,
					rate: 0.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + флутиказон',
					// info: '(аклидиния бромид/формотерол, флутиказон)',
					meth: 127,
					rate: 0.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				// { !! Отсутствует в таблице
				// 	// name: 'Беклометазон/сальбутамол фиксированная комбинация + тиотропия бромид',
				// 	// info: '(беклометазон/сальбутамол, тиотропия бромид)',
				// 	meth: ,
				// 	rate: 0,
				// 	days: [365,365],
				// 	cycles: [1,1],
				// 	sect: 3,
				// 	pats: 0
				// },
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид',
					// info: '(вилантерол/флутиказон, тиотропия бромид)',
					meth: 130,
					rate: 2.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
					// info: '(беклометазон/формотерол фиксированная комбинация + тиотропия бромид)',
					meth: 128,
					rate: 1.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					// info: '(будесонид/формотерол, тиотропия бромид)',
					meth: 129,
					rate: 4.71,
					days: [365,365],
					cycles: [1,1],
					sect: 3,
					pats: 0
				},
//--------------------------
				{
					// name: 'Беклометазон/формотерол + рофлумиласт',
					// info: '(беклометазон/формотерол, рофлумиласт)',
					meth: 131,
					rate: 0.294,
					days: [365,365],
					cycles: [1,1],
					sect: 4,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол + рофлумиласт',
					// info: '(будесонид/формотерол, рофлумиласт)',
					meth: 132,
					rate: 0.294,
					days: [365,365],
					cycles: [1,1],
					sect: 4,
					pats: 0
				},
//--------------------------
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(гликопиррония бромид/индакатерол, беклометазон, рофлумиласт)',
					meth: 133,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(гликопиррония бромид/индакатерол, будесонид, рофлумиласт)',
					meth: 134,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(гликопиррония бромид/индакатерол, флутиказон, рофлумиласт)',
					meth: 135,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(тиотропия бромид/олодатерол, беклометазон, рофлумиласт)',
					meth: 136,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(тиотропия бромид/олодатерол, будесонид, рофлумиласт)',
					meth: 137,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Тиотропия бромид/олодатерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(тиотропия бромид/олодатерол, флутиказон, рофлумиласт)',
					meth: 138,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(умеклидиния бромид/вилантерол, беклометазон, рофлумиласт)',
					meth: 139,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(умеклидиния бромид/вилантерол, будесонид, рофлумиласт)',
					meth: 140,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(умеклидиния бромид/вилантерол, флутиказон, рофлумиласт)',
					meth: 141,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + беклометазон + рофлумиласт',
					// info: '(аклидиния бромид/формотерол, беклометазон, рофлумиласт)',
					meth: 142,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + будесонид + рофлумиласт',
					// info: '(аклидиния бромид/формотерол, будесонид, рофлумиласт)',
					meth: 143,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Аклидиния бромид/формотерол фиксированная комбинация + флутиказон + рофлумиласт',
					// info: '(аклидиния бромид/формотерол, флутиказон, рофлумиласт)',
					meth: 144,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				// { !! Отсутствует в таблице
				// 	// name: 'Беклометазон/сальбутамол фиксированная комбинация + тиотропия бромид + рофлумиласт',
				// 	// info: '(беклометазон/сальбутамол, тиотропия бромид, рофлумиласт)',
				// 	meth: ,
				// 	rate: 0.294,
				// 	days: [365,365,365],
				// 	cycles: [1,1,1],
				// 	sect: 5,
				// 	pats: 0
				// },
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид + рофлумиласт',
					// info: '(беклометазон/формотерол фиксированная комбинация, тиотропия бромид, рофлумиласт)',
					meth: 145,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид + рофлумиласт',
					// info: '(будесонид/формотерол, тиотропия бромид, рофлумиласт)',
					meth: 146,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид + рофлумиласт',
					// info: '(вилантерол/флутиказон, тиотропия бромид, рофлумиласт)',
					meth: 147,
					rate: 0.294,
					days: [365,365,365],
					cycles: [1,1,1],
					sect: 5,
					pats: 0
				},
//--------------------------
				{
					// name: 'Беклометазон/формотерол + теофиллин',
					// info: '(беклометазон/формотерол, теофиллин)',
					meth: 148,
					rate: 2,
					days: [365,365],
					cycles: [1,1],
					sect: 6,
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол + теофиллин',
					// info: '(будесонид/формотерол, теофиллин)',
					meth: 149,
					rate: 3,
					days: [365,365],
					cycles: [1,1],
					sect: 6,
					pats: 0
				},
//--------------------------
				{
					// name: 'Ацетилцистеин',
					// info: '(ацетилцистеин)',
					meth: 150,
					rate: -5,
					days: [536],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Амброксол',
					// info: '(амброксол)',
					meth: 151,
					rate: -5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Карбоцистеин',
					// info: '(карбоцистеин)',
					meth: 152,
					rate: -5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				},
				{
					// name: 'Эрдостеин',
					// info: '(эрдостеин)',
					meth: 153,
					rate: -5,
					days: [365],
					cycles: [1],
					sect: 0,
					pats: 0
				}
			]
		},
		'st0_0_5': {
			group: 'Группа А<br>прием по потребности',
			//ambul: 0,
			meth: [
				{
					// name: 'Cальбутамол',
					// info: '(сальбутамол)',
					meth: 105,
					rate: 40,
					days: [8],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Фенотерол',
					// info: '(фенотерол)',
					meth: 158,
					rate: 20,
					days: [8],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Тиотропия бромид',
				// 	// info: '(тиотропия бромид)',
				// 	meth: 71,
				// 	rate: 40,
				// 	days: [8],
				// 	cycles: [1],
				// 	pats: 0
				// }

				{
					// name: 'Ипратропия бромид',
					// info: '(ипратропия бромид)',
					meth: 107,
					rate: 40,
					days: [8],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_6': {
			group: 'Группа B<br>прием по потребности',
			//ambul: 0,
			meth: [
				{
					// name: 'Cальбутамол',
					// info: '(сальбутамол)',
					meth: 105,
					rate: 40,
					days: [10],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Фенотерол',
					// info: '(фенотерол)',
					meth: 158,
					rate: 20,
					days: [10],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Тиотропия бромид',
				// 	// info: '(тиотропия бромид)',
				// 	meth: 71,
				// 	rate: 40,
				// 	days: [10],
				// 	cycles: [1],
				// 	pats: 0
				// }

				{
					// name: 'Ипратропия бромид',
					// info: '(ипратропия бромид)',
					meth: 107,
					rate: 40,
					days: [10],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_7': {
			group: 'Группа C<br>прием по потребности',
			//ambul: 0,
			meth: [
				{
					// name: 'Cальбутамол',
					// info: '(сальбутамол)',
					meth: 105,
					rate: 40,
					days: [22],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Фенотерол',
					// info: '(фенотерол)',
					meth: 158,
					rate: 20,
					days: [22],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Тиотропия бромид',
				// 	// info: '(тиотропия бромид)',
				// 	meth: 71,
				// 	rate: 40,
				// 	days: [22],
				// 	cycles: [1],
				// 	pats: 0
				// }

				{
					// name: 'Ипратропия бромид',
					// info: '(ипратропия бромид)',
					meth: 107,
					rate: 40,
					days: [22],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_8': {
			group: 'Группа D<br>прием по потребности',
			//ambul: 0,
			meth: [
				{
					// name: 'Cальбутамол',
					// info: '(сальбутамол)',
					meth: 105,
					rate: 40,
					days: [26],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Фенотерол',
					// info: '(фенотерол)',
					meth: 158,
					rate: 20,
					days: [26],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Тиотропия бромид',
				// 	// info: '(тиотропия бромид)',
				// 	meth: 71,
				// 	rate: 40,
				// 	days: [26],
				// 	cycles: [1],
				// 	pats: 0
				// }

				{
					// name: 'Ипратропия бромид',
					// info: '(ипратропия бромид)',
					meth: 107,
					rate: 40,
					days: [26],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_9': {
			group: 'Группа А<br>при обострении',
			meth: [
				{
					// name: 'Амоксициллин/клавуланат',
					// info: '(амоксициллин/клавуланат)',
					meth: 154,
					rate: 50,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефиксим',
					// info: '(цефиксим)',
					meth: 155,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин',
					// info: '(левофлоксацин)',
					meth: 156,
					rate: 25,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин',
					// info: '(моксифлоксацин)',
					meth: 157,
					rate: 20,
					days: [7],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_10': {
			group: 'Группа B<br>при обострении',
			meth: [
				{
					// name: 'Амоксициллин/клавуланат',
					// info: '(амоксициллин/клавуланат)',
					meth: 154,
					rate: 50,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефиксим',
					// info: '(цефиксим)',
					meth: 155,
					rate: 5,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин',
					// info: '(левофлоксацин)',
					meth: 156,
					rate: 25,
					days: [7],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин',
					// info: '(моксифлоксацин)',
					meth: 157,
					rate: 20,
					days: [7],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_11': {
			group: 'Группа C<br>при обострении',
			meth: [
				{
					// name: 'Амоксициллин/клавуланат',
					// info: '(амоксициллин/клавуланат)',
					meth: 154,
					rate: 50,
					days: [16],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефиксим',
					// info: '(цефиксим)',
					meth: 155,
					rate: 5,
					days: [16],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин',
					// info: '(левофлоксацин)',
					meth: 156,
					rate: 25,
					days: [16],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин',
					// info: '(моксифлоксацин)',
					meth: 157,
					rate: 20,
					days: [16],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_12': {
			group: 'Группа D<br>при обострении',
			meth: [
				{
					// name: 'Амоксициллин/клавуланат',
					// info: '(амоксициллин/клавуланат)',
					meth: 154,
					rate: 50,
					days: [20],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Цефиксим',
					// info: '(цефиксим)',
					meth: 155,
					rate: 5,
					days: [20],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Левофлоксацин',
					// info: '(левофлоксацин)',
					meth: 156,
					rate: 25,
					days: [20],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Моксифлоксацин',
					// info: '(моксифлоксацин)',
					meth: 157,
					rate: 20,
					days: [20],
					cycles: [1],
					pats: 0
				}
			]
		}


	}

export default asData;	