;"use strict";

import State from './state';
import methods from './methods';

class KsgSetup {

	constructor() {
		this.editMode = 'text';
		this.currLineNum = -1;
		this.methodBuf = [];
		$('#ksg-close-button').on(State.startEvent, (e)=>this.closePanel(e));
		$('#m-setup-save-but').on(State.startEvent, (e)=>this.closeEdit(e));
		$('#m-setup-close-but').on(State.startEvent, (e)=>this.closeEdit(e));
		this.currMeth = 0;
		this.loadData();
	}

//////////////////////////////

	showPanel() {

		State.flModal = 1;
		$('#section9').show();
		$('#section'+State.currPage).hide();
		this.render();
		$('.footer').hide();
	}

	closePanel() {

		State.flModal = 0;
		$('#section9').hide();
		$('#section'+State.currPage).show();
	}

//////////////////////////////

	render() {

		let cnt, color = 0, colClass, ind, lineTemplate, currMeth, currDs, currSt,
			tableCode = '', listStr;	
		let len = 0;
		const holder = `<div class='v-holder'>&nbsp<br></div>`;

		this.methodBuf = [];
		for (let key in methods) {
			methods[key].key = key;
			this.methodBuf.push(methods[key]);
		}
		len = this.methodBuf.length;
		this.methodBuf.sort((a,b)=>{
			if(a.name.toLowerCase()>b.name.toLowerCase()) return 1;
			else return -1;
		});
		tableCode = '';
		for(cnt=0 ; cnt<len ; cnt++) {
//			currMeth = methods[cnt];
			currMeth = this.methodBuf[cnt];
			currSt = currMeth.st ; currDs = currMeth.ds;
			if(currMeth.custSt!==undefined) currSt = currMeth.stSet[currMeth.custSt];
			else if(currMeth.stSet!==undefined) currSt = currMeth.stSet[0];
			if(currMeth.custDs!==undefined) currDs = currMeth.dsSet[currMeth.custDs];
			else if(currMeth.dsSet!==undefined) currDs = currMeth.dsSet[0];

			listStr = 'Да';
			ind = currMeth.key;
			if(color==0) colClass = 'grey'; else colClass = '';
			lineTemplate = 
                `<div class="scr1-line" id="scr9-line${ind}">
                    <div class="scr1-col t9-col1 ${colClass}" id="ksg-col1-line${ind}">${currMeth.name}</div>
                    <div class="scr1-col t9-col2 ${colClass}" id="ksg-col2-line${ind}">${currDs}<br>${currSt}</div>
                    <div class="scr1-col t9-col3 ${colClass}" id="ksg-col3-line${ind}">${listStr}</div>
                    <div class="scr1-col t9-col4 ${colClass}" id="ksg-col4-line${ind}">
                        <div class="ksg-edit-butt" id="ksg-edit-butt${ind}">Изменить</div>
                    </div>
                </div>`;
            tableCode += lineTemplate;    
			color++; if (color>1) color = 0;	
		}
		tableCode += holder;
		tableCode += '<br><br><br>'
		$('#scr9-insert').html(tableCode);
		$('.ksg-edit-butt').on(State.startEvent, (e) => this.showEdit(e));
	}

///////////////////////////////////////////////

	showEdit(e) {

		let num = e.target.id,
			meth, dsLen = 0, stLen = 0, dsInsert, stInsert, dsStr, stStr, 
			codeStr, codes, select;

		if(State.flKsgEdit==1) return;
		State.flKsgEdit = 1;
		num = num.slice(13);
		$('#meth-setup').show();
		$('#meth-setup').css({'opacity':1});
		this.currMeth = num;
		meth = methods[num];
		$('#m-meth-name').text(meth.name);
		codes = meth.dsSet;
		dsInsert = '';
		if(codes) dsLen = codes.length;
		if(dsLen>0) {
			for(let cnt=0 ; cnt<dsLen ; cnt++) {
				select = ''; 
				if(meth.custDs===undefined && cnt==0) select = 'checked';
				if(meth.custDs===cnt) select = 'checked';
				dsStr = `<input type="radio" id="ds-choice${cnt}" class="ksg-choice"
                 name="ds-choice" value="${codes[cnt]}" ${select}>
                <label for="ds-choice${cnt}">${codes[cnt]}</label>
				`;
				dsInsert += dsStr;
			}
		} else {
			if(meth.ds==undefined || meth.ds=='') codeStr = '--------';
			else codeStr = meth.ds;
			dsInsert = `<input type="radio" id="ds-choice0"
                 name="ds-choice" value="${codeStr}" checked>
                <label for="ds-choice0">${codeStr}</label>
				`;
		}
		$('#ds-list').html(dsInsert);
		codes = meth.stSet;
		stInsert = '';
		if(codes) stLen = codes.length;
		if(stLen>0) {
			for(let cnt=0 ; cnt<stLen ; cnt++) {
				select = ''; 
				if(meth.custSt===undefined && cnt==0) select = 'checked';
				if(meth.custSt===cnt) select = 'checked';
				stStr = `<input type="radio" id="st-choice${cnt}" class="ksg-choice"
                 name="st-choice" value="${codes[cnt]}" ${select}>
                <label for="st-choice${cnt}">${codes[cnt]}</label>
				`;
				stInsert += stStr;
			}
		} else {
			if(meth.st==undefined || meth.st=='') codeStr = '--------';
			else codeStr = meth.st;
			stInsert = `<input type="radio" id="st-choice0"
                 name="st-choice" value="${codeStr}" checked>
                <label for="st-choice0">${codeStr}</label>
				`;
		}
		$('#st-list').html(stInsert);
		$('.ksg-choice').on('change', (e)=>this.changeKsg(e));
	}

///////////////////////////////////

	changeKsg(e) {

		let num = e.target.id, cnt, len, record = [], flFound;
		let prefix = num.slice(0,2);

		num = parseInt(num.slice(9));
		if(prefix=='st') {
			methods[this.currMeth].custSt = num;
			if(methods[this.currMeth].stSet[num]!=undefined)
				methods[this.currMeth].st = methods[this.currMeth].stSet[num];
	// Ищем номер текущего метода в массиве custSt и вырезаем или 
	// корректируем найденную пару 
			flFound = 0;
			for(cnt=0, len=State.custSt.length ; cnt<len ; cnt++) {
				if(this.currMeth==State.custSt[cnt][0]) {
					if(num==0) State.custSt.splice(cnt,1);
					else State.custSt[cnt][1] = num;
					flFound = 1;
					break;
				}
			}
			if(flFound==0 && num!=0) {
				record[0] = this.currMeth;
				record[1] = num;
				State.custSt.push(record);
			}
		}
		if(prefix=='ds') {
			methods[this.currMeth].custDs = num;
			if(methods[this.currMeth].dsSet[num]!=undefined)
				methods[this.currMeth].ds = methods[this.currMeth].dsSet[num];
			flFound = 0;
			for(cnt=0, len=State.custDs.length ; cnt<len ; cnt++) {
				if(this.currMeth==State.custDs[cnt][0]) {
					if(num==0) State.custDs.splice(cnt,1);
					else State.custDs[cnt][1] = num;
					flFound = 1;
					break;
				}
			}
			if(flFound==0 && num!=0) {
				record[0] = this.currMeth;
				record[1] = num;
				State.custDs.push(record);
			}
		}
		this.saveData();
		this.render();
		State.distPanel.render();
	}

///////////////////////////////////

	closeEdit(e) {

		$('#meth-setup').hide();
		$('#meth-setup').css({'opacity':0});
		State.flKsgEdit = 0;
	}

///////////////////////////////////

	saveData() {
		State.saveSetup();
	}


// Загружаем настройки выбранных КСГ в структуру methods
	loadData() {
		
		let cnt, len, ksgNum, methNum;

		State.loadSetup();
		for(cnt=0, len=State.custSt.length ; cnt<len ; cnt++) {
			ksgNum = State.custSt[cnt][1];
			methNum = State.custSt[cnt][0];
			methods[methNum].custSt = ksgNum;
			methods[methNum].st = methods[methNum].stSet[ksgNum];
		}
		for(cnt=0, len=State.custDs.length ; cnt<len ; cnt++) {
			ksgNum = State.custDs[cnt][1];
			methNum = State.custDs[cnt][0];
			methods[methNum].custDs = ksgNum;
			methods[methNum].ds = methods[methNum].dsSet[ksgNum];
		}
	}
} 

export default KsgSetup;