; "use strict";

import State from '../state';
import drugData from '../drugdata';
import baData from './ba-meth';
import methods from '../methods';

const sections = [];

class BaModel {

	constructor() {

		let group;

		for(group in baData) State.baPatsBuf[group] = -1;
	}

/////////////////////////////

	getSections() {

		return sections;
	}


/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal, nOfDays,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, currMeth,
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks,log;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = baData[st].meth;
		const currPath = State.currPath;
//console.log(st);
//if(st!='st0_2_6') return 0;
//console.log('------------>');
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			currMeth = methSet[cnt].meth;
			drugSet = methods[currMeth].drugs;
			doseSet = methods[currMeth].dose;
			nOfDrugs = drugSet.length;
			nOfDays = methSet[cnt].days;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==2) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfDays[currDrugN]*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
// if(st=='st0_0_5') {
// console.log('---------');
// 	console.log(currDrug.name);
// 	console.log(cycleDose);//2
// 	console.log(drugDose);//2
// 	console.log(Math.ceil(cycleDose/drugDose));//1 
// 	console.log(cycleDose/drugDose);//1
// 	console.log(drugPack);//10
// 	console.log(nOfDays[currDrugN]);//15
// 	console.log(nOfCycles[currDrugN]);//1
// 	console.log(drugPack);//10
// 	console.log(nOfPacks);//2
// }
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfDays[currDrugN]*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
					State.nOfRloPats[currPath] += nOfPatients;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
					State.nOfOnlsPats[currPath] += nOfPatients;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methods[currMeth].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methods[currMeth].st && methods[currMeth].st!='') {
					State.ksgBuf[methods[currMeth].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methods[currMeth].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methods[currMeth].ds && methods[currMeth].ds!='') {
					State.ksgBuf[methods[currMeth].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// 			!! Для дополнительных пациентов вероятность приходить в отрицательном значении,
//				чтобы расположить их первыми в алгоритме ранжирования
// patByf - ссылка на массив распределенных пациентов
// extra - дополнительные фиктивные пациенты, необходимые для расчета медикаментов,
//		добавляемых сверх реального распределения, например - амброксол. Отдельной 
//		группы пациентов с амброксолом нет, но 20% пациентов его получает, независимо
//		от того, в какую группу они попадают. 

// Версия с обратным проходом от маленьких вероятностей к большим
// из модуля РП

	disributePats(input, probBuf, patBuf, extra) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];
		let extraRest = extra;					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в возрастающем
// порядке
// Отдаем приоритет позициям с меньшей вероятностью!!
		rest = input; // Нераспределенный остаток
//		if(extra) rest += extra;

		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			if(data<0) { 
				data = 0 - data;
				pats = Math.round(data);
				if(pats>extraRest) pats = extraRest;
				patBuf[probList[i]] = pats;
				extraRest -= pats;
				if(extraRest<0) extraRest = 0;
			} else {
				pats = Math.round(data);
				if(pats>rest) pats = rest;
				patBuf[probList[i]] = pats;
				rest -= pats;
				if(rest<0) rest = 0;
			}	
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
		if(extraRest>0) patBuf[probList[0]] += extraRest;
	}
 
/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам с недостатком

	distributeCutMeth(group, ratio) {

		let flSt3;
		let pathData, prob;

		if(State.flNoDist)	return;

		pathData = baData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, extraPats, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		total = pathData[group].pats;
		total = Math.round(total);
		// len = methSet.length;
		// for(i=0 ; i<len ; i++) {
		// 	prob = methSet[i].rate;
		// 	probBuf.push(prob*ratio);
		// 	patBuf.push(0);
		// }
		// this.disributePats(total, probBuf, patBuf, 0);
		len = methSet.length;
		extraPats = 0;
		for(i=0 ; i<len ; i++) {
			if(methSet[i].rate<0) extraPats += Math.abs(methSet[i].rate);
			probBuf.push(methSet[i].rate*ratio);
			patBuf.push(0);
		}
		extraPats = Math.floor(total*extraPats*ratio/100.0);
State.extraPats['ba'][group] = extraPats;		
this.disributePats(total, probBuf, patBuf, extraPats);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам для группы с избытком

	distributeExtraMeth(group) {

		let pathData, extraPats;

		if(State.flNoDist) return; // Отладочный режим

		pathData = baData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		extraPats = 0;
		for(i=0 ; i<len ; i++) {
			if(methSet[i].rate<0) extraPats += Math.abs(methSet[i].rate);
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
		extraPats = Math.floor(total*extraPats/100.0);
State.extraPats['ba'][group] = extraPats;		
this.disributePats(total, probBuf, patBuf, extraPats);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}

/////////////////////////////
// Суммируем для текущией патологии всех пациентов для данной группы
// Необходима в случае, если сумма процентов для всех методов в группе больше 100%

	summGroupPats(group) {

		let totalPatsBuf = State.stPats[0];
		let total = totalPatsBuf[group];
		let methSet = baData[group].meth;
		let summ = 0, len, i, pats;

		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			pats = Math.abs(total*methSet[i].rate/100.0);
			summ += pats;
		}
		return summ;
	}

/////////////////////////////
// Распределяем пациентов по методам для группы с суммой процентов меньше
// 100%
//

	distributeOneCutGroup(currGroup) {

		let i, pats;
		let stPats = State.stPats[1];
// Для группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам не равна 100%
// Суммируем пациентов внутри группы
			let currGrData = baData[currGroup].meth;
			let grSumm = 0, len = currGrData.length, num, ratio;
			if(baData[currGroup].result != -1) pats = baData[currGroup].result;
			else pats = baData[currGroup].pats;
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num;
				grSumm += num;
			}
			grSumm = Math.floor(grSumm);			
			if(grSumm==0) ratio = 1;
			else ratio = pats/grSumm;			
// Перезаписываем новое значение в группу
			grSumm = Math.ceil(grSumm);
			baData[currGroup].pats = grSumm;

// Для каждой группы перераспределяем пациентов по методам
			this.distributeCutMeth(currGroup, ratio);
// Сохраняем реальное кол-во пациентов для расчета нераспределенных 
			State.baFreePatsBuf[currGroup] = baData[currGroup].pats;
// Восстанавливаем затертое начальное распределение 
if(State.baPatsBuf[currGroup])			
			baData[currGroup].pats = State.baPatsBuf[currGroup];
return baData[currGroup].pats;
//return grSumm;
	}

/////////////////////////////
// Распределяем пациентов по методам для группы с суммой процентов больше
// или равно 100%

	distributeOneExtraGroup(currGroup) {

		let i, pats;
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры

			if(State.baPatsBuf[currGroup]!=-1) {
				baData[currGroup].pats = State.baPatsBuf[currGroup]; 
			}

			let currGrData = baData[currGroup].meth;
			let baGrSumm = 0, percentSumm = 0, len = currGrData.length, num;
			
// Вводилось ли число пациентов в группе вручную и делался ли раньше перерасчет на превышение 100%
// Ручной ввод хранится в result!
			if(baData[currGroup].result!=-1 && State.baMultBuf[currGroup]) {
				pats = Math.round(baData[currGroup].result/State.baMultBuf[currGroup]);
			} else if(baData[currGroup].result!=-1 && isNaN(State.baMultBuf[currGroup])) {
				pats = Math.round(baData[currGroup].result);
			}
			else {
				pats = baData[currGroup].pats;
			} 
			for(i=0 ; i<len ; i++) {
				num = Math.abs(currGrData[i].rate*pats/100.0);
				if(!State.flNoDist)	currGrData[i].pats = num; 
				if(currGrData[i].rate>0) baGrSumm += num;
			}
			baData[currGroup].pats = pats;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeExtraMeth(currGroup);
//!!D Сохраняем реальное кол-во пациентов для расчета нераспределенных 
State.baFreePatsBuf[currGroup] = baData[currGroup].pats;
// Сохраняем множитель распределения в буфере
			State.baMultBuf[currGroup] = baGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.baPatsBuf[currGroup] = pats;			
			return pats;
	}

/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

		let i, percentSumm, currGroup, pats, len, meth;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 

		for(currGroup in baData) {
			meth = baData[currGroup].meth;
			len = meth.length;
			percentSumm = 0;
			for(let cnt=0 ; cnt<len ; cnt++) {
				if(meth[cnt].rate>=0) percentSumm += meth[cnt].rate;
			}
			if(percentSumm>=100) pathSumm += this.distributeOneExtraGroup(currGroup);
			else pathSumm += this.distributeOneCutGroup(currGroup);
		}

		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[1] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = baData, currGroup,i, indStr;
		let stPats = [], total = State.pathTotals[1];
		let totalPatsBuf = State.stPats[1];
		const grProbs = [10, 30, 25, 25, 10];
		let grPats = []; 
		for(i=0 ; i<5 ; i++) {
			grPats[i] = total*grProbs[i]/100.0;
			grPats[i+5] = grPats[i]*grProbs[i]/100.0;
		}
		let patSumm = 0, delta, maxQuant = 0, maxInd;
		
		for(i=0 ; i<5 ; i++) {
			if(grPats[i]>maxQuant) {
				maxQuant = grPats[i];
				maxInd = i+1;
			}
		}

// Ступень 1, вне обострений
		Data['st0_0_1'].pats = Math.round(grPats[0]);
		totalPatsBuf['st0_0_1'] = Math.round(grPats[0]);
patSumm += Data['st0_0_1'].pats;
// Ступень 2, вне обострений
		Data['st0_0_2'].pats = Math.round(grPats[1]);
		totalPatsBuf['st0_0_2'] = Math.round(grPats[1]);
patSumm += Data['st0_0_2'].pats;

// Ступень 3, вне обострений
		Data['st0_0_3'].pats = Math.round(grPats[2]);
		totalPatsBuf['st0_0_3'] = Math.round(grPats[2]);
patSumm += Data['st0_0_3'].pats;

// Ступень 4, вне обострений
		Data['st0_0_4'].pats = Math.round(grPats[3]);
		totalPatsBuf['st0_0_4'] = Math.round(grPats[3]);
patSumm += Data['st0_0_4'].pats;

// Ступень 5, вне обострений
		Data['st0_0_5'].pats = Math.round(grPats[4]);
		totalPatsBuf['st0_0_5'] = Math.round(grPats[4]);
patSumm += Data['st0_0_5'].pats;

// Ступень 1, обострения
		Data['st0_0_6'].pats = Math.round(grPats[5]);
		totalPatsBuf['st0_0_6'] = Math.round(grPats[5]);
//patSumm += Data['st0_0_6'].pats;

// Ступень 2, обострения
		Data['st0_0_7'].pats = Math.round(grPats[6]);
		totalPatsBuf['st0_0_7'] = Math.round(grPats[6]);
//patSumm += Data['st0_0_7'].pats;

// Ступень 3, обострения
		Data['st0_0_8'].pats = Math.round(grPats[7]);
		totalPatsBuf['st0_0_8'] = Math.round(grPats[7]);
//patSumm += Data['st0_0_8'].pats;

// Ступень 4, обострения
		Data['st0_0_9'].pats = Math.round(grPats[8]);
		totalPatsBuf['st0_0_9'] = Math.round(grPats[8]);
//patSumm += Data['st0_0_9'].pats;


// Ступень 5, обострения
		Data['st0_0_10'].pats = Math.round(grPats[9]);
		totalPatsBuf['st0_0_10'] = Math.round(grPats[9]);
//patSumm += Data['st0_0_10'].pats;


// Убираем ошибку округлений за счет значения в группе с наибольшим кол-вом пациентов
		delta = patSumm - total;
		if(delta!=0) {
			indStr = 'st0_0_' + maxInd;
			Data[indStr].pats -= delta;
			totalPatsBuf[indStr] -= delta;
		}
let pathSumm = 0;
		for(currGroup in Data) {
// Для каждой группы нужно расчитать и сохранить в State.rlPatsBuf общее количество пациентов,
// т.к. после ручного ввода и перераспределения, исходные значения буду перезаписаны
State.baPatsBuf[currGroup] = Data[currGroup].pats;
//D			State.baPatsBuf[currGroup] = -1;
			Data[currGroup].result = -1;
pathSumm += Data[currGroup].pats;
		}
State.pathSumm[1] = pathSumm;
		this.distributeAllMeth();
	}

}

export default BaModel;