;"use strict";

const methods = {

				0: {
					name: 'Амоксициллин внутрь',
					info: '(амоксициллин)',
					drugs: [5],//
					dose: [3000],
					ds: '',
					st: ''
				},
				1: {
					name: 'Моксифлоксацин',
					info: '(моксифлоксацин)',
					drugs: [20],//
					dose: [400],
					ds: '',
					st: ''
				},
				2: {
					name: 'Левофлоксацин',
					info: '(левофлоксацин)',
					drugs: [17],//
					dose: [1000],
					ds: '',
					st: ''
				},
				3: {
					name: 'Доксициклин',
					info: '(доксициклин)',
					drugs: [11],//
					dose: [200],
					ds: '',
					st: ''
				},
				4: {
					name: 'N-ацетилцистеин',
					info: '(N-ацетилцистеин)',
					drugs: [0],//
					dose: [1800],
					ds: '',
					st: ''
				},
				5: {
					name: 'Амброксол',
					info: '(амброксол)',
					drugs: [2],//
					dose: [90],
					ds: '',
					st: ''
				},
				6: {
					name: 'Амоксициллин/клавуланат',
					info: '(амоксициллин/клавуланат)',
					drugs: [6],//
					dose: [1750],
					ds: '',
					st: ''
				},
				7: {
					name: 'Цефдиторен',
					info: '(цефдиторен)',
					drugs: [24],//
					dose: [800],
					ds: '',
					st: ''
				},
				8: {
					name: 'Амоксициллин/клавуланат в/в или в/м',
					info: '(амоксициллин/клавуланат)',
					drugs: [7],//
					dose: [3000],
					ds: 'ds23.001',
					st: 'st23.004',
					// custDs: 0,
					// custSt: 1,
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				9: {
					name: 'Ампициллин в/в или в/м',
					info: '(ампициллин)',
					drugs: [10],//
					dose: [4000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				10: {
					name: 'Ампициллин/сульбактам в/в или в/м',
					info: '(ампициллин/сульбактам)',
					drugs: [9],//
					dose: [4500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				11: {
					name: 'Амоксициллин/сульбактам в/в или в/м',
					info: '(амоксициллин/сульбактам)',
					drugs: [8],//
					dose: [2250],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				12: {
					name: 'Моксифлоксацин в/в',
					info: '(моксифлоксацин)',
					drugs: [21],//
					dose: [400],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				13: {
					name: 'Левофлоксацин в/в',
					info: '(левофлоксацин)',
					drugs: [16],//
					dose: [1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				14: {
					name: 'Цефотаксим в/в или в/м',
					info: '(цефотаксим)',
					drugs: [25],//
					dose: [4000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				15: {
					name: 'Цефтриаксон в/в или в/м',
					info: '(цефтриаксон)',
					drugs: [28],//
					dose: [4000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				16: {
					name: 'Цефтаролин в/в',
					info: '(цефтаролин)',
					drugs: [26],//
					dose: [1200],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				17: {
					name: 'Эртапенем в/в или в/м',
					info: '(эртапенем)',
					drugs: [27],//
					dose: [1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				18: {
					name: 'Цефтриаксон в/в или в/м + азитромицин в/в',
					info: '(цефтриаксон, азитромицин)',
					drugs: [28,1],//
					dose: [4000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				19: {
					name: 'Цефотаксим в/в или в/м + азитромицин в/в',
					info: '(цефотаксим, азитромицин)',
					drugs: [25,1],//
					dose: [4000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				20: {
					name: 'Цефтаролин в/в + азитромицин в/в',
					info: '(цефтаролин, азитромицин)',
					drugs: [26,1],//
					dose: [1200,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				21: {
					name: 'Амоксициллин/клавуланат в/в или в/м + азитромицин в/в',
					info: '(амоксициллин/клавуланат, азитромицин)',
					drugs: [7,1],//
					dose: [3000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				22: {
					name: 'Эртапенем в/в + азитромицин в/в',
					info: '(эртапенем, азитромицин)',
					drugs: [27,1],//
					dose: [1000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				23: {
					name: 'Левофлоксацин в/в + цефтриаксон',
					info: '(левофлоксацин, цефтриаксон)',
					drugs: [16,28],//
					dose: [1000,4000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				24: {
					name: 'Эноксапарин',
					info: '(эноксапарин)',
					drugs: [30],//
					dose: [40],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				25: {
					name: 'Пиперациллин/тазобактам + ципрофлоксацин',
					info: '(пиперациллин/тазобактам, ципрофлоксацин)',
					drugs: [23,29],//
					dose: [18000,1200],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				26: {
					name: 'Пиперациллин/тазобактам + левофлоксацин в/в',
					info: '(пиперациллин/тазобактам, левофлоксацин)',
					drugs: [23,16],//
					dose: [18000,1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				27: {
					name: 'Меропенем + ципрофлоксацин',
					info: '(меропенем, ципрофлоксацин)',
					drugs: [18,29],//
					dose: [3000,1200],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				28: {
					name: 'Меропенем + левофлоксацин в/в',
					info: '(меропенем, левофлоксацин)',
					drugs: [18,16],//
					dose: [3000,1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				29: {
					name: 'Имипенем в/в + ципрофлоксацин',
					info: '(имипенем, ципрофлоксацин)',
					drugs: [13,29],//
					dose: [4000,1200],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				30: {
					name: 'Имипенем в/в + левофлоксацин в/в',
					info: '(имипенем, левофлоксацин)',
					drugs: [13,16],//
					dose: [4000,1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				31: {
					name: 'Пиперациллин/тазобактам + азитромицин в/в',
					info: '(пиперациллин/тазобактам, азитромицин)',
					drugs: [23,1],//
					dose: [18000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				32: {
					name: 'Пиперациллин/тазобактам + кларитромицин в/в',
					info: '(пиперациллин/тазобактам, кларитромицин)',
					drugs: [23,14],//
					dose: [18000,1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				33: {
					name: 'Меропенем + азитромицин в/в',
					info: '(меропенем, азитромицин)',
					drugs: [18,1],//
					dose: [3000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				34: {
//					name: 'Имипенем/циластатин в/в + азитромицин в/в',
					name: 'Имипенем в/в + азитромицин в/в',
					info: '(имипенем, азитромицин)',
					drugs: [13,1],
					dose: [4000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				35: {
					name: 'Моксифлоксацин + амикацин',
					info: '(моксифлоксацин, амикацин)',
					drugs: [21,4,3],//
					dose: [400,1000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				36: {
					name: 'Левофлоксацин + амикацин',
					info: '(левофлоксацин, амикацин)',
					drugs: [16,4,3],//
					dose: [1000,1000,500],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				37: {
					name: 'Пиперациллин/тазобактам в/в',
					info: '(пиперациллин/тазобактам)',
					drugs: [23],//
					dose: [18000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				38: {
					name: 'Эртапенем в/в',
					info: '(эртапенем)',
					drugs: [27],//
					dose: [1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				39: {
					name: 'Меропенем в/в',
					info: '(меропенем)',
					drugs: [18],//
					dose: [3000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				40: {
					name: 'Имипенем в/в',
					info: '(имипенем)',
					drugs: [13],
					dose: [4000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				41: {
					name: 'Цефотаксим в/в + клиндамицин в/в',
					info: '(цефотаксим, клиндамицин)',
					drugs: [25,15],//
					dose: [4000,1800],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				42: {
					name: 'Цефотаксим в/в + метронидазол в/в',
					info: '(цефотаксим, метронидазол)',
					drugs: [25,19],//
					dose: [4000,1000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				43: {
					name: 'Осельтамивир + цефтаролин',
					info: '(осельтамивир, цефтаролин)',
					drugs: [22,26],//
					dose: [150,1200],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				44: {
					name: 'Занамивир + цефтаролин',
					info: '(занамивир, цефтаролин)',
					drugs: [12,26],//
					dose: [10,1200],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				45: {
					name: 'Амоксициллин/клавуланат в/в',
					info: '(амоксициллин/клавуланат)',
					drugs: [7],//
					dose: [3000],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},
				46:	{
					name: 'Ампициллин/сульбактам в/в',
					info: '(ампициллин/сульбактам)',
					drugs: [9],//
					dose: [2250],
					ds: 'ds23.001',
					st: 'st23.004',
					dsSet: ['ds23.001'],
					stSet: ['st23.004','st12.013']
				},

// ================== BA ===============================================
				47:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация',
					info: '(беклометазон/сальбутамол)',
					drugs: [31],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				48:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация',
					info: '(беклометазон/сальбутамол)',
					drugs: [31],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				49:	{
					name: 'Беклометазон/формотерол фиксированная комбинация',
					info: '(беклометазон/формотерол)',
					drugs: [32],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				// 50:	{// с порошком тиотропия
				// 	name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
				// 	info: '(беклометазон/формотерол, тиотропия бромид)',
				// 	drugs: [32,46],
				// 	dose: [2,0.018],//
				// 	ds: 'ds23.001',
				// 	st: 'st23.005'
				// },
				50:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
					info: '(беклометазон/формотерол, тиотропия бромид)',
					drugs: [32,56],
					dose: [2,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				51:	{
					name: 'Беклометазона дипропионат ДАИ неэкстрамелкодисперсный',
					info: '(беклометазона дипропионат)',
					drugs: [33],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				52:	{
					name: 'Будесонид ДАИ, ДПИ',
					info: '(будесонид)',
					drugs: [34],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				53:	{
					name: 'Будесонид ДАИ, ДПИ',
					info: '(будесонид)',
					drugs: [34],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				54:	{
					name: 'Будесонид/формотерол фиксированая комбинация',
					info: '(будесонид/формотерол)',
					drugs: [36],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				55:	{
					name: 'Будесонид/формотерол фиксированая комбинация',
					info: '(будесонид/формотерол)',
					drugs: [36],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				56:	{
					name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					info: '(будесонид/формотерол, тиотропия бромид)',
					drugs: [36,56],
					dose: [1,1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				// 56:	{
				// 	name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
				// 	info: '(будесонид/формотерол, тиотропия бромид)',
				// 	drugs: [46],
				// 	dose: [0.018],//
				// 	ds: 'ds23.001',
				// 	st: 'st23.005'
				// },
				57:	{
					name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					info: '(будесонид/формотерол, тиотропия бромид)',
					drugs: [36,46],
					dose: [2,0.036],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				58:	{
					name: 'Вилантерол/флутиказон фиксированная комбинация',
					info: '(вилантерол/флутиказон)',
					drugs: [37],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				59:	{
					name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид',
					info: '(вилантерол/флутиказон, тиотропия бромид)',
					drugs: [37,46],
					dose: [1,0.018],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				60:	{
					name: 'Меполизумаб',
					info: '(меполизумаб)',
					drugs: [38],
					dose: [100],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				61:	{
					name: 'Монтелукаст',
					info: '(монтелукаст)',
					drugs: [40],
					dose: [10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				62:	{
					name: 'Небулизированный сальбутамол + метилпреднизолон в/в',
					info: '(сальбутамол, метилпреднизолон)',
					drugs: [44,39],
					dose: [10,100],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				63:	{
					name: 'Небулизированный сальбутамол + небулизированный будесонид',
					info: '(сальбутамол, будесонид)',
					drugs: [44,35],
					dose: [10,3],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				64:	{
					name: 'Небулизированный сальбутамол + преднизолон таб.',
					info: '(сальбутамол, преднизолон)',
					drugs: [44,42],
					dose: [10,40],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				65:	{
					name: 'Омализумаб',
					info: '(омализумаб)',
					drugs: [41],
					dose: [150],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				66:	{
					name: 'Преднизолон',
					info: '(преднизолон)',
					drugs: [42],
//TODO
					dose: [40],
					ds: 'ds23.001',
					st: 'st23.005'
				},
				67:	{
					name: 'Реслизумаб',
					info: '(реслизумаб)',
					drugs: [54],
					dose: [100],
					ds: 'ds36.004',
					st: 'st36.003'
				},
				68:	{
					name: 'Салметерол/флутиказон фиксированная комбинация',
					info: '(салметерол/флутиказон)',
					drugs: [43],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				69:	{
					name: 'Салметерол/флутиказон фиксированная комбинация + тиотропия бромид',
					info: '(салметерол/флутиказон, тиотропия бромид)',
					drugs: [43,56],
					dose: [2,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				// 69:	{
				// 	name: 'Салметерол/флутиказон фиксированная комбинация + тиотропия бромид',
				// 	info: '(салметерол/флутиказон, тиотропия бромид)',
				// 	drugs: [43,46],
				// 	dose: [2,0.018],//
				// 	ds: 'ds23.001',
				// 	st: 'st23.005'
				// },
				70:	{
					name: 'Теофиллин',
					info: '(теофиллин)',
					drugs: [45],
					dose: [600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				71:	{
					name: 'Тиотропия бромид',
					info: '(тиотропия бромид)',
					drugs: [46],
					dose: [0.018],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				72:	{
					name: 'Фенотерол + преднизолон таб.',
					info: '(фенотерол, преднизолон)',
					drugs: [47,42],
					dose: [2,40],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				73:	{
					name: 'Фенотерол + метилпреднизолон в/в',
					info: '(фенотерол, метилпреднизолон)',
					drugs: [47,39],
					dose: [2,250],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				74:	{
					name: 'Фенотерол + небулизированный будесонид',
					info: '(фенотерол, будесонид)',
					drugs: [47,35],
					dose: [2,3],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				75:	{
					name: 'Флутиказона пропионат ДАИ, ДПИ',
					info: '(флутиказона пропионат)',
					drugs: [48],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				76:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + теофиллин',
					info: '(беклометазон/формотерол, теофиллин)',
					drugs: [32,45],
					dose: [2,600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				77:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация + теофиллин',
					info: '(беклометазон/сальбутамол, теофиллин)',
					drugs: [31,45],
					dose: [1,300],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				78:	{
					name: 'Будесонид/формотерол фиксированая комбинация + теофиллин',
					info: '(Будесонид/формотерол, теофиллин)',
					drugs: [36,45],
					dose: [1,300],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				79:	{
					name: 'Будесонид/формотерол фиксированая комбинация + теофиллин',
					info: '(Будесонид/формотерол, теофиллин)',
					drugs: [36,45],
					dose: [4,600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				80:	{
					name: 'Беклометазона дипропионат + теофиллин',
					info: '(беклометазона дипропионат, теофиллин)',
					drugs: [33,45],
					dose: [2,600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				81:	{
					name: 'Будесонид + теофиллин',
					info: '(будесонид, теофиллин)',
					drugs: [34,45],
					dose: [2,600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				82:	{
					name: 'Будесонид + теофиллин',
					info: '(будесонид, теофиллин)',
					drugs: [34,45],
					dose: [1,300],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				83:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + монтелукаст',
					info: '(беклометазон/формотерол, монтелукаст)',
					drugs: [32,40],
					dose: [2,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				84:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация + монтелукаст',
					info: '(беклометазон/сальбутамол, монтелукаст)',
					drugs: [31,40],
					dose: [1,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				85:	{
					name: 'Будесонид/формотерол фиксированая комбинация + монтелукаст',
					info: '(будесонид/формотерол, монтелукаст)',
					drugs: [36,40],
					dose: [4,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				86:	{
					name: 'Будесонид/формотерол фиксированая комбинация + монтелукаст',
					info: '(будесонид/формотерол, монтелукаст)',
					drugs: [36,40],
					dose: [1,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				87:	{
					name: 'Беклометазона дипропионат + монтелукаст',
					info: '(беклометазона дипропионат, монтелукаст)',
					drugs: [33,40],
					dose: [2,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				88:	{
					name: 'Будесонид  + монтелукаст',
					info: '(будесонид, монтелукаст)',
					drugs: [34,40],
					dose: [2,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				89:	{
					name: 'Будесонид  + монтелукаст',
					info: '(будесонид, монтелукаст)',
					drugs: [34,40],
					dose: [1,10],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				90:	{
					name: 'Бенрализумаб',
					info: '(бенрализумаб)',
					drugs: [53],
					dose: [30],//
					ds: 'ds36.004',
					st: 'st36.003'
				},
				91:	{
					name: 'Дупилумаб',
					info: '(дупилумаб)',
					drugs: [55],
					dose: [200],//
					ds: 'ds36.004',
					st: 'st36.003'
				},
				92:	{
					name: 'Салметерол/флутиказон фиксированная комбинация + тиотропия бромид',
					info: '(салметерол/флутиказон, тиотропия бромид)',
					drugs: [43,46],
					dose: [4,0.036],//
					ds: 'ds36.004',
					st: 'st36.003'
				},
				93:	{
					name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид',
					info: '(вилантерол/флутиказон, тиотропия бромид)',
					drugs: [37,46],
					dose: [1,0.036],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				94:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
					info: '(беклометазон/формотерол, тиотропия бромид)',
					drugs: [32,46],
					dose: [2,0.036],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				95:	{
					name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					info: '(будесонид/формотерол, тиотропия бромид)',
					drugs: [36,46],
					dose: [4,0.036],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				96:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация + тиотропия бромид',
					info: '(беклометазон/сальбутамол, тиотропия бромид)',
					drugs: [31,46],
					dose: [4,0.036],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				97:	{
					name: 'Салметерол/флутиказон фиксированная комбинация',
					info: '(салметерол/флутиказон)',
					drugs: [43],
					dose: [4],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				98:	{
					name: 'Будесонид/формотерол фиксированая комбинация',
					info: '(будесонид/формотерол)',
					drugs: [36],
					dose: [4],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				99:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация',
					info: '(беклометазон/сальбутамол)',
					drugs: [31],
					dose: [4],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				100:	{
					name: 'Салметерол/флутиказон фиксированная комбинация + преднизолон',
					info: '(салметерол/флутиказон, преднизолон)',
					drugs: [43,42],
					dose: [4,40],//
					ds: 'ds36.004',
					st: 'st36.003'
				},
				101:	{
					name: 'Вилантерол/флутиказон фиксированная комбинация + преднизолон',
					info: '(вилантерол/флутиказон, преднизолон)',
					drugs: [37,42],
					dose: [1,40],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				102:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + преднизолон',
					info: '(беклометазон/формотерол, преднизолон)',
					drugs: [32,42],
					dose: [4,40],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				103:	{
					name: 'Будесонид/формотерол фиксированая комбинация + преднизолон',
					info: '(будесонид/формотерол, преднизолон)',
					drugs: [36,42],
					dose: [4,40],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				104:	{
					name: 'Беклометазон/сальбутамол фиксированная комбинация + преднизолон',
					info: '(беклометазон/сальбутамол, преднизолон)',
					drugs: [31,42],
					dose: [4,40],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				105:	{
					name: 'Сальбутамол',
					info: '(сальбутамол)',
					drugs: [44],
					dose: [4],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				106:	{
					name: 'Фенотерол',
					info: '(фенотерол)',
					drugs: [47],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				107:	{
					name: 'Ипратропия бромид',
					info: '(ипратропия бромид)',
					drugs: [73],
					dose: [4],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				108:	{
					name: 'Формотерол',
					info: '(формотерол)',
					drugs: [63],
					dose: [0.024],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				109:	{
					name: 'Аклидиния бромид',
					info: '(аклидиния бромид)',
					drugs: [57],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				110:	{
					name: 'Гликопиррония бромид',
					info: '(гликопиррония бромид)',
					drugs: [59],
					dose: [0.05],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				111:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация',
					info: '(гликопиррония бромид/индакатерол)',
					drugs: [60],
					dose: [0.16],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				112:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация',
					info: '(тиотропия бромид/олодатерол)',
					drugs: [61],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				113:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация',
					info: '(умеклидиния бромид/вилантерол)',
					drugs: [62],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				114:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация',
					info: '(аклидиния бромид/формотерол)',
					drugs: [58],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				115:	{
					name: 'Умеклидиния бромид',
					info: '(умеклидиния бромид)',
					drugs: [65],
					dose: [1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				116:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + беклометазон',
					info: '(гликопиррония бромид/индакатерол, беклометазон)',
					drugs: [60,33],
					dose: [0.16,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				117:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + будесонид',
					info: '(гликопиррония бромид/индакатерол, будесонид)',
					drugs: [60,35],
					dose: [0.16,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				118:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + флутиказон',
					info: '(гликопиррония бромид/индакатерол, флутиказон)',
					drugs: [60,49],
					dose: [0.16,1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				119:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация + беклометазон',
					info: '(тиотропия бромид/олодатерол, беклометазон)',
					drugs: [61,33],
					dose: [2,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				120:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация + будесонид',
					info: '(тиотропия бромид/олодатерол, будесонид)',
					drugs: [61,36],
					dose: [2,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				121:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация + флутиказон',
					info: '(тиотропия бромид/олодатерол, флутиказон)',
					drugs: [61,49],
					dose: [2,1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				122:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + беклометазон',
					info: '(умеклидиния бромид/вилантерол, беклометазон)',
					drugs: [62,33],
					dose: [1,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				123:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + будесонид',
					info: '(умеклидиния бромид/вилантерол, будесонид)',
					drugs: [62,36],
					dose: [1,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				124:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + флутиказон',
					info: '(умеклидиния бромид/вилантерол, флутиказон)',
					drugs: [62,49],
					dose: [1,1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				125:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация + беклометазон',
					info: '(аклидиния бромид/формотерол, беклометазон)',
					drugs: [58,33],
					dose: [2,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				126:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация + будесонид',
					info: '(аклидиния бромид/формотерол, будесонид)',
					drugs: [58,36],
					dose: [2,2],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				127:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация + флутиказон',
					info: '(аклидиния бромид/формотерол, флутиказон)',
					drugs: [58,49],
					dose: [2,1],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				128:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
					info: '(беклометазон/формотерол, тиотропия бромид)',
					// drugs: [32,56],
					// dose: [2,1],//
					drugs: [32,46],
					dose: [2,0.018],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				129:	{
					name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					info: '(будесонид/формотерол, тиотропия бромид)',
					// drugs: [36,56],
					// dose: [2,1],//
					drugs: [36,46],
					dose: [2,0.018],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				130:	{
					name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид',
					info: '(вилантерол/флутиказон, тиотропия бромид)',
					// drugs: [52,56],
					// dose: [1,1],//
					drugs: [52,46],
					dose: [1,0.018],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				131:	{
					name: 'Беклометазон/формотерол + рофлумиласт',
					info: '(беклометазон/формотерол, рофлумиласт)',
					drugs: [32,66],
					dose: [2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				132:	{
					name: 'Будесонид/формотерол + рофлумиласт',
					info: '(будесонид/формотерол, рофлумиласт)',
					drugs: [36,66],
					dose: [2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				133:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + беклометазон + рофлумиласт',
					info: '(гликопиррония бромид/индакатерол, беклометазон, рофлумиласт)',
					drugs: [60,33,66],
					dose: [0.16,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				134:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + будесонид + рофлумиласт',
					info: '(гликопиррония бромид/индакатерол, будесонид, рофлумиласт)',
					drugs: [60,35,66],
					dose: [0.16,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				135:	{
					name: 'Гликопиррония бромид/индакатерол фиксированная комбинация + флутиказон + рофлумиласт',
					info: '(гликопиррония бромид/индакатерол, флутиказон, рофлумиласт)',
					drugs: [60,49,66],
					dose: [0.16,1,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				136:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация + беклометазон + рофлумиласт',
					info: '(тиотропия бромид/олодатерол, беклометазон, рофлумиласт)',
					drugs: [61,33,66],
					dose: [2,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				137:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация + будесонид + рофлумиласт',
					info: '(тиотропия бромид/олодатерол, будесонид, рофлумиласт)',
					drugs: [61,35,66],
					dose: [2,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				138:	{
					name: 'Тиотропия бромид/олодатерол фиксированная комбинация + флутиказон + рофлумиласт',
					info: '(тиотропия бромид/олодатерол, флутиказон, рофлумиласт)',
					drugs: [61,49,66],
					dose: [2,1,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				139:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + беклометазон + рофлумиласт',
					info: '(умеклидиния бромид/вилантерол, беклометазон, рофлумиласт)',
					drugs: [62,33,66],
					dose: [1,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				140:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + будесонид + рофлумиласт',
					info: '(умеклидиния бромид/вилантерол, будесонид, рофлумиласт)',
					drugs: [62,35,66],
					dose: [1,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				141:	{
					name: 'Умеклидиния бромид/вилантерол фиксированная комбинация + флутиказон + рофлумиласт',
					info: '(умеклидиния бромид/вилантерол, флутиказон, рофлумиласт)',
					drugs: [62,49,66],
					dose: [1,1,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				142:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация + беклометазон + рофлумиласт',
					info: '(аклидиния бромид/формотерол, беклометазон, рофлумиласт)',
					drugs: [58,33,66],
					dose: [2,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				143:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация + будесонид + рофлумиласт',
					info: '(аклидиния бромид/формотерол, будесонид, рофлумиласт)',
					drugs: [58,35,66],
					dose: [2,2,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				144:	{
					name: 'Аклидиния бромид/формотерол фиксированная комбинация + флутиказон + рофлумиласт',
					info: '(аклидиния бромид/формотерол, флутиказон, рофлумиласт)',
					drugs: [58,49,66],
					dose: [2,1,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				145:	{
					name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид + рофлумиласт',
					info: '(беклометазон/формотерол, тиотропия бромид, рофлумиласт)',
					// drugs: [32,56,66],
					// dose: [2,1,0.5],//
					drugs: [32,46,66],
					dose: [2,0.018,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				146:	{
					name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид + рофлумиласт',
					info: '(будесонид/формотерол, тиотропия бромид, рофлумиласт)',
					// drugs: [36,56,66],
					// dose: [2,1,0.5],//
					drugs: [36,46,66],
					dose: [2,0.018,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				147:	{
					name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид + рофлумиласт',
					info: '(вилантерол/флутиказон, тиотропия бромид, рофлумиласт)',
					// drugs: [52,56,66],
					// dose: [1,1,0.5],//
					drugs: [52,46,66],
					dose: [1,0.018,0.5],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				148:	{
					name: 'Беклометазон/формотерол + теофиллин',
					info: '(беклометазон/формотерол, теофиллин)',
					drugs: [32,45],
					dose: [2,600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				149:	{
					name: 'Будесонид/формотерол + теофиллин',
					info: '(будесонид/формотерол, теофиллин)',
					drugs: [36,45],
					dose: [2,600],//
					ds: 'ds23.001',
					st: 'st23.005'
				},
				150: {
					name: 'N-ацетилцистеин',
					info: '(N-ацетилцистеин)',
					drugs: [0],//
					dose: [600],
					ds: '',
					st: ''
				},
				151:	{
					name: 'Амброксол',
					info: '(амброксол)',
					drugs: [67],
					dose: [180],//
					ds: '',
					st: ''
				},
				152:	{
					name: 'Карбоцистеин',
					info: '(карбоцистеин)',
					drugs: [68],
					dose: [2250],//
					ds: '',
					st: ''
				},
				153:	{
					name: 'Эрдостеин',
					info: '(эрдостеин)',
					drugs: [69],
					dose: [900],//
					ds: '',
					st: ''
				},
				154: {
					name: 'Амоксициллин/клавуланат',
					info: '(амоксициллин/клавуланат)',
					drugs: [72],//
					dose: [2000],
					ds: '',
					st: '',
				},
				155: {
					name: 'Цефиксим',
					info: '(цефиксим)',
					drugs: [71],//
					dose: [400],
					ds: '',
					st: '',
				},
				156: {
					name: 'Левофлоксацин',
					info: '(левофлоксацин)',
					drugs: [17],//
					dose: [1000],
					ds: '',
					st: '',
				},
				157: {
					name: 'Моксифлоксацин',
					info: '(моксифлоксацин)',
					drugs: [20],//
					dose: [400],
					ds: '',
					st: '',
				},
				158:	{
					name: 'Фенотерол',
					info: '(фенотерол)',
					drugs: [70],
					dose: [2],//
					ds: 'ds23.001',
					st: 'st23.005'
				}

	}

export default methods;	