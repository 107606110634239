
;"use strict";

import State from './state';
import drugData from './drugdata';

class ChanPrinter {

	constructor() {

		this.clickCounter = 0;
        $('#chan-mess-but').on(State.startEvent, (e)=>this.hideMessage(e));
        $('#d-print-butt').on(State.startEvent, (e)=>this.dataPrint(e));

	}

//******************************************

	render(chan) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, typeStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind,
			chanName = '', doseMg, doseStr, subTotVal, averStr, averCost, ksgTable,
			packRatio, num, str, repMonthStr;	
		const len = State.drugOrd.length;

		State.calc.resetDataBufs();
		State.calc.calcFullBudget(chan);

		packRatio = State.repMonthNum/12.0;
		num = State.repMonthNum % 10;
		str = ' месяцев';
		if(num<5 && num>1) str = ' месяца';
		if(num==1) str = ' месяц';
		if(State.repMonthNum>5 && State.repMonthNum<21) str = ' месяцев';
		repMonthStr = '' + State.repMonthNum + str;

		if(chan=='') { // Строим статистику по КСГ
			ksgTable = 
			`
			<div class="print-subtitle">
	            <div class="print-rep-months">Заявка на <span id="chan-months">${repMonthStr}</span></div>
				Статистика случаев по значению КСГ
			</div>
			<table id="prt-ksg-table">
                <tr id="prt-ksg-line0">
                    <td style="width:70px">st12.013:</td>
                    <td style="width:120px">${Math.ceil(State.ksgBuf['st12.013']*packRatio)}</td>
                    <td style="width:70px">ds23.001:</td>
                    <td style="width:120px">${Math.ceil(State.ksgBuf['ds23.001']*packRatio)}</td>
                </tr>
                <tr id="prt-ksg-line1">
                    <td>st23.004:</td>
                    <td>${Math.ceil(State.ksgBuf['st23.004']*packRatio)}</td>
                </tr>
                <tr id="prt-ksg-line2">
                    <td>st23.005:</td>
                    <td>${Math.ceil(State.ksgBuf['st23.005']*packRatio)}</td>
                </tr>
            </table>`;
			tableCode = ksgTable;		
			tableCode += '<br>'
			$('#chan-table-tot').html(tableCode);
			return;
		}
		averStr = '';
        if(chan=='oms') { 
        	chanName = 'ОМС КС'; 
        	drugBuf = State.omsBuf; 
        	averCost = State.calc.padDigits(Math.round(State.averCostKs));
        	averStr = `<div class="print-subtotal">
       		Средняя стоимость случая: ${averCost} руб.
       		</div>`;
        }
        if(chan=='omsd') { 
        	chanName = 'ОМС ДС'; 
        	drugBuf = State.omsdBuf; 
        	averCost = State.calc.padDigits(Math.round(State.averCostDs));
        	averStr = `<div class="print-subtotal">
       		Средняя стоимость случая: ${averCost} руб.
       		</div>`;
        }
        if(chan=='rlo') { chanName = 'РЛО'; drugBuf = State.rloBuf; }
        if(chan=='onls') { chanName = 'ОНЛС'; drugBuf = State.onlsBuf; }
//        subTotVal = State.calc.padDigits(Math.floor(State.chanBudgets[chan]));
       	// subTotal = `<div class="print-subtotal" style="margin-bottom:0">
       	// 	Суммарные расходы для канала ${chanName}: ${subTotVal} руб.
       	// </div>`;

       	let tabFooter = `</tbody>
            </table>
			`;

        let insert = 
                	`<div class="print-title" style="margin-bottom:6px">
						${chanName}
					</div>
		            <table class="prt-drug-table" align="center">
		                <tbody>
		                	<tr class="table-header">
		                        <td width="250px">Препарат</td>
		                        <td>Кол-во уп.</td>
		                        <td>Кол-во мг.</td>
		                        <td>Затраты, руб.</td>
		                    </tr>`;                
		tableCode = $('#chan-table-tot').html();
		tableCode += insert;
subTotVal = 0 ; 		
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
nOfPacks = Math.ceil(drugBuf[ind].packs*packRatio);
			if(nOfPacks==0) continue;
			currDrug = drugData[ind];
 			result = drugBuf[ind].summ;
			doseMg = drugBuf[ind].mg;
doseMg = currDrug.dose*currDrug.set*nOfPacks;
result = nOfPacks*currDrug.price;
nOfPacks = State.calc.padDigits(nOfPacks);	
			doseStr = State.calc.padDigits(Math.floor(doseMg));
 			result = Math.floor(result);
subTotVal += result; 			
 			result = State.calc.padDigits(result);
			priceStr = State.calc.padFloat(currDrug.price);
			if(currDrug.type=='a') typeStr = '[Антиб.]'; 
			if(currDrug.type=='v') typeStr = '[Прот.вир.]'; 
			if(currDrug.type=='m') typeStr = '[Муколит.]';
			if(currDrug.type=='ak') typeStr = '[Анти.коаг.]';
			if(currDrug.type=='o') typeStr = '[Другое]';
			lineTemplate = 
                	`<tr>
                        <td>
                        	${currDrug.name}
                        	<span class="prt-drug-type">${typeStr}</span>
                        </td>
                        <td>${nOfPacks}</td>
                        <td>${doseStr}</td>
                        <td>${result}</td>
                    </tr>`;
            tableCode += lineTemplate;    
		}
subTotVal = State.calc.padDigits(Math.floor(subTotVal));
       	subTotal = `<div class="print-subtotal" style="margin-bottom:0">
       		Суммарные расходы для канала ${chanName}: ${subTotVal} руб.
       	</div>`;
		tableCode += tabFooter;
		tableCode += subTotal;
		tableCode += averStr;		
		tableCode += '<br>'
		$('#chan-table-tot').html(tableCode);
	}

//******************************************

	renderTables(){

		this.render('');
		this.render('oms');
		this.render('omsd');
		this.render('rlo');
		this.render('onls');
	}

//******************************************

	hideMessage(e){

	    var type;

	    e.preventDefault();
        type = e.type;
        $('#chan-print-message').css('display','none');
        State.flModal = 0;
    }

//******************************************

	    dataPrint(e){

	        var type, messTxt;

	        $('#chan-print-cont').css('display','block');
	        this.renderTables();
	        $('.maincont').css('display','none');
	        $('#chan-print-but').css('display','none');
	        $('body').css('overflow','visible');
	            State.flModal = 1;

	        setTimeout(function(){
	            window.focus();
	//            alert('Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.');
	            window.print();

	            if(State.flIpad) {
	                messTxt = 'Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.';
	                messTxt += '<br>Если ваш планшет не нашел доступный принтер для печати, воспользуйтесь пиктограммой в виде прямоугольника в верхней строке браузера<br><br><img src="img/pict.png"><br><br>для отправки результатов по почте или преобразования в другой удобный формат';
	            } else {
	                messTxt = 'Для возвращения к калькулятору после печати, выполните двойной клик на экране с таблицей.';
	            }
	            $('#chan-prt-mess-text').html(messTxt);
	            setTimeout(function(){
	                    $('#chan-print-message').css('display','block');
	                }, 1000);
	        },1000);
	        this.clickCounter = 0;
	        $('body').bind(State.startEvent, (e)=>this.closePrintWindow(e));

	        return true;
	    }

//******************************************

	closePrintWindow(e){

	        var type;

	        if(State.flModal) return;
	        if(e.target.id=='chan-mess-but') return;
	        type = e.type;
	        this.clickCounter++;
	        setTimeout(()=>{
	            this.clickCounter = 0;
	        },400);
	        if(this.clickCounter<2) return;
	        
	        $('body').unbind();
//	        $('body').css('overflow','hidden');
//	        $('.maincont').css('display','block');
	        $('#distcont').css('display','block');
	        $('#chan-print-cont').css('display','none');
	        e.preventDefault();
	    }
	}


//******************************************

export default ChanPrinter;