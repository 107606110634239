;"use strict";

import State from './state';
import drugData from './drugdata';

class PriceTable {

	constructor() {
		this.editMode = 'text';
		this.currLineNum = -1;
		$('#int-sw-butt').on(State.startEvent, (e) => this.switchAddon(e));
	}

//////////////////////////////

	render() {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, totPriceStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, listStr, ind, injStr,
			pathName = '', totalPrice, priceText;	
		const len = State.drugOrd.length;
		const holder = `<div class='v-holder'>&nbsp<br></div>`;

        // let insert = 
        //         `<div class="scr1-table-head" id="scr1-table-hd">
        //             <div class="scr1-head-col" id="scr1-hcol1">Препарат</div>
        //             <div class="scr1-head-col" id="scr1-hcol2">Наличие в ЖНВЛП</div>
        //             <div class="scr1-head-col" id="scr1-hcol3">Стоимость, руб</div>
        //             <div class="scr1-head-col" id="scr1-hcol4">
        //             </div>
        //         </div>`;                

//		tableCode = insert;
		tableCode = '';
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			currDrug = drugData[ind];
			if(color==0) colClass = 'grey'; else colClass = '';
			if(currDrug.inj==0) injStr = 'перор' ; else injStr = 'инъекц';
			if(currDrug.oth==1) injStr = 'другой';
			if(currDrug.list==0) listStr = 'нет' ; else listStr = 'есть';
			priceStr = currDrug.price;
			priceText = State.calc.padFloat(currDrug.price);
			lineTemplate = 
                `<div class="scr1-line" id="scr1-line${ind}">
                    <div class="scr1-col t1-col1 ${colClass}" id="col1-line${ind}">${currDrug.name}</div>
                    <div class="scr1-col t1-col5 ${colClass}" id="col5-line${ind}">${injStr}</div>
                    <div class="scr1-col t1-col2 ${colClass}" id="col2-line${ind}">${listStr}</div>
                    <div class="scr1-col t1-col3 ${colClass}" id="col3-line${ind}">
                    	<div class="price-edit" id="price-edit${ind}">
                    		<input class="price-input" id="price-input${ind}" type="text" value="${priceStr}">
                    	</div>
						<div class="price-text" id="price-text${ind}">
		                    ${priceText}
		                </div>    
                    </div>
                    <div class="scr1-col t1-col4 ${colClass}" id="col4-line${ind}">
                        <div class="edit-butt" id="edit-butt${ind}">Изменить</div>
                    </div>
                </div>`;
            tableCode += lineTemplate;    
			color++; if (color>1) color = 0;	
		}
		tableCode += holder;
		tableCode += '<br><br><br>'
		$('#scr1-insert').html(tableCode);
		$('#reg-interest').text(State.currRegAdd+'%');
		$('.edit-butt').on(State.startEvent, (e) => this.showEdit(e));
		$('.price-input').on('keypress focusout', (e) => this.editNumber(e));
	}

///////////////////////////////////////////////


	switchAddon(e) {

		if(State.flRegPrice==0) {
			State.flRegPrice = 1;
			$('#int-sw-butt').addClass('int-sw-active');
			$('#int-sw-butt').removeClass('int-sw-inactive');
			$('#int-sw-caption').addClass('gold-text');
			$('#int-sw-caption').removeClass('grey-text');
		} else {
			State.flRegPrice = 0;
			$('#int-sw-butt').addClass('int-sw-inactive');
			$('#int-sw-butt').removeClass('int-sw-active');
			$('#int-sw-caption').addClass('grey-text');
			$('#int-sw-caption').removeClass('gold-text');
		}
		State.drugStore.fillDrugData();
		this.render();
		State.methPanel.displayResult();
	}

///////////////////////////////////////////////

	completeEdit() {


		let val;
		const num = this.currLineNum;

		if(this.editMode!='edit') return;
		let	price = $('#price-input'+num).val();
		this.editMode = 'count';
		val = parseFloat(price.replace(/,/g, '.'));
		val = Math.floor(val*100)/100;
		drugData[num].price = val;
		State.drugStore.saveData(num);
		$('#price-input'+num).val(val);
		$('#price-edit'+num).hide();
		$('#price-text'+num).show();
		$('#price-text'+num).text(State.calc.padFloat(val));
		$('#edit-butt'+num).text('Изменить');
		State.methPanel.displayResult();
	}


///////////////////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.completeEdit();
				return;
			}
			if((e.charCode<48 || e.charCode>57) && e.charCode!=46 
				&& e.charCode!=44) { 
				e.preventDefault();
				return;
			}
			// Intercept second delimeter
			let num = e.target.id;
			num = num.slice(11);			
			let txt = $('#price-input'+num).val();
			let ind = txt.indexOf('.');
			let ind2 = txt.indexOf(',');
			if(ind>=0 || ind2>=0){
				if(e.charCode==46 || e.charCode==44) {
					e.preventDefault();
					return;
				}
			}

		}
		if(e.type=='focusout') {
				this.completeEdit();
		}	
	}

///////////////////////////////////////////////

	showEdit(e) {

		let num = e.target.id,
			str = num.slice(0,9);

		if(str!='edit-butt') return;
		num = num.slice(9);
		if(this.editMode=='edit') {
			if(this.currLineNum==num) return;
			this.completeEdit();
		}

		setTimeout(() => {
			this.editMode = 'edit';
			this.currLineNum = num;
			$('#price-text'+num).hide();
			$('#price-edit'+num).show();
			$('#price-edit'+num).val(drugData[num].price);
			$('#edit-butt'+num).text('Сохранить');
		},50);
		setTimeout(() => {
			$('#price-input'+num).focus();
			let len = $('#price-edit'+num).val().length;
//console.log(len);		
			let inp = document.getElementById('price-input'+num);
			inp.setSelectionRange(0,len);
		},100);

	}

///////////////////////////////////

} 

export default PriceTable;