;"use strict";

import State from './state';
import drugData from './drugdata';

class ChanTable {

	constructor() {
		$('#scr6-xls-butt').on(State.startEvent, (e)=>this.renderXls(e));
	}

//////////////////////////////

	render(chan) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, packRatio, repMonthStr, repMonthNum,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind, str, num,
			chanName = '', doseMg, doseStr, subTotVal, averStr, averCost, ksgTable;	
		const len = State.drugOrd.length;
		const holder = `<div class='v-holder'>&nbsp<br></div>`;

		State.calc.resetDataBufs();
		State.calc.calcFullBudget(chan);
		packRatio = State.repMonthNum/12.0;
		num = State.repMonthNum % 10;
		str = ' месяцев';
		if(num<5 && num>1) str = ' месяца';
		if(num==1) str = ' месяц';
		if(State.repMonthNum>5 && State.repMonthNum<21) str = ' месяцев';
		repMonthStr = '' + State.repMonthNum + str;

		if(chan=='') { // Строим статистику по КСГ
			ksgTable = 
			`<br>
			<div class="ksg-stat-header">
				Статистика случаев по значению КСГ
	            <div class="ksg-rep-months">Заявка на <span id="chan-months">${repMonthStr}</span></div>
			</div>
			<div class="ksg-stat-line">
				<div class="ksg-stat-value">
					st12.013: <span id="st12.013" class="ksg-pat-num">
					${Math.ceil(State.ksgBuf['st12.013']*packRatio)}
					</span>
				</div>
				<div class="ksg-stat-value">
					ds23.001: <span id="ds23.001" class="ksg-pat-num">
					${Math.ceil(State.ksgBuf['ds23.001']*packRatio)}
					</span>
				</div>
			</div>
			<div class="ksg-stat-line">
				<div class="ksg-stat-value">
					st23.004: <span id="st23.004" class="ksg-pat-num">
					${Math.ceil(State.ksgBuf['st23.004']*packRatio)}
					</span>
				</div>
			</div>
			<div class="ksg-stat-line">
				<div class="ksg-stat-value">
					st23.005: <span id="st23.005" class="ksg-pat-num">
					${Math.ceil(State.ksgBuf['st23.005']*packRatio)}
					</span>
				</div>
			</div>
			`;
			tableCode = $('#scr6-insert').html();
			tableCode += ksgTable;		
			tableCode += '<br>'
			$('#scr6-insert').html(tableCode);
			return;
		}

		averStr = '';
        if(chan=='oms') { 
        	chanName = 'ОМС КС'; 
        	drugBuf = State.omsBuf; 
        	averCost = State.calc.padDigits(Math.round(State.averCostKs));
        	averStr = `<div class="scr3-subtotal">
       		Средняя стоимость случая: <span id="avercost-${chan}">${averCost}</span> руб.
       		</div>`;
        }
        if(chan=='omsd') { 
        	chanName = 'ОМС ДС'; 
        	drugBuf = State.omsdBuf; 
        	averCost = State.calc.padDigits(Math.round(State.averCostDs));
        	averStr = `<div class="scr3-subtotal">
       		Средняя стоимость случая: <span id="avercost-${chan}">${averCost}</span> руб.
       		</div>`;
        }
        if(chan=='rlo') { chanName = 'РЛО'; drugBuf = State.rloBuf; }
        if(chan=='onls') { chanName = 'ОНЛС'; drugBuf = State.onlsBuf; 
    }
//??EXP        subTotVal = State.calc.padDigits(Math.floor(State.chanBudgets[chan]));

        let insert = 
                `<div class="scr3-delim" id="delim-${chan}">${chanName}</div>
                <div class="scr3-table-head" id="scr6-table-hd">
                    <div class="scr3-head-col" id="scr6-hcol1">Препарат (МНН)</div>
                    <div class="scr3-head-col" id="scr6-hcol2"> Количество<br>упаковок</div>
                    <div class="scr3-head-col" id="scr6-hcol3">Количество мг<br>суммарно</div>
                    <div class="scr3-head-col" id="scr6-hcol4">Расчет затрат<br>руб.</div>
                </div>`;                

		tableCode = $('#scr6-insert').html();
		tableCode += insert;
subTotVal = 0 ; 		
//console.log(drugBuf);
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			if(color==0) colClass = 'grey'; else colClass = '';
nOfPacks = Math.ceil(drugBuf[ind].packs*packRatio);
//			nOfPacks = State.calc.padDigits(drugBuf[ind].packs);
			if(nOfPacks==0) continue;
			currDrug = drugData[ind];
 			result = drugBuf[ind].summ;
//console.log(result);
			doseMg = drugBuf[ind].mg;
doseMg = currDrug.dose*currDrug.set*nOfPacks;
result = nOfPacks*currDrug.price;
			doseStr = State.calc.padDigits(Math.floor(doseMg));
 			result = Math.floor(result);
subTotVal += result; 			
 			result = State.calc.padDigits(result);
			priceStr = State.calc.padFloat(currDrug.price);
nOfPacks = State.calc.padDigits(nOfPacks);
			lineTemplate = 
                `<div class="scr3-line" id="scr6-line${cnt}">
                    <div class="scr3-col t-col1 ${colClass}" id="ccol1-line${cnt}">${currDrug.name}</div>
                    <div class="scr3-col t-col2 ${colClass}" id="ccol2-line${cnt}">${nOfPacks}</div>
                    <div class="scr3-col t-col3 ${colClass}" id="ccol3-line${cnt}">${doseStr}</div>
                    <div class="scr3-col t-col4 ${colClass}" id="ccol4-line${cnt}">${result}</div>
                </div>`;
            tableCode += lineTemplate;    
			color++; if (color>1) color = 0;	
		}
subTotVal = State.calc.padDigits(Math.floor(subTotVal));
       	subTotal = `<div class="scr3-subtotal">
       		Суммарные расходы для канала ${chanName}: <span id="subtotal-${chan}">${subTotVal}</span> руб.
       	</div>`;
tableCode += subTotal;
tableCode += averStr;		
		tableCode += holder;
		tableCode += '<br>'
		$('#scr6-insert').html(tableCode);
	}

//////////////////////////////

	renderKsgXls() {

		let packRatio = State.repMonthNum/12.0;

		let tableCode = `
	   <Row ss:AutoFitHeight="0" ss:Height="27">
	    <Cell ss:StyleID="s55"><Data ss:Type="String">st12.013: </Data></Cell>
	    <Cell ss:StyleID="s46"><Data ss:Type="Number">${Math.ceil(State.ksgBuf['st12.013']*packRatio)}</Data></Cell>
	    <Cell ss:StyleID="s57"><Data ss:Type="String">ds23.001: </Data></Cell>
	    <Cell ss:MergeAcross="1" ss:StyleID="m73028280"><Data ss:Type="Number">${Math.ceil(State.ksgBuf['ds23.001']*packRatio)}</Data></Cell>
	   </Row>
	   <Row ss:AutoFitHeight="0" ss:Height="27">
	    <Cell ss:StyleID="s55"><Data ss:Type="String">st23.004: </Data></Cell>
	    <Cell ss:StyleID="s45"><Data ss:Type="Number">${State.ksgBuf['st23.004']*packRatio}</Data></Cell>
	   </Row>
	   <Row ss:AutoFitHeight="0" ss:Height="27">
	    <Cell ss:StyleID="s55"><Data ss:Type="String">st23.005: </Data></Cell>
	    <Cell ss:StyleID="s45"><Data ss:Type="Number">${State.ksgBuf['st23.005']*packRatio}</Data></Cell>
	   </Row>
		`;
		State.calc.resetDataBufs();
		State.calc.calcFullBudget('');
		return tableCode;
	}

//////////////////////////////

	renderChanelXls(chan) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, typeStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind,
			chanName = '', doseMg, doseStr, subTotVal, averStr, averCost, ksgTable,
			packRatio;	
		const len = State.drugOrd.length;
		const delimLine = `<Row ss:AutoFitHeight="0" ss:Height="9.9375">
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			   </Row>
			`;

		State.calc.resetDataBufs();
		State.calc.calcFullBudget(chan);
		packRatio = State.repMonthNum/12.0;

		averStr = '';
        if(chan=='oms') { 
        	chanName = 'ОМС КС'; 
        	drugBuf = State.omsBuf; 
        	averCost = Math.round(State.averCostKs);
        }
        if(chan=='omsd') { 
        	chanName = 'ОМС ДС'; 
        	drugBuf = State.omsdBuf; 
        	averCost = Math.round(State.averCostDs);
        }
        if(chan=='rlo') { chanName = 'РЛО'; drugBuf = State.rloBuf; }
        if(chan=='onls') { chanName = 'ОНЛС'; drugBuf = State.onlsBuf; }
        subTotVal = Math.floor(State.chanBudgets[chan]);

        let insert = // Header of table (names of columns)
                `<Row ss:AutoFitHeight="0" ss:Height="9.9375">
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
			   </Row>
			   <Row ss:AutoFitHeight="0" ss:Height="27">
			    <Cell ss:MergeAcross="4" ss:StyleID="s67"><Data ss:Type="String">${chanName}</Data></Cell>
			   </Row>
			   <Row ss:AutoFitHeight="0" ss:Height="21.75">
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Тип препарата</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Препарат (МНН)</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Количество упаковок</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Количество мг суммарно</Data></Cell>
			    <Cell ss:StyleID="s23"><Data ss:Type="String">Расчет затрат руб.</Data></Cell>
			   </Row>
				`;                

		tableCode = insert;
subTotVal = 0 ; 		
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			nOfPacks = drugBuf[ind].packs;	
			if(nOfPacks==0) continue;
nOfPacks = Math.ceil(nOfPacks*packRatio);
			currDrug = drugData[ind];
 			result = drugBuf[ind].summ;
			doseMg = drugBuf[ind].mg;
doseMg = currDrug.dose*currDrug.set*nOfPacks;
result = nOfPacks*currDrug.price;
			doseStr = Math.floor(doseMg);
 			result = Math.floor(result);
subTotVal += result; 			
			priceStr = currDrug.price;
			typeStr = '';
			if(currDrug.type=='a') typeStr = 'Антиб.'; 
			if(currDrug.type=='v') typeStr = 'Прот.вир.'; 
			if(currDrug.type=='m') typeStr = 'Муколит.'; 
			if(currDrug.type=='ak') typeStr = 'Ант.коаг.'; 
			if(currDrug.type=='o') typeStr = 'Другое'; 
			lineTemplate = 
                `<Row ss:AutoFitHeight="0" ss:Height="30">
				    <Cell ss:StyleID="s24"><Data ss:Type="String">${typeStr}</Data></Cell>
				    <Cell ss:StyleID="s25"><Data ss:Type="String">${currDrug.name}</Data></Cell>
				    <Cell ss:StyleID="s24"><Data ss:Type="Number">${nOfPacks}</Data></Cell>
				    <Cell ss:StyleID="s26"><Data ss:Type="Number">${doseStr}</Data></Cell>
				    <Cell ss:StyleID="s26"><Data ss:Type="Number">${result}</Data></Cell>
				   </Row>
				`;
            tableCode += lineTemplate;    
		}
subTotVal = Math.floor(subTotVal);
		tableCode += delimLine;
		subTotal = `<Row ss:AutoFitHeight="0" ss:Height="18">
			    <Cell ss:StyleID="s27"><Data ss:Type="String"></Data></Cell>
			    <Cell ss:StyleID="s27"/>
			    <Cell ss:StyleID="s28"><Data ss:Type="String">Суммарные расходы для канала ${chanName}: </Data></Cell>
			    <Cell ss:StyleID="s27"><Data ss:Type="Number">${subTotVal}</Data></Cell>
			    <Cell ss:StyleID="s29"><Data ss:Type="String"> рублей</Data></Cell>
			   </Row>
			`;
		tableCode += subTotal;
		if(chan=='oms' || chan=='omsd') {
			averStr = `<Row ss:AutoFitHeight="0" ss:Height="18">
				    <Cell ss:StyleID="s27"><Data ss:Type="String"></Data></Cell>
				    <Cell ss:StyleID="s27"/>
				    <Cell ss:StyleID="s28"><Data ss:Type="String">Средняя стоимость случая: </Data></Cell>
				    <Cell ss:StyleID="s27"><Data ss:Type="Number">${averCost}</Data></Cell>
				    <Cell ss:StyleID="s29"><Data ss:Type="String"> рублей</Data></Cell>
				   </Row>
				`;
			tableCode += averStr;
		}		
		return tableCode;
	}

//////////////////////////////

	renderXls() {

		let num = State.repMonthNum % 10;
		let str = ' месяцев.';
		
		if(num<5 && num>1) str = ' месяца.';
		if(num==1) str = ' месяц.';
		if(State.repMonthNum>5 && State.repMonthNum<21) str = ' месяцев.';
		let repMonthStr = '' + State.repMonthNum + str;

		let tableStr;
		let tabHeader = `<?xml version="1.0"?>
		<?mso-application progid="Excel.Sheet"?>
		<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
		 xmlns:o="urn:schemas-microsoft-com:office:office"
		 xmlns:x="urn:schemas-microsoft-com:office:excel"
		 xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
		 xmlns:html="http://www.w3.org/TR/REC-html40">
		 <DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
		  <Author>Microsoft Corporation</Author>
		  <LastAuthor>Alex</LastAuthor>
		  <LastPrinted>2019-10-06T10:23:28Z</LastPrinted>
		  <Created>1996-10-08T23:32:33Z</Created>
		  <LastSaved>2019-10-06T10:24:53Z</LastSaved>
		  <Version>11.5606</Version>
		 </DocumentProperties>
		 <ExcelWorkbook xmlns="urn:schemas-microsoft-com:office:excel">
		  <WindowHeight>7320</WindowHeight>
		  <WindowWidth>9720</WindowWidth>
		  <WindowTopX>120</WindowTopX>
		  <WindowTopY>120</WindowTopY>
		  <AcceptLabelsInFormulas/>
		  <ProtectStructure>False</ProtectStructure>
		  <ProtectWindows>False</ProtectWindows>
		  <DisplayInkNotes>False</DisplayInkNotes>
		 </ExcelWorkbook>
		 <Styles>
		  <Style ss:ID="Default" ss:Name="Normal">
		   <Alignment ss:Vertical="Bottom"/>
		   <Borders/>
		   <Font/>
		   <Interior/>
		   <NumberFormat/>
		   <Protection/>
		  </Style>
		  <Style ss:ID="m73014120">
		   <Alignment ss:Horizontal="Left" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11"/>
		  </Style>
		  <Style ss:ID="m73028280">
		   <Alignment ss:Horizontal="Left" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11"/>
		  </Style>
		  <Style ss:ID="s22">
		   <Alignment ss:Horizontal="Center" ss:Vertical="Center"/>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="14"/>
		  </Style>
		  <Style ss:ID="s23">
		   <Alignment ss:Horizontal="Center" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Bold="1"/>
		  </Style>
		  <Style ss:ID="s24">
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		  </Style>
		  <Style ss:ID="s25">
		   <Alignment ss:Vertical="Bottom" ss:WrapText="1"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		  </Style>
		  <Style ss:ID="s26">
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <NumberFormat ss:Format="#,##0"/>
		  </Style>
		  <Style ss:ID="s27">
		   <Font x:CharSet="204" x:Family="Swiss" ss:Bold="1"/>
		   <NumberFormat ss:Format="#,##0"/>
		  </Style>
		  <Style ss:ID="s28">
		   <Alignment ss:Horizontal="Right" ss:Vertical="Bottom"/>
		  </Style>
		  <Style ss:ID="s29">
		   <Alignment ss:Horizontal="Left" ss:Vertical="Bottom"/>
		  </Style>
		  <Style ss:ID="s45">
		   <Alignment ss:Horizontal="Left" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11"/>
		  </Style>
		  <Style ss:ID="s46">
		   <Alignment ss:Horizontal="Left" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11"/>
		  </Style>
		  <Style ss:ID="s55">
		   <Alignment ss:Horizontal="Right" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11" ss:Bold="1"/>
		  </Style>
		  <Style ss:ID="s57">
		   <Alignment ss:Horizontal="Right" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11" ss:Bold="1"/>
		  </Style>
		  <Style ss:ID="s58">
		   <Alignment ss:Horizontal="Center" ss:Vertical="Center"/>
		   <Borders>
		    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
		   </Borders>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11" ss:Bold="1"/>
		  </Style>
		  <Style ss:ID="s67">
		   <Alignment ss:Horizontal="Center" ss:Vertical="Center"/>
		   <Borders/>
		   <Font x:CharSet="204" x:Family="Swiss" ss:Size="11" ss:Bold="1"/>
		  </Style>
		 </Styles>
		 <Worksheet ss:Name="Результат расчетов">
		  <Table ss:ExpandedColumnCount="5" x:FullColumns="1"
		   x:FullRows="1">
		   <Column ss:AutoFitWidth="0" ss:Width="97.5"/>
		   <Column ss:AutoFitWidth="0" ss:Width="271.5"/>
		   <Column ss:AutoFitWidth="0" ss:Width="116.25"/>
		   <Column ss:AutoFitWidth="0" ss:Width="134.25"/>
		   <Column ss:AutoFitWidth="0" ss:Width="102.75"/>
		   <Row ss:AutoFitHeight="0" ss:Height="9.9375">
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		    <Cell ss:StyleID="s27"><Data ss:Type="String"> </Data></Cell>
		   </Row>
		   <Row ss:AutoFitHeight="0" ss:Height="27">
		    <Cell ss:MergeAcross="4" ss:StyleID="s22"><Data ss:Type="String">Расчет затрат по каналам финансирования</Data></Cell>
		   </Row>
		   <Row ss:AutoFitHeight="0" ss:Height="27">
		    <Cell ss:MergeAcross="4" ss:StyleID="s58"><Data ss:Type="String">
		    Статистика случаев по значению КСГ.  Заявка на ${repMonthStr}</Data>
		    </Cell>
		   </Row>`;

			let tabFooter = `  </Table>
				  <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
				   <PageSetup>
				    <PageMargins x:Bottom="0.984251969" x:Left="0.78740157499999996"
				     x:Right="0.78740157499999996" x:Top="0.984251969"/>
				   </PageSetup>
				   <Print>
				    <ValidPrinterInfo/>
				    <PaperSizeIndex>9</PaperSizeIndex>
				    <HorizontalResolution>1200</HorizontalResolution>
				    <VerticalResolution>1200</VerticalResolution>
				   </Print>
				   <Selected/>
				   <Panes>
				    <Pane>
				     <Number>3</Number>
				     <ActiveRow>14</ActiveRow>
				    </Pane>
				   </Panes>
				   <ProtectObjects>False</ProtectObjects>
				   <ProtectScenarios>False</ProtectScenarios>
				  </WorksheetOptions>
				 </Worksheet>
				</Workbook>
				`;   

		let linesStr = '';
		linesStr += this.renderKsgXls();
		linesStr += this.renderChanelXls('oms');
		linesStr += this.renderChanelXls('omsd');
		linesStr += this.renderChanelXls('rlo');
		linesStr += this.renderChanelXls('onls');

		tableStr = tabHeader + linesStr;
		tableStr += tabFooter;

        let blob = new Blob([tableStr], { type: 'application/vnd.ms-excel' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'chanels.xls';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
	}


} 

export default ChanTable;