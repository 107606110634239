;"use strict";

const asData = {

		'st0_0_1': {
			group: 'Ступень 1<br>вне обострений',
			//ambul: 0,
			meth: [
				{
					// name: 'Беклометазон/сальбутамол фиксированная комбинация',
					// info: '(беклометазон/сальбутамол)',
					meth: 47,
					rate: 15,
					days: [180],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазона дипропионат ДАИ неэкстрамелкодисперсный',
					// info: '(беклометазона дипропионат)',
					meth: 51,
					rate: 5,
					days: [180],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид ДАИ, ДПИ',
					// info: '(будесонид)',
					meth: 53,
					rate: 5,
					days: [180],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Флутиказона пропионат ДАИ, ДПИ',
					// info: '(флутиказона пропионат)',
					meth: 75,
					rate: 5,
					days: [180],
					cycles: [1],
					pats: 0
				}
			]
		},
		'st0_0_2': {
			group: 'Ступень 2<br>вне обострений',
			//ambul: 0,
			meth: [
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация',
					// info: '(беклометазон/формотерол)',
					meth: 49,
					rate: 2,
					days: [358],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазон/сальбутамол фиксированная комбинация',
					// info: '(беклометазон/сальбутамол)',
					meth: 48,
					rate: 2,
					days: [358],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация',
					// info: '(будесонид/формотерол)',
					meth: 54,
					rate: 12,
					days: [358],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазона дипропионат ДАИ неэкстрамелкодисперсный',
					// info: '(беклометазона дипропионат)',
					meth: 51,
					rate: 47,
					days: [358],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид ДАИ, ДПИ',
					// info: '(будесонид)',
					meth: 52,
					rate: 2,
					days: [358],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Флутиказона пропионат ДАИ, ДПИ',
					// info: '(флутиказона пропионат)',
					meth: 75,
					rate: 5,
					days: [358],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Монтелукаст',
				// 	// info: '(монтелукаст)',
				// 	meth: 61,
				// 	rate: 10,
				// 	days: [358],
				// 	cycles: [1],
				// 	pats: 0
				// },
				// {
				// 	// name: 'Теофиллин',
				// 	// info: '(теофиллин)',
				// 	meth: 70,
				// 	rate: 5,
				// 	days: [358],
				// 	cycles: [1],
				// 	pats: 0
				// }
				{
					name: 'Беклометазон/формотерол фиксированная комбинация + теофиллин',
					info: '(беклометазон/формотерол, теофиллин)',
					meth: 76,
					rate: 1,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Беклометазон/сальбутамол фиксированная комбинация + теофиллин',
					info: '(беклометазон/сальбутамол, теофиллин)',
					meth: 77,
					rate: 1,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Будесонид/формотерол фиксированая комбинация + теофиллин',
					info: '(Будесонид/формотерол, теофиллин)',
					meth: 78,
					rate: 1,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Беклометазона дипропионат + теофиллин',
					info: '(беклометазона дипропионат, теофиллин)',
					meth: 80,
					rate: 1,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Будесонид + теофиллин',
					info: '(будесонид, теофиллин)',
					meth: 81,
					rate: 1,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Беклометазон/формотерол фиксированная комбинация + монтелукаст',
					info: '(бекламетазон/формотерол, монтелукаст)',
					meth: 83,
					rate: 2,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Беклометазон/сальбутамол фиксированная комбинация + монтелукаст',
					info: '(беклометазон/сальбутамол, монтелукаст)',
					meth: 84,
					rate: 2,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Будесонид/формотерол фиксированая комбинация + монтелукаст',
					info: '(будесонид/формотерол, монтелукаст)',
					meth: 86,
					rate: 2,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Беклометазона дипропионат + монтелукаст',
					info: '(беклометазона дипропионат, монтелукаст)',
					meth: 87,
					rate: 2,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Будесонид  + монтелукаст',
					info: '(будесонид, монтелукаст)',
					meth: 88,
					rate: 2,
					days: [358,358],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_3': {
			group: 'Ступень 3<br>вне обострений',
			//ambul: 0,
			meth: [
				{
					// name: 'Салметерол/флутиказон фиксированная комбинация',
					// info: '(салметерол/флутиказон)',
					meth: 68,
					rate: 25,
					days: [355],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация',
					// info: '(вилантерол/флутиказон)',
					meth: 58,
					rate: 10,
					days: [355],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация',
					// info: '(беклометазон/формотерол)',
					meth: 49,
					rate: 15,
					days: [355],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация',
					// info: '(будесонид/формотерол)',
					meth: 55,
					rate: 25,
					days: [355],
					cycles: [1],
					pats: 0
				},
				// {
				// 	name: 'Монтелукаст',
				// 	info: '(монтелукаст)',
				// 	meth: 61,
				// 	rate: ,
				// 	days: [355],
				// 	cycles: [1],
				// 	pats: 0
				// },
				{
					// name: 'Беклометазона дипропионат ДАИ неэкстрамелкодисперсный',
					// info: '(беклометазона дипропионат)',
					meth: 51,
					rate: 2.5,
					days: [355],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид ДАИ, ДПИ',
					// info: '(будесонид)',
					meth: 53,
					rate: 5,
					days: [355],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Флутиказона пропионат ДАИ, ДПИ',
					// info: '(флутиказона пропионат)',
					meth: 75,
					rate: 5,
					days: [355],
					cycles: [1],
					pats: 0
				},
				// {
				// 	name: 'Теофиллин',
				// 	info: '(теофиллин)',
				// 	meth: 70,
				// 	rate: ,
				// 	days: [355],
				// 	cycles: [1],
				// 	pats: 0
				// }
				{
					name: 'Беклометазона дипропионат + монтелукаст',
					info: '(беклометазона дипропионат, монтелукаст)',
					meth: 87,
					rate: 2.5,
					days: [355,355],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Будесонид  + монтелукаст',
					info: '(будесонид, монтелукаст)',
					meth: 89,
					rate: 5,
					days: [355,355],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Беклометазона дипропионат + теофиллин',
					info: '(беклометазона дипропионат, теофиллин)',
					meth: 80,
					rate: 2.5,
					days: [355,355],
					cycles: [1,1],
					pats: 0
				},
				{
					name: 'Будесонид + теофиллин',
					info: '(будесонид, теофиллин)',
					meth: 82,
					rate: 2.5,
					days: [355,355],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_4': {
			group: 'Ступень 4<br>вне обострений',
			//ambul: 0,
			meth: [
				{
					// name: 'Салметерол/флутиказон фиксированная комбинация',
					// info: '(салметерол/флутиказон)',
					meth: 97,
					rate: 30,
					days: [350],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация',
					// info: '(вилантерол/флутиказон)',
					meth: 58,
					rate: 10,
					days: [350],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация',
					// info: '(беклометазон/формотерол)',
					meth: 49,
					rate: 15,
					days: [350],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация',
					// info: '(будесонид/формотерол)',
					meth: 55,
					rate: 15,
					days: [350],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Салметерол/флутиказон фиксированная комбинация + тиотропия бромид',
				// 	// info: '(салметерол/флутиказон, тиотропия бромид)',
				// 	meth: 69,
				// 	rate: -10,
				// 	days: [350,350],
				// 	cycles: [1,1],
				// 	pats: 0
				// },
				// {
				// 	// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
				// 	// info: '(будесонид/формотерол, тиотропия бромид)',
				// 	meth: 56,
				// 	rate: -10,
				// 	days: [350,350],
				// 	cycles: [1,1],
				// 	pats: 0
				// }
				{
					// name: 'Салметерол/флутиказон фиксированная комбинация + тиотропия бромид',
					// info: '(салметерол/флутиказон, тиотропия бромид)',
					meth: 69,
					rate: 10,
					days: [350,350],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					// info: '(будесонид/формотерол, тиотропия бромид)',
					meth: 56,
					rate: 10,
					days: [350,350],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + теофиллин',
					// info: '(беклометазон/формотерол, теофиллин)',
					meth: 76,
					rate: 2.5,
					days: [350,350],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + теофиллин',
					// info: '(Будесонид/формотерол, теофиллин)',
					meth: 79,
					rate: 2.5,
					days: [350,350],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + монтелукаст',
					// info: '(бекламетазон/формотерол, монтелукаст)',
					meth: 83,
					rate: 2.5,
					days: [350,350],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + монтелукаст',
					// info: '(будесонид/формотерол, монтелукаст)',
					meth: 85,
					rate: 2.5,
					days: [350,350],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_5': {
			group: 'Ступень 5<br>вне обострений',
			//ambul: 0,
			meth: [
				{
					// name: 'Салметерол/флутиказон фиксированная комбинация',
					// info: '(салметерол/флутиказон)',
					meth: 97,
					rate: 20,
					days: [345],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация',
					// info: '(вилантерол/флутиказон)',
					meth: 58,
					rate: 15,
					days: [345],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация',
					// info: '(беклометазон/формотерол)',
					meth: 49,
					rate: 15,
					days: [345],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация',
					// info: '(будесонид/формотерол)',
					meth: 98,
					rate: 20,
					days: [345],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Беклометазон/сальбутамол фиксированная комбинация',
					// info: '(беклометазон/сальбутамол)',
					meth: 99,
					rate: 10,
					days: [345],
					cycles: [1],
					pats: 0
				},
				// {
				// 	// name: 'Тиотропия бромид',
				// 	// info: '(тиотропия бромид)',
				// 	meth: 71,
				// 	rate: 11,
				// 	days: [345],
				// 	cycles: [1],
				// 	pats: 0
				// },
				{
					// name: Бенрализумаб',
					// info: '(бенрализумаб)',
					meth: 90,
					rate: 0.5,
					days: [7], 
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Омализумаб',
					// info: '(омализумаб)',
					meth: 65,
					rate: 0.5,
					days: [11],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Меполизумаб',
					// info: '(меполизумаб)',
					meth: 60,
					rate: 0.5,
					days: [11],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Реслизумаб',
					// info: '(реслизумаб)',
					meth: 67,
					rate: 0.5,
					days: [30],
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Дупилумаб',
					// info: '(дупилумаб)',
					meth: 91,
					rate: 0.5,
					days: [25], 
					cycles: [1],
					pats: 0
				},
				{
					// name: 'Салметерол/флутиказон фиксированная комбинация + тиотропия бромид',
					// info: '(салметерол/флутиказон фиксированная комбинация, тиотропия бромид)',
					meth: 92,
					rate: 2,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация + тиотропия бромид',
					// info: '(вилантерол/флутиказон фиксированная комбинация, тиотропия бромид)',
					meth: 93,
					rate: 2,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + тиотропия бромид',
					// info: '(беклометазон/формотерол фиксированная комбинация, тиотропия бромид)',
					meth: 94,
					rate: 2,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + тиотропия бромид',
					// info: '(будесонид/формотерол фиксированая комбинация, тиотропия бромид)',
					meth: 95,
					rate: 2,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Беклометазон/сальбутамол фиксированная комбинация + тиотропия бромид',
					// info: '(беклометазон/сальбутамол, тиотропия бромид)',
					meth: 96,
					rate: 2,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Салметерол/флутиказон фиксированная комбинация + преднизолон',
					// info: '(салметерол/флутиказон фиксированная комбинация, преднизолон)',
					meth: 100,
					rate: 0,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Вилантерол/флутиказон фиксированная комбинация + преднизолон',
					// info: '(вилантерол/флутиказон фиксированная комбинация, преднизолон)',
					meth: 101,
					rate: 0,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Беклометазон/формотерол фиксированная комбинация + преднизолон',
					// info: '(беклометазон/формотерол фиксированная комбинация, преднизолон)',
					meth: 102,
					rate: 0,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Будесонид/формотерол фиксированая комбинация + преднизолон',
					// info: '(будесонид/формотерол фиксированая комбинация, преднизолон)',
					meth: 103,
					rate: 0,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Беклометазон/сальбутамол фиксированная комбинация + преднизолон',
					// info: '(беклометазон/сальбутамол, преднизолон)',
					meth: 104,
					rate: 0,
					days: [345,345], 
					cycles: [1,1],
					pats: 0
				}
				// {
				// 	// name: 'Преднизолон',
				// 	// info: '(преднизолон)',
				// 	meth: 66,
				// 	rate: 5,
				// 	days: [11], //TODO Исправить!
				// 	cycles: [1],
				// 	pats: 0
				// }
			]
		},
		'st0_0_6': {
			group: 'Ступень 1<br>обострения',
			//ambul: 0,
			meth: [
				{
					// name: 'Небулизированный сальбутамол + преднизолон таб.',
					// info: '(сальбутамол, преднизолон)',
					meth: 64,
					rate: 20,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + метилпреднизолон в/в',
					// info: '(сальбутамол, метилпреднизолон)',
					meth: 62,
					rate: 5,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Феноерол + преднизолон таб.',
					// info: '(феноерол, преднизолон)',
					meth: 72,
					rate: 20,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + метилпреднизолон в/в',
					// info: '(фенотерол, метилпреднизолон)',
					meth: 73,
					rate: 5,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + небулизированный будесонид',
					// info: '(сальбутамол, будесонид)',
					meth: 63,
					rate: 25,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + небулизированный будесонид',
					// info: '(фенотерол, будесонид)',
					meth: 74,
					rate: 25,
					days: [5,5],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_7': {
			group: 'Ступень 2<br>обострения',
			//ambul: 0,
			meth: [
				{
					// name: 'Небулизированный сальбутамол + преднизолон таб.',
					// info: '(сальбутамол, преднизолон)',
					meth: 64,
					rate: 20,
					days: [7,7],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + метилпреднизолон в/в',
					// info: '(сальбутамол, метилпреднизолон)',
					meth: 62,
					rate: 10,
					days: [7,7],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Феноерол + преднизолон таб.',
					// info: '(феноерол, преднизолон)',
					meth: 72,
					rate: 20,
					days: [7,7],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + метилпреднизолон в/в',
					// info: '(фенотерол, метилпреднизолон)',
					meth: 73,
					rate: 10,
					days: [7,7],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + небулизированный будесонид',
					// info: '(сальбутамол, будесонид)',
					meth: 63,
					rate: 20,
					days: [7,7],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + небулизированный будесонид',
					// info: '(фенотерол, будесонид)',
					meth: 74,
					rate: 20,
					days: [7,7],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_8': {
			group: 'Ступень 3<br>обострения',
			//ambul: 0,
			meth: [
				{
					// name: 'Небулизированный сальбутамол + преднизолон таб.',
					// info: '(сальбутамол, преднизолон)',
					meth: 64,
					rate: 15,
					days: [10,10],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + метилпреднизолон в/в',
					// info: '(сальбутамол, метилпреднизолон)',
					meth: 62,
					rate: 20,
					days: [10,10],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Феноерол + преднизолон таб.',
					// info: '(феноерол, преднизолон)',
					meth: 72,
					rate: 15,
					days: [10,10],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + метилпреднизолон в/в',
					// info: '(фенотерол, метилпреднизолон)',
					meth: 73,
					rate: 20,
					days: [10,10],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + небулизированный будесонид',
					// info: '(сальбутамол, будесонид)',
					meth: 63,
					rate: 15,
					days: [10,10],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + небулизированный будесонид',
					// info: '(фенотерол, будесонид)',
					meth: 74,
					rate: 15,
					days: [10,10],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_9': {
			group: 'Ступень 4<br>обострения',
			//ambul: 0,
			meth: [
				{
					// name: 'Небулизированный сальбутамол + преднизолон таб.',
					// info: '(сальбутамол, преднизолон)',
					meth: 64,
					rate: 10,
					days: [15,15],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + метилпреднизолон в/в',
					// info: '(сальбутамол, метилпреднизолон)',
					meth: 62,
					rate: 30,
					days: [15,15],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + преднизолон таб.',
					// info: '(феноерол, преднизолон)',
					meth: 72,
					rate: 10,
					days: [15,15],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + метилпреднизолон в/в',
					// info: '(фенотерол, метилпреднизолон)',
					meth: 73,
					rate: 30,
					days: [15,15],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + небулизированный будесонид',
					// info: '(сальбутамол, будесонид)',
					meth: 63,
					rate: 10,
					days: [15,15],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + небулизированный будесонид',
					// info: '(фенотерол, будесонид)',
					meth: 74,
					rate: 10,
					days: [15,15],
					cycles: [1,1],
					pats: 0
				}
			]
		},
		'st0_0_10': {
			group: 'Ступень 5<br>обострения',
			//ambul: 0,
			meth: [
				{
					// name: 'Небулизированный сальбутамол + преднизолон таб.',
					// info: '(сальбутамол, преднизолон)',
					meth: 64,
					rate: 10,
					days: [20,20],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + метилпреднизолон в/в',
					// info: '(сальбутамол, метилпреднизолон)',
					meth: 62,
					rate: 35,
					days: [20,20],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Феноерол + преднизолон таб.',
					// info: '(феноерол, преднизолон)',
					meth: 72,
					rate: 10,
					days: [20,20],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + метилпреднизолон в/в',
					// info: '(фенотерол, метилпреднизолон)',
					meth: 73,
					rate: 35,
					days: [20,20],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Небулизированный сальбутамол + небулизированный будесонид',
					// info: '(сальбутамол, будесонид)',
					meth: 63,
					rate: 5,
					days: [20,20],
					cycles: [1,1],
					pats: 0
				},
				{
					// name: 'Фенотерол + небулизированный будесонид',
					// info: '(фенотерол, будесонид)',
					meth: 74,
					rate: 5,
					days: [20,20],
					cycles: [1,1],
					pats: 0
				}
			]
		},

	}

export default asData;	